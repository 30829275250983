<div class="content tasks-page sub-page-content">
  <div class="fixed-area mb-4">
    <section class="col-12 px-0 history-back">
      <a routerLink="/profil">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <section class="page-tab">
      <nav class="tab-button">
        <div class="nav" id="nav-tab" role="tablist">
          <a
            (click)="reloadList(0)"
            class="active"
            data-toggle="tab"
            href="#my-tasks"
            role="tab"
            aria-selected="true"
            >Aktif Görevlerim</a
          >
          <a
            (click)="reloadList(1)"
            data-toggle="tab"
            href="#past-tasks"
            role="tab"
            aria-selected="false"
            >Tamamlanan Görevlerim</a
          >
        </div>
      </nav>
      <div class="new-item" *hasPermission="['Pages.OrphanTasks.Create']">
        <a (click)="edit(null)">
          <span>Yeni Ekle</span>
          <span class="icon-plus-circle-thin"></span>
        </a>
      </div>
    </section>
  </div>
  <div class="scroll-content">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="my-tasks" role="tabpanel">
        <div class="my-tasks-content">
          <div class="row row-gap-30">
            <ng-container *ngFor="let item of waitingModel">
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                <div class="item">
                  <div class="item-body">
                    <div class="title">
                      <span>{{ item.orphanTaskTypeName }}</span>
                    </div>
                    <div class="description">
                      <!-- <span class="description-title"
                        ><b> Görev Konusu :</b></span
                      > -->
                      <span class="description-content"
                        ><b>{{ item.orphanTask.subject }}</b></span
                      >
                    </div>
                    <div class="constituent">
                      <div class="constituent-title">
                        <span>Atayan :</span>
                      </div>
                      <div class="constituent-person">
                        <div class="img">
                          <img src="../assets/images/userDefault.png" alt="" />
                        </div>
                        <div class="constituent-name">
                          <span>{{ item.creatorName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-footer">
                    <div class="task-comments">
                      <!-- <span class="icon icon-comments"></span> -->

                      <span class="text"
                        >Hedeflenen Tarih :
                        {{
                          item.orphanTask.targetCompletedDate
                            | date: "dd/MM/yyyy"
                        }}</span
                      >
                    </div>
                    <div class="task-detail">
                      <a
                        [routerLink]="[
                          '/gorevlerim-detay/' + item.orphanTask.id
                        ]"
                      >
                        <span class="text">Detay</span>
                        <span class="icon icon-brush-right-arrow"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <mat-paginator
          (page)="getServerData()"
          [length]="dataCount"
          [pageSize]="5"
          [pageSizeOptions]="[5, 10, 25, 100]"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>

      <div class="tab-pane fade" id="past-tasks" role="tabpanel">
        <div class="past-tasks-content">
          <div class="row row-gap-30">
            <ng-container *ngFor="let item of completedModel">
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                <div class="item">
                  <div class="item-body">
                    <div class="title">
                      <span>{{ item.orphanTaskTypeName }}</span>
                    </div>
                    <div class="description">
                      <!-- <span class="description-title"
                        > Görev Konusu :</span
                      > -->
                      <span class="description-content"
                        ><b>{{ item.orphanTask.description }}</b></span
                      >
                    </div>
                    <div class="date">
                      <span class="date-title">Tamamlanma Tarihi :</span>
                      <span class="date-content"
                        >{{
                          item.orphanTask.completedDate | date: "dd/MM/yyyy"
                        }}
                      </span>
                    </div>
                    <div class="constituent">
                      <div class="constituent-title">
                        <span>Atayan :</span>
                      </div>
                      <div class="constituent-person">
                        <div class="img">
                          <img src="../assets/images/userDefault.png" alt="" />
                        </div>
                        <div class="constituent-name">
                          <span>{{ item.creatorName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item-footer">
                    <!-- <div class="task-comments">
                      <span class="icon icon-comments"></span>
                      <span class="text">{{
                        item.orphanTask.targetCompletedDate | date: "dd/MM/yyyy"
                      }}</span>
                    </div> -->
                    <div class="task-detail">
                      <a
                        [routerLink]="[
                          '/gorevlerim-detay/' + item.orphanTask.id
                        ]"
                      >
                        <span class="text">Detay</span>
                        <span class="icon icon-brush-right-arrow"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <mat-paginator
          (page)="getServerData()"
          [length]="dataCount"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
