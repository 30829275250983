import { Component, Inject, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { GetOrphanFileForViewDto } from 'src/app/models/orphan/file';
import { OrphanEducationModel } from 'src/app/models/orphan/taken-education';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { FileService } from 'src/app/services/orphan/file.service';
import { TakenEducationService } from 'src/app/services/orphan/taken-education.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-take-education-edit',
  templateUrl: './take-education-edit.component.html',
  styleUrls: ['./take-education-edit.component.css']
})
export class TakeEducationEditComponent extends ModalViewComponent<TakeEducationEditComponent> implements OnInit {
  @ViewChild('fileUpload')
  fileUploadVariable: ElementRef;

  orphanFiles: GetOrphanFileForViewDto[] = [];
  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  formData: FormData = new FormData();
  model: OrphanEducationModel;
  isEdit = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;

  constructor(
    private service: TakenEducationService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TakeEducationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      educationName: ["", Validators.required],
      educationTime: ["", Validators.required],
      pointValue: [""],
      description: [""],
      educationDate: ["", Validators.required],
      binaryFileToken: [""],
      orphanId: [this.utilsService.selectedOrphanId, Validators.required], createOrEditOrphanFileDto: null
    })
    if (this.dialogData.id) {
      this.service.getOrphanEducationForEdit(this.dialogData.id).subscribe(result => {
        this.model = result;
        this.form.patchValue(this.model.orphanEducation);
      })
    }
    this.isPageDone = true;
  }

  onFileSelected(event): void {
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    // const [file] = event.target.files;

    // reader.readAsDataURL(file);
    // reader.onload = () => {
    //   let fileName = event.target.files[0].name;
    //   let fileExtension = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.'), event.target.files[0].name.length);
    //   this.form.controls.createOrEditOrphanFileDto.setValue({ fileName: fileName, fileExtension: fileExtension, fileDataBase64: reader.result, referenceNumber: "asd" });
    //   this.selectedFileName = fileName;
    // }
    var extensionArray: string[] = [];
    extensionArray.push("jpeg");
    extensionArray.push("jpg");
    extensionArray.push("png");
    /*extensionArray.push("xlsx");*/
    /*extensionArray.push("txt");*/
    /*extensionArray.push("docx");*/
    extensionArray.push("pdf");
    let selectedFile = <File>event.target.files[0];
    this.formData.append(selectedFile.name, selectedFile);
    this.selectedFileName = selectedFile.name;
    var extension = this.selectedFileName.substring(this.selectedFileName.lastIndexOf('.') + 1);
    if (this.utilsService.checkFileIsCorrect(selectedFile.size, extension, 5242880, extensionArray)) {
      this.fileService.uploadCatalogImageFile("/OrphanEducations/UploadcatalogImageFile", this.formData).subscribe(res => {
        this.form.controls.binaryFileToken.setValue(res.result.fileToken)
      })
    }
    else {
      this.utilsService.showError("Dosya boyutu max 5 mb ve dosya uzantısı 'jpg','jpeg','png' veya 'pdf' formatında olmalıdır")
      this.fileUploadVariable.nativeElement.value = "";
      this.formData = new FormData();
      this.selectedFileName = ""
    }
    // }
  }

  downloadFile(binaryFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(binaryFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  deleteFile(id: number, key: string, index: number) {
    const dialogData: DialogDataModel = {
      title: "Dosya Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.removeCatalogImageFile(id).subscribe(response => {
          this.model.binaryFileName = null;
          this.model.orphanEducation.binaryFileId = null;
        })
      }
    })
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe((response) => {
          this.dialogRef.close();
        })
      }
    })
  }

  removeSelectedFile(): void {
    this.fileUploadVariable.nativeElement.value = "";
    this.formData = new FormData();
    this.selectedFileName = "";
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }
}
