import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { NewsDataRequestModel, NewsModel } from 'src/app/models/orphan/news';
import { NewsService } from 'src/app/services/orphan/news.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {


  isPageDone = false;
  model: NewsModel[];
  dataCount: number;
  requestModel = new NewsDataRequestModel();
  @ViewChild(MatPaginator, { static: false}) paginator: MatPaginator;

  constructor(
    public service: NewsService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) {
    this.utilsService.setTitle("Haberler");

  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 6;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.sorting = "publishDate desc"
    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);
    this.service.getActiveAll(paramsString).subscribe(result => {
      this.model = result.items;
      this.dataCount = result.totalCount;
      this.isPageDone = true;
    });
  }

  getServerData() {
    this.requestModel.maxResultCount = this.paginator.pageSize;
    this.requestModel.skipCount = this.paginator.pageSize * this.paginator.pageIndex;
    this.reloadList();
  }

  getDownloadUrl(id: string): string {
    if (id) {
      return environment.apiRoot + '/File/DownloadBinaryFile?id=' + id;
    }
    return "";
  }

}
