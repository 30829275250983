import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { EventViewModel, OrphanTaskModel } from 'src/app/models/orphan';
import { EventCalendarService, TaskInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-event-calendar-detail',
  templateUrl: './event-calendar-detail.component.html'
})
export class EventCalendarDetailComponent extends ModalViewComponent<EventCalendarDetailComponent> implements OnInit {

  isPageDone = false;

  model: EventViewModel | OrphanTaskModel;
  taskModel: OrphanTaskModel;

  constructor(
    public utilsService: UtilsService,
    public eventService: EventCalendarService,
    public taskService: TaskInfoService,
    public formBuilder: FormBuilder,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EventCalendarDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    if (this.dialogData.id) {
      if (this.dialogData.dataType == "Event") {
        this.getEventModel(this.dialogData.id);
      }
      else {
        this.getTaskModel(this.dialogData.id);
      }

    }

  }

  getTaskModel(id: number) {
    this.taskService.getOrphanTaskForEdit(id).subscribe(result => {
      this.model = result;
      this.isPageDone = true;
    })
  }

  getEventModel(id: number) {
    this.eventService.getEventCalendarDetailForView(id).subscribe(result => {
      this.model = result;
      this.isPageDone = true;
    })
  }

  goTaskDetail(id: number) {
    this.onClose();
    this.router.navigate(["/gorevlerim-detay/" + id]);
  }

  onClose(): void {
    this.dialogRef.close(AppConstants.EDIT_DIALOG_RESULT_CANCEL)
  }

}
