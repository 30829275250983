<div *ngIf="isPageDone" class="content project-detail-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="/projelerim">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div class="item-header">
      <div
        class="edit-item"
        *hasPermission="['Pages.OrphanProjectDetails.Edit']"
      >
        <a (click)="edit(model.id)">
          <span class="icon-edit icon"></span>
          <span>Düzenle</span>
        </a>
      </div>
    </div>
  </div>

  <div class="scroll-content">
    <div class="project-detail-content">
      <div class="row">
        <div class="col-12">
          <div class="project-detail">
            <div class="project-detail-title">
              <span>Proje Adı :</span>
            </div>
            <div class="project-name">
              <span>{{ model.name }}</span>
            </div>
            <div class="project-detail-title description-title">
              <span>Proje Durumu :</span>
            </div>
            <div class="project-description">
              <span>{{
                getProjectStatusName(model.projectStatus.toString())
              }}</span>
            </div>
            <div class="project-detail-title date-title">
              <span>Tarih :</span>
            </div>
            <div class="project-date">
              <span>{{ model.year }}</span>
            </div>
          </div>
        </div>
        <!--
        <div class="col-12 mt-5">
          <div class="project-comment">
            <div class="comment-title">
              <span>Yorumlar (12)</span>
            </div>
            <div class="comment-list">
              <div class="comment">
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">Merve Acar</span>
                    <span class="date">01.01.2022</span>
                  </div>
                  <div class="content-description">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div class="comment">
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">Merve Acar</span>
                    <span class="date">01.01.2022</span>
                  </div>
                  <div class="content-description">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div class="comment">
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">Merve Acar</span>
                    <span class="date">01.01.2022</span>
                  </div>
                  <div class="content-description">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div class="comment">
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">Merve Acar</span>
                    <span class="date">01.01.2022</span>
                  </div>
                  <div class="content-description">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
              <div class="comment">
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">Merve Acar</span>
                    <span class="date">01.01.2022</span>
                  </div>
                  <div class="content-description">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
