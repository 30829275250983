import { DateTime } from "luxon";

export class OrphanLanguageInfoModel {
  orphanLanguageDetail: OrphanLanguageInfoViewModel;
  orphanFirstName: string;
}

export class OrphanLanguageInfoViewModel {
  name: string;
  languageLevelRead: number;
  languageLevelWrite: number;
  languageLevelTolk: number;
  orphanId: number;
  id: number;
}



