import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { GetOrphanFileForViewDto } from 'src/app/models/orphan/file';
import { JobApplicationModel } from 'src/app/models/orphan/job-work';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { FileService, JobApplicationService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-job-application-edit',
  templateUrl: './job-application-edit.component.html',
  styleUrls: ['./job-application-edit.component.css']
})
export class JobApplicationEditComponent extends ModalViewComponent<JobApplicationEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  model: JobApplicationModel;
  form: FormGroup;
  isEdit = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;
  formData: FormData = new FormData();
  taskTypeSelect: DropdownModel[];
  orphanFiles: GetOrphanFileForViewDto[] = [];
  public fileUploadWarning: boolean = true;

  constructor(
    private service: JobApplicationService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<JobApplicationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      orphanDescription: [""],
      managerDescription: [""],
      jobStatusId: [""],
      workJobId: ["", Validators.required],
      jobApplicationFile: "",
      jobApplicationFileToken: "",
      orphanId: [this.utilsService.selectedOrphanId, Validators.required],
    })
    if (this.dialogData.jobApplicationId) {
      this.service.getJobApplicationForEdit(this.dialogData.jobApplicationId).subscribe(result => {
        this.model = result;
        this.form.patchValue(this.model.jobApplication);
      })
    }
    this.isPageDone = true;
  }

  get f() {
    return this.form.controls;
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      // const [file] = event.target.files;

      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   let fileName = event.target.files[0].name;
      //   let fileExtension = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.'), event.target.files[0].name.length);
      //   this.form.controls.createOrEditOrphanFileDto.setValue({ fileName: fileName, fileExtension: fileExtension, fileDataBase64: reader.result, referenceNumber: "asd" });
      //
      // }
      let selectedFile = <File>event.target.files[0];
      this.formData.append(selectedFile.name, selectedFile);
      this.selectedFileName = selectedFile.name;
      this.fileUploadWarning = true;

      this.fileService.uploadCatalogImageFile("/JobApplications/UploadJobApplicationFileFile", this.formData).subscribe(res => {
        this.form.controls.jobApplicationFileToken.setValue(res.result.fileToken)
      })
    }
  }

  downloadFile(binaryFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(binaryFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  deleteFile(id: number, key: string, index: number) {
    const dialogData: DialogDataModel = {
      title: "Dosya Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.removeCatalogImageFile(id).subscribe(response => {
          this.model.jobApplicationFileFileName = null;
        })
      }
    })
  }

  onSubmit(): void {
    if(this.selectedFileName != undefined && this.selectedFileName != "" && this.selectedFileName != null)
    {
      this.form.controls.workJobId.setValue(this.dialogData.workJobId);
      if (this.form.valid) {

        this.fileUploadWarning = true;
        this.service.createOrEdit(this.form.value).subscribe(result => {
          // if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
          // }

          this.dialogRef.close(this.form.value);
        })
      }
    }
    else{
      this.fileUploadWarning = false;
    }
  }
}
