
export class GonulluOlResultModel {
  volunteerLevelPoints: number;
  totalNumOfAttendedEvents: number;
  taskList: GonullOlTaskListModel[];
}


export class GonullOlTaskListModel {
  taskName: string;
  startDate: Date;
  endDate: Date;
  participationStatus: string;
}
