import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanClubInfoViewModel } from 'src/app/models/orphan/club-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { ClubInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-club-info-edit',
  templateUrl: './club-info-edit.component.html'
})
export class ClubInfoEditComponent extends ModalViewComponent<ClubInfoEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  model: OrphanClubInfoViewModel;
  isPageDone = false;
  isSubmitted = false;
  bookTypeSelect: DropdownModel[];
  todayDateFormatted:String;
  constructor(
    private service: ClubInfoService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClubInfoEditComponent>,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      name: ["", Validators.required],
      joinedCount: ["",],
      joinedYear: [""],
      description: [""],
      orphanId: [this.utilsService.selectedOrphanId, Validators.required]
    })

    if (this.dialogData.id) {
      this.service.getOrphanClubDetailForEdit(this.dialogData.id).subscribe(result => {
        this.model = result.orphanClubDetail;
        this.form.patchValue(this.model);
      })
    }
    this.todayDateFormatted= this.datepipe.transform(new Date(),'yyyy-MM-dd')
    this.isPageDone = true;
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe((response) => {

          this.dialogRef.close();
        })
      }
    })
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }

}
