<div class="content grants-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <div *ngIf="model && model.length > 0">
    <label for="">Belge Numarası</label>
    <div class="select">
      <select #select (change)="ayniNakdiYardimDetail(select.value)">
        <option value="">Seçiniz...</option>
        <option
          value="{{ item.ayniNakdiYardim.belgeNo }}"
          *ngFor="let item of model; let i = index"
        >
          {{ item.ayniNakdiYardim.belgeNo }}
        </option>
      </select>
      <div class="icon">
        <span class="icon-down-thin"></span>
      </div>
    </div>

    <div class="grants-list" id="accordion">
      <div class="item" *ngFor="let item of responseData; let i = index">
        <div class="item-header">
          <a
            data-toggle="collapse"
            href="#item{{ i }}"
            role="button"
            aria-expanded="false"
            aria-controls="item{{ i }}"
            class="collapsed"
          >
            <span class="title">{{ item.planlamA_TARIHI }}</span>
            <span class="icon icon-right-thin"></span>
          </a>
        </div>
        <div
          id="item{{ i }}"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="item-body">
            <div class="row">
              <div class="col-12 table-title">
                <div class="row">
                  <div class="col-6">
                    <span>Türü</span>
                  </div>
                  <div class="col-6">
                    <span>Karşılığı</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>PARA_BIRIMI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.parA_BIRIMI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>Tutar</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.tutar }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>planlamA_TARIHI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.planlamA_TARIHI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>durum</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.durum }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>periyot</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.periyot }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>kacincI_ODEME</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.kacincI_ODEME }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>teslimaT_TARIHI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.teslimaT_TARIHI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>bankayA_GONDERIM_TARIHI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.bankayA_GONDERIM_TARIHI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>projE_KODU</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.projE_KODU }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>butcE_KODU</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.butcE_KODU }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>projE_KODU_TEXT_ADI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.projE_KODU_TEXT_ADI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>butcE_KODU_TEXT_ADI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.butcE_KODU_TEXT_ADI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>yonlendireN_SUBE_KODU</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.yonlendireN_SUBE_KODU }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>subE_ORGANIZASYON_KODU</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.subE_ORGANIZASYON_KODU }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>dagitiM_ARACI</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.dagitiM_ARACI }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>isbirlikcI_KURUM</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.isbirlikcI_KURUM }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 table-line">
                <div class="row">
                  <div class="col-6">
                    <span>ihtiyaC_TIPI_KODU</span>
                  </div>
                  <div class="col-6">
                    <span>{{ item.ihtiyaC_TIPI_KODU }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!model || model.length <= 0">
    <h6 style="text-align: center">Gösterilecek kayıt bulunamadı</h6>
  </div>
  <app-footer></app-footer>
</div>
