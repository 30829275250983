<div *ngIf="isPageDone">
  <div mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button type="button" class="close modal-close">
        <span (click)="onClose()" class="icon-modal-close"> </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="event-date">
        <span *ngIf="model.orphanTask">{{
          model.orphanTask?.targetCompletedDate | date: "dd/MM/yyyy"
        }}</span>
        <span *ngIf="model.eventCalendarDetail"
          >{{ model.eventCalendarDetail?.startDate | date: "dd.MM.yyyy HH:mm" }} /
          {{ model.eventCalendarDetail?.endDate | date: "dd.MM.yyyy HH:mm" }}</span
        >
      </div>
      <div class="event-group">
        <div class="group-title">
          <span *ngIf="model.orphanTask">Görevin Konusu :</span>
          <span *ngIf="model.eventCalendarDetail">Etkinlik Adı :</span>
        </div>
        <div class="group-desc">
          <span *ngIf="model.orphanTask">{{ model.orphanTask.subject }}</span>
          <span *ngIf="model.eventCalendarDetail">{{
            model.eventCalendarDetail.subject
          }}</span>
        </div>
      </div>
      <div class="event-group" *ngIf="model.orphanTask">
        <div class="group-title">
          <span>Açıklama :</span>
        </div>
        <div class="group-desc">
          <span>{{ model.orphanTask.description }}</span>
        </div>
      </div>
      <div class="event-group" *ngIf="model.eventCalendarDetail">
        <div class="group-title">
          <span>Saati :</span>
        </div>
        <div class="group-desc">
          <span>{{ model.eventCalendarDetail?.startDate | date: "dd.MM.yyyy HH:mm" }} /
            {{ model.eventCalendarDetail?.endDate | date: "dd.MM.yyyy HH:mm"  }}</span>
        </div>
      </div>

      <div class="event-group" *ngIf="model.eventCalendarDetail">
        <div class="group-title">
          <span>Etkinlik Türü :</span>
        </div>
        <div class="group-desc">
          <span>{{
            model.orphanEventTypeName
          }}</span>
        </div>
      </div>

      <div class="event-group">
        <div class="group-title">
          <span>Yer :</span>
        </div>
        <div class="group-desc">
          <a
            (click)="goTaskDetail(model.orphanTask.id)"
            *ngIf="model.orphanTask"
          >
            <span>Göreve Git</span>
          </a>
          <a href="#" *ngIf="model.eventCalendarDetail">
            <span>{{ model.eventCalendarDetail?.eventLocationOrUrl }}</span>
          </a>
        </div>
      </div>
      <button
        type="button"
        class="close modal-close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span class="icon-modal-close"></span>
      </button>
    </div>
  </div>
</div>
