import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrphanViewDto } from 'src/app/models/orphan/profile';
import { OrphanEducationModel, TakenEducationViewModel } from 'src/app/models/orphan/taken-education';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class TakenEducationService extends GenericCrudService<OrphanEducationModel, number>{

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanEducations")
  }

  getOrphanEducationAllWithOrphanId(id: number): Observable<OrphanEducationModel[]> {
    return this.http.get<OrphanEducationModel[]>(this.baseUrl + "/GetOrphanEducationAllWithOrphanId?orphanId=" + id);
  }

  getOrphanEducationForEdit(id: number): Observable<OrphanEducationModel> {
    return this.http.get<OrphanEducationModel>(this.baseUrl + "/GetOrphanEducationForEdit?id=" + id);
  }

  downloadBinaryFile(binaryFileId: string): Observable<Blob> {
    return this.http.get(environment.apiRoot + "/File/DownloadBinaryFile?id=" + binaryFileId, { responseType: "blob" })
  }

  removeCatalogImageFile(id: number): Observable<any> {
    return this.http.delete<any[]>(this.baseUrl + "/RemoveCatalogImageFile?Id=" + id);
  }
}
