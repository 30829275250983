<div *ngIf="isPageDone">
  <form [formGroup]="form" #certificateForm="ngForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button type="button" class="close modal-close">
        <span (click)="onClose()" class="icon-modal-close"> </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-title">
        <span>Sertifika Ekle</span>
      </div>
      <div class="row flex-column">
        <div class="col-lg-12 mb-4">
          <div
            class="default-input-group"
            *ngIf="!orphanModel?.certificateFileName"
          >
            <div class="title">
              <span>Sertifikanızın PDF dokümanını Yükleyin</span>
            </div>
            <div class="file">
              <input
                type="file"
                class="d-none"
                id="file-upload"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <label class="file-upload" for="file-upload">
                <span class="icon-uploud icon"></span>
                <span class="upload-desc">Dosyanızı Buradan Yükleyiniz</span>
              </label>
              <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
            </div>
          </div>
          <ng-container *ngIf="orphanModel?.certificateFileName">
            <a
              (click)="
                downloadFile(
                  orphanModel.orphan.certificateFileId,
                  orphanModel.certificateFileName
                )
              "
              mat-button
            >
              {{ orphanModel.certificateFileName }}
              <mat-icon color="primary">download</mat-icon>
            </a>
          </ng-container>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Veriliş Tarihi</span>
          </div>
          <div class="input">
            <input
              type="date"
              #certificateDateOfIssue
              max="{{ this.todayDateFormatted }}"
              formControlName="certificateDateOfIssue"
              value="{{
                this.form.controls.certificateDateOfIssue.value | date: 'yyyy-MM-dd'
              }}"
            />
          </div>
        </div>
      </div>
      <div class="btn-content mt-3">
        <div class="add-btn">
          <button [disabled]="!certificateForm.form.valid">Kaydet</button>
        </div>
      </div>
      <button
        type="button"
        class="close modal-close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span class="icon-modal-close"></span>
      </button>
    </div>
  </div>
  </form>
</div>
