<div class="content online-education-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <section class="page-tab">
      <nav class="tab-button">
        <div class="nav" id="nav-tab" role="tablist">
          <a
            class="active"
            data-toggle="tab"
            href="#tab1"
            role="tab"
            aria-selected="true"
            >Online Eğitimler</a
          >
          <a
            data-toggle="tab"
            href="#tab2"
            role="tab"
            aria-selected="false"
            (click)="getUserCourses()"
            >Başvurduğum Eğitimler</a
          >
        </div>
      </nav>
    </section>
  </div>
  <div class="scroll-content">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="tab1" role="tabpanel">
        <div class="online-education-content">
          <div class="row row-gap-30">
            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-6"
              *ngFor="let item of model"
            >
              <div class="item">
                <figure class="m-0">
                  <img
                    src="../../../../assets/images/egitim-gorsel.png"
                    class="img-fluid"
                  />
                </figure>
                <div class="p-3">
                  <div class="box-content text-center">
                    <span class="box-title d-block">Eğitim Adı: </span>
                    <p>
                      {{ item.fullname }}
                    </p>
                  </div>
                  <div class="box-content d-flex align-items-center flex-row">
                    <div class="text-center">
                      <span class="d-block">Başlangıç Tarihi:</span>
                      <p class="d-block">
                        {{ item.startdate * 1000 | date: "dd/MM/yyyy" }}
                      </p>
                    </div>
                    <div class="text-center">
                      <span class="d-block">Bitiş Tarihi:</span>
                      <p class="d-block">
                        {{
                          item.enddate === 0
                            ? "-"
                            : (item.enddate * 1000 | date: "dd/MM/yyyy")
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="box-bottom-content">
                    <a (click)="enrolCourse(item.id)"
                      ><span class="d-block icon-meeting icon"></span>Eğitime
                      Katıl</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="tab2" role="tabpanel">
        <div class="online-education-content">
          <div class="row row-gap-30">
            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-6"
              *ngFor="let item of applicationCourseModel"
            >
              <div class="item">
                <figure class="m-0">
                  <img
                    src="../../../../assets/images/egitim-gorsel.png"
                    class="img-fluid"
                  />
                </figure>
                <div class="p-3">
                  <div class="box-content text-center">
                    <span class="box-title d-block">Eğitim Adı: </span>
                    <p>
                      {{ item.fullname }}
                    </p>
                  </div>
                  <div class="box-content d-flex align-items-center flex-row">
                    <div class="text-center">
                      <span class="d-block">Başlangıç Tarihi:</span>
                      <p class="d-block">
                        <!-- {{ item.startdate * 100 | date: "dd/MM/yyyy" }} -->
                      </p>
                    </div>
                    <div class="text-center">
                      <span class="d-block">Bitiş Tarihi:</span>
                      <p class="d-block">
                        <!-- {{ item.enddate * 100 | date: "dd/MM/yyyy" }} -->
                      </p>
                    </div>
                  </div>
                  <div class="box-bottom-content">
                    <a (click)="unEnrolCourse(item.id)"
                      ><span class="d-block icon-meeting icon"></span>Eğitimden
                      Ayrıl</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
