<!-- <mat-table #table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
    <td mat-cell *matCellDef="let element">{{ element.orphan.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.orphan.lastName }}</td>
  </ng-container>

  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef>Weight</th>
    <td mat-cell *matCellDef="let element">{{ element.orphan.firstName }}</td>
  </ng-container>

  <ng-container matColumnDef="symbol">
    <th mat-header-cell *matHeaderCellDef>Symbol</th>
    <td mat-cell *matCellDef="let element">{{ element.orphan.firstName }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</mat-table> -->
<div>
  <mat-table [dataSource]="dataSource">
    <!-- User name Definition -->
    <ng-container matColumnDef="select">
      <mat-header-cell class="dc-action-icon button-td" *matHeaderCellDef
        >Seç
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="button-td">
        <button mat-icon-button (click)="selectOrphan(row.orphan.id)">
          <mat-icon color="primary">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="setTask">
      <mat-header-cell class="dc-action-icon button-td" *matHeaderCellDef
        >Görev Ata
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="button-td">
        <button mat-icon-button (click)="setTaskForOrphan(row.orphan.id)" *hasPermission="['Pages.OrphanTasks.Edit']">
          <mat-icon color="primary">receipt</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef> Ad </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.orphan.firstName }} </mat-cell>
    </ng-container>

    <!-- Age Definition -->
    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef> Soyad </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.orphan.lastName }} </mat-cell>
    </ng-container>

    <!-- Title Definition -->
    <ng-container matColumnDef="dateOfBirth">
      <mat-header-cell *matHeaderCellDef> Doğum Tarihi </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.orphan.dateOfBirth | date: "dd/MM/yyyy" }}
      </mat-cell>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999" style="text-align: center">
        Gösterilecek kayıt bulunamadı
      </td>
    </tr>

    <!-- Header and Row Declarations -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</div>
