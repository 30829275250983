import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { JobApplicationViewModel, WorkJobModel, WorkJobViewModel } from 'src/app/models/orphan/job-work';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { JobApplicationService, WorkJobService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { JobApplicationEditComponent } from '../job-application-edit/job-application-edit.component';

@Component({
  selector: 'app-work-job-detail',
  templateUrl: './work-job-detail.component.html'
})
export class WorkJobDetailComponent implements OnInit {

  id: number;
  applicationExists: JobApplicationViewModel;
  model: WorkJobModel;
  isPageDone = false;
  isEdit: boolean = false;

  constructor(
    public service: WorkJobService,
    public jobApplicationService: JobApplicationService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.jobApplicationService.jobApplicationExists(this.utilsService.selectedOrphanId, this.id).subscribe(result => {
        this.applicationExists = result;
        this.service.getWorkJob(this.id).subscribe(response => {
          this.model = response;
          this.isPageDone = true;
        })
      })
    })

    console.log(this.utilsService.currentUserRole);
    if(this.utilsService.currentUserRole == "rehberogretmen" || this.utilsService.currentUserRole == "yetimsahakoordinatoru"
     || this.utilsService.currentUserRole == "mentor" ||this.utilsService.currentUserRole == "mentör")
    {
      this.isEdit = true;
    }
  }

  edit(workJobId: number): void {
    const dialogRef = this.dialog.open(JobApplicationEditComponent, {
      data: { workJobId: workJobId, jobApplicationId: this.applicationExists?.id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.jobApplicationService.jobApplicationExists(this.utilsService.selectedOrphanId, this.id).subscribe(response => {
          this.applicationExists = response;
        })
      }
    });
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.jobApplicationService.delete(id).subscribe(result => {
          this.jobApplicationService.jobApplicationExists(this.utilsService.selectedOrphanId, this.id).subscribe(response => {
            this.applicationExists = response;
          })
          this.dialog.closeAll();
        })
      }
    })
  }

}
