import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { EventDataRequestModel, EventModel, EventTypeModel } from 'src/app/models/orphan';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { EventCalendarJoinService, EventCalendarService, EventTypeService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-event-calendar-list',
  templateUrl: './event-calendar-list.component.html',
})
export class EventCalendarListComponent implements OnInit {
  requestModel = new EventDataRequestModel();
  eventListModel: EventModel[] = [];
  eventTypeListModel: EventTypeModel[] = [];
  selectedItem: string = "";

  isPageDone = false;

  constructor(
    public service: EventCalendarService,
    private eventCalendarJoinService: EventCalendarJoinService,
    private eventTypeService: EventTypeService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public router: Router,
    public dialog: MatDialog
  ) { this.utilsService.setTitle("Etkinlik Takvimi"); }

  ngOnInit(): void {

    this.initData();
  }

  listClick(event, newValue) {
    this.selectedItem = newValue;
    this.initData();
  }

  initData(): void {
    this.requestModel.sorting = "startDate desc"
    this.requestModel.orphanEventTypeNameFilter = this.selectedItem;
    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);

    const eventTypePromise$ = this.eventTypeService.getAll().toPromise();
    const eventPromise$ = this.service.getAll(paramsString).toPromise();
    Promise.all([
      eventTypePromise$,
      eventPromise$]).then(result => {
        this.eventTypeListModel = result[0].items;
        this.eventListModel = result[1].items;
        this.isPageDone = true;
      })
  }

  joinEventCalendar(id: number, index: number) {
    let form = new FormGroup({
      eventCalendarDetailId: new FormControl(id),
      userId: new FormControl(this.utilsService.currentUserId),
    });
    this.eventCalendarJoinService.createOrEdit(form.value).subscribe(result => {
      if (result.message == "Ok") {
        this.utilsService.showMessage("Etkinliğe kayıt oldunuz");

        this.eventListModel[index].exists = true
        this.eventListModel[index].eventJoinId = result.data.id
      }
    })
  }


  delete(index: number, key: string, eventId: number) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let joinId = this.eventListModel[index].eventJoinId;
        this.eventCalendarJoinService.delete(joinId).subscribe(result => {

          this.eventCalendarJoinService.existEvent(this.utilsService.currentUserId, eventId).subscribe(response => {
            if (response === 0) {
              this.eventListModel[index].exists = false;
              this.eventListModel[index].eventJoinId = 0;
            }
          })

        })
      }
    })
  }
}
