import { DataRequestModel } from "../generic-base/data-model";

export class OrphanProjectModel {
  orphanProjectDetail: ProjectViewModel;
  orphanFirstName: string;
}

export class ProjectViewModel {
  year: string;
  name: string;
  projectStatus: number;
  orphanId: number;
  id: number;
}

export class ProjectDataRequestModel extends DataRequestModel {
  projectStatusFilter: number;
  orphanIdFilter: number;
}




