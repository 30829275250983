import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService {

  baseUrl = environment.apiRoot + "/api/services";
  constructor(public http: HttpClient) { }

  getAllProvincesForDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/app/Provinces/GetAllProvinceForTableDropdown");
  }

  getAllDistrictWithProvinceIdForDropdown(id: number): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/app/Provinces/GetAllDistrictWithProvinceIdForTableDropdown?provinceId=" + id);
  }

  getAllNeighborhoodtWithDistrictIdForDropdown(id: number): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/app/Provinces/GetAllNeighborhoodtWithDistrictIdForTableDropdown?districtId=" + id);
  }

  getAllNationalForDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/app/Nationalities/GetNationalityLookupTable");
  }

}
