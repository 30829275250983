import { validateRequired } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app-constants';
import { MessageContentModel, MessageInboxModel } from 'src/app/models/orphan';
import { MessageContentService, MessageInboxService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ChatCreateGroupComponent } from './chat-create-group/chat-create-group.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  chatListModel: MessageInboxModel[] = [];
  chatContentModel: MessageContentModel[] = [];
  selectedMessageInboxId = 0;
  form: FormGroup;
  isPageDone = false;

  constructor(
    private messageInboxService: MessageInboxService,
    private messageContentService: MessageContentService,
    public utilsService: UtilsService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      messageContentText: ['', Validators.required],
      messageInboxId: [this.selectedMessageInboxId],
      senderUserId: [this.utilsService.currentUserId]
    });
    this.getAllChats();
  }

  getAllChats() {
    this.messageInboxService.getAll().subscribe(result => {
      this.chatListModel = result.items;
      this.isPageDone = true;
    })
  }

  getMessageContent(id: number) {

    // if (this.selectedMessageInboxId == id) { return; }
    this.selectedMessageInboxId = id;
    this.messageContentService.getAll(id).subscribe(result => {

      this.chatContentModel = result.items;
    })
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(ChatCreateGroupComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.getAllChats();
      }
    });
  }

  onSubmitComment(): void {
    this.form.controls.messageInboxId.setValue(this.selectedMessageInboxId);
    if (this.form.valid) {
      this.messageContentService.createOrEdit(this.form.value).subscribe(result => {
        // if (result.message == "Ok") {
        //   this.utilsService.showMessage("İşlem başarılı");

        // }
        this.form.controls.messageContentText.setValue(null);

        this.getMessageContent(this.selectedMessageInboxId)
      })
    }
  }
}
