<p>login works!</p>

<!-- <form  [formGroup]="loginForm"  (ngSubmit)="onSubmit()">

<input type="text"  formControlName="userName">
<input type="text" formControlName="password">

<button type="submit">Login</button>

{{loginForm.value | json}}

{{loginForm.valid}}

</form> -->
