<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-title">
          <span>Mesaj-Grup Oluştur</span>
        </div>

        <div class="default-input-group mb-4">
          <div class="title">
            <span>Kişiler</span>
          </div>
          <div class="select">
            <select #select>
              <option value="">Seçiniz</option>
              <ng-container *ngFor="let item of userSelectList">
                <option
                  *ngIf="item.id != utilsService.currentUserId"
                  [value]="item.id"
                >
                  {{ item.displayName }}
                </option>
              </ng-container>
            </select>
            <div class="icon">
              <span class="icon-down-thin"></span>
            </div>
          </div>
        </div>
        <ul class="mb-5" *ngIf="selectedUserIds.length > 0">
          <li *ngFor="let item of selectedUsers">{{ item.displayName }}</li>
        </ul>
        <div class="btn-content">
          <div class="add-btn">
            <button type="button" (click)="addUserToSelectedList(select.value)">
              Ekle
            </button>
          </div>
          <ng-container *hasPermission="['Pages.OrphanClubDetails.Edit']">
            <div *ngIf="selectedUserIds.length > 0" class="add-btn">
              <button>Mesaj Oluştur</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
