import { DataRequestModel } from "../generic-base/data-model";

export class OrphanTaskModel {
  orphanTask: TaskViewModel;
  orphanTaskTypeName: string;
  orphanFirstName: string;
  userName: string;
  creatorName: string;
  orphanTaskComments: OrphanTaskCommentModel[] = [];
}
export class TaskViewModel {
  subject: string;
  batchTask: boolean;
  batchWorkTaskReques: BatchWorkTaskReques;
  description: string;
  askQuestion: true;
  question: string;
  questionAnswer: string;
  pointValue: number;
  isCompleted: true;
  completedDate: Date;
  targetCompletedDate: Date;
  orphanTaskTypeId: number;
  orphanId: number;
  targetUserId: number;
  id: number;
}

export class BatchWorkTaskReques {
  ageLimitId: number;
  provinceId: number;
  regionId: number;
  roleId: number;
  userId: number;
}

export class OrphanTaskCommentModel {
  orphanTaskComment: TaskCommentViewModel;
  orphanTaskSubject: string;
  userName: string;
}

export class TaskCommentViewModel {
  commentDate: Date;
  commentText: string;
  userId: number;
  orphanTaskId: number;
  id: number;
}

export class TaskDataRequestModel extends DataRequestModel {
  isCompletedFilter: number;
  targetCompletedDateFilter: string;
  targetUserId: number;
  orphanId: number;
}




