import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TaskTypes, WorkTypes } from 'src/app/models/generic-base/custom-enum-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanLanguageInfoViewModel } from 'src/app/models/orphan';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { LanguageInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-language-info-edit',
  templateUrl: './language-info-edit.component.html'
})
export class LanguageInfoEditComponent extends ModalViewComponent<LanguageInfoEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  model: OrphanLanguageInfoViewModel;
  isPageDone = false;
  isSubmitted = false;
  provinceSelect: DropdownModel[];

  constructor(
    private service: LanguageInfoService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LanguageInfoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      name: ["", Validators.required],
      languageLevelRead: [""],
      languageLevelWrite: [""],
      languageLevelTolk: [""],
      orphanId: [this.utilsService.selectedOrphanId, Validators.required]
    })

    const provincePromise$ = this.service.getAllOrphanForTableDropdown().toPromise();
    Promise.all([provincePromise$]).then(response => {
      this.provinceSelect = response[0]
    }).then(() => {
      if (this.dialogData.id) {
        this.service.getOrphanLanguageForEdit(this.dialogData.id).subscribe(result => {
          this.model = result.orphanLanguageDetail;
          this.form.patchValue(this.model);
        })
      }
    });

    this.isPageDone = true;
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe(response => {
          this.dialogRef.close();
        })
      }
    })
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }
}
