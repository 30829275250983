export class OrphanFileModel {
  fileName: string;
  fileExtension: string;
  referenceNumber: string;
  fileReferanceType: number;
  fileDataBase64: string;
}


export class GetOrphanFileForViewDto {
  orphanFile: OrphanFileModel
}

