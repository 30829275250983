import { DateTime } from "luxon";

export class OrphanEducationModel {
  orphanEducation: TakenEducationViewModel;
  orphanFirstName: string;
  binaryFileName: string;
}
export class TakenEducationViewModel {
  educationName: string;
  educationTime: string;
  pointValue: string;
  description: string;
  documentName: string;
  filePath: string;
  fileExtension: string;
  educationDate: DateTime;
  orphanId: number;
  binaryFileId: string;
  id: number;
}


