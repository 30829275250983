import { Injectable, QueryList, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { OrphanViewDto } from 'src/app/models/orphan';
import { JwtTokenService } from '../auth/jwt-token.service';
import { HasPermissionDirective } from '../permission/has-permissions.directive';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  // private currentUserSubject = new BehaviorSubject<any>({});
  // public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private currentUserPermissionsSubject = new BehaviorSubject<any>({});
  public currentUserPermissions = this.currentUserPermissionsSubject.asObservable().pipe(distinctUntilChanged());
  @ViewChildren(HasPermissionDirective) permissionDirective: QueryList<HasPermissionDirective>

  subTitle: string;
  isAdmin = false;
  currentUserId: number;
  currentUserRole: string;
  currentUserName = "";
  currentUserPermissionsForUser: any[];
  selectedOrphanId: number = 0;
  profilePicture = "../assets/images/userDefault.png";
  // dreamJob: string;
  // subject = new Subject();
  constructor(
    private snackBar: MatSnackBar,
    private jwtLoginService: JwtTokenService
  ) { }

  showMessage(message: string): void {
    this.snackBar.open(message, "ⓘ", {
      duration: 2000,
      horizontalPosition: "right",
      verticalPosition: "bottom"
    });
  }

  setPermissions(permissions: any[]) {
    this.currentUserPermissionsForUser = permissions;
    this.currentUserPermissionsSubject.next(permissions);
    if (this.permissionDirective) {
      this.permissionDirective.forEach((directives: HasPermissionDirective) => {
        directives.checkPermission();
      })
    }

  }

  // setUser(user) {
  //   this.currentUserSubject.next(user);
  // }

  showInfo(message: string): void {
    this.snackBar.open(message, "⚠️", {
      duration: 2000,
      horizontalPosition: "right",
      verticalPosition: "bottom"
    });
  }

  showError(message: string): void {
    this.snackBar.open(message, "X", {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "top"
    });
  }

  setTitle(title: string) {
    this.subTitle = title;
  }

  getTitle() {
    if (this.subTitle != undefined) {
      return this.subTitle;
    }
    else {
      return null
    }
  }

  setUserName(name) {
    this.currentUserName = name;
  }

  getUserName() {
    return this.currentUserName;
  }

  // setOrphanDreamJob(dreamJob?: string) {
  //   this.dreamJob = dreamJob;
  //   this.subject.next(this.dreamJob);
  // }

  setObjectLocalStorage(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  getTokenDecode() {
    // this.jwtLoginService.getToken();
    var myRawToken = String(localStorage.getItem('access_token'));
    if (myRawToken) {
      const helper = new JwtHelperService();
      return helper.decodeToken(myRawToken);
    }
  }

  getObjectLocalStorage(key: string): any {
    const data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    else
      return null;
  }

  getLocalStorage(key: string): any {
    const data = window.localStorage.getItem(key);
    if (data) {
      return data;
    }
    else
      return null;
  }

  removeLocalStorageItem(key: string) {
    window.localStorage.removeItem(key);
  }

  checkFileIsCorrect(size: number, extension: string, _maxSize?: number, extensionArray?: string[]) {
    let maxSize = _maxSize ? _maxSize : 25200000;
    const correctExtensions = extensionArray != null ? extensionArray : ['.jpeg', '.jpg', '.jfif', '.png', '.gif', '.doc', '.docx', '.xls', '.xlsx', '.txt', '.pdf'];

    if (correctExtensions.indexOf(extension.toLowerCase()) == -1) {
      return false;
    }
    if (size > maxSize) {
      return false;
    }
    return true;
  }

  downloadFileBase64String(base64String: string, fileName: string): void {
    fetch(base64String).then(res => res.blob()).then((res) => {
      const link = window.document.createElement("a");

      link.href = window.URL.createObjectURL(res);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
  }

  downloadFile(result: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(result);
    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  urlSearchParamConvertToString(requestModel: any): string {
    let params = new URLSearchParams();
    for (let key in requestModel) {
      params.set(key, requestModel[key]);
    }

    return params.toString();
  }
}
