import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { OrphanEducationModel, TakenEducationViewModel } from 'src/app/models/orphan/taken-education';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { TakenEducationService } from 'src/app/services/orphan/taken-education.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TakeEducationEditComponent } from './take-education-edit/take-education-edit.component';

@Component({
  selector: 'app-taken-education',
  templateUrl: './taken-education.component.html',
  styleUrls: ['./taken-education.component.css']
})
export class TakenEducationComponent implements OnInit {

  model: OrphanEducationModel[];

  constructor(
    private service: TakenEducationService,
    public utilsService: UtilsService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Aldığım Eğitimler");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.service.getOrphanEducationAllWithOrphanId(this.utilsService.selectedOrphanId).subscribe(result => {
      this.model = result;
    });
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(TakeEducationEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      /*if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }*/

      this.reloadList();

    });
  }

  downloadFile(binaryFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(binaryFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }
}
