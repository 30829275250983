<h1 mat-dialog-title>{{ dialogData.title }}</h1>
<div mat-dialog-content align="center">
  {{ dialogData.message }}
  <div class="btn-content">
    <div class="delete-btn">
      <button [matDialogClose]="false">İptal</button>
    </div>
    <div class="add-btn">
      <button [matDialogClose]="true" cdkFocusInitial>Tamam</button>
    </div>
  </div>
</div>
<!-- <div mat-dialog-actions align="end">
  <button mat-raised-button color="accent" [matDialogClose]="false">
    <mat-icon>cancel</mat-icon>İptal
  </button>
  <button
    mat-raised-button
    color="primary"
    [matDialogClose]="true"
    cdkFocusInitial
  >
    <mat-icon>done</mat-icon>Tamam
  </button>
</div> -->
