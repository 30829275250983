import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanProjectModel, ProjectViewModel } from 'src/app/models/orphan/project';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends GenericCrudService<ProjectViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanProjectDetails")
  }

  getOrphanProjectAll(id: number, params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params.toString());
  }

  getOrphanProjectAllWithOrphanId(id: number): Observable<OrphanProjectModel[]> {
    return this.http.get<OrphanProjectModel[]>(this.baseUrl + "/GetOrphanProjectAllWithOrphanId?orphanId=" + id);
  }

  getOrphanProjectDetailForEdit(id: number): Observable<OrphanProjectModel> {
    return this.http.get<OrphanProjectModel>(this.baseUrl + "/GetOrphanProjectDetailForEdit?id=" + id);
  }

  getAllOrphanForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllOrphanForTableDropdown");
  }
}
