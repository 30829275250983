import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { GetNotificationModel } from 'src/app/models/shared/notification';
import { environment } from 'src/environments/environment';
import { UserNotificationState } from 'src/shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public http: HttpClient
  ) { }

  getUserNotifications(state: UserNotificationState | undefined, startDate: DateTime | undefined, endDate: DateTime | undefined, maxResultCount: number | undefined, skipCount: number | undefined): Observable<GetNotificationModel> {
    let url_ = environment.apiRoot + "/api/services/app/Notification/GetUserNotifications?";
    if (state === null)
      throw new Error("The parameter 'state' cannot be null.");
    else if (state !== undefined)
      url_ += "State=" + encodeURIComponent("" + state) + "&";
    if (startDate === null)
      throw new Error("The parameter 'startDate' cannot be null.");
    else if (startDate !== undefined)
      url_ += "StartDate=" + encodeURIComponent(startDate ? "" + startDate.toJSON() : "") + "&";
    if (endDate === null)
      throw new Error("The parameter 'endDate' cannot be null.");
    else if (endDate !== undefined)
      url_ += "EndDate=" + encodeURIComponent(endDate ? "" + endDate.toJSON() : "") + "&";
    if (maxResultCount === null)
      throw new Error("The parameter 'maxResultCount' cannot be null.");
    else if (maxResultCount !== undefined)
      url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
    if (skipCount === null)
      throw new Error("The parameter 'skipCount' cannot be null.");
    else if (skipCount !== undefined)
      url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
    url_ = url_.replace(/[?&]$/, "");

    return this.http.get<GetNotificationModel>(url_);
  }
}
