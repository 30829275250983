import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanClubInfoModel, OrphanClubInfoViewModel } from 'src/app/models/orphan/club-info';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ClubInfoService extends GenericCrudService<OrphanClubInfoViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanClubDetails")
  }

  getOrphanClubAllWithOrphanId(id: number): Observable<OrphanClubInfoModel[]> {
    return this.http.get<OrphanClubInfoModel[]>(this.baseUrl + "/GetOrphanClubAllWithOrphanId?orphanId=" + id);
  }

  getOrphanClubDetailForEdit(id: number): Observable<OrphanClubInfoModel> {
    return this.http.get<OrphanClubInfoModel>(this.baseUrl + "/GetOrphanClubDetailForEdit?id=" + id);
  }

  getAllOrphanForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllOrphanForTableDropdown");
  }
}
