import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { EventViewModel, OrphanTaskCommentModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class EventCalendarCommentService extends GenericCrudService<EventViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/EventCalendarComments")
  }

  getAllCommentsWithEventId(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAllCommentsWithEventId?" + params);
  }

  getOrphanTaskCommentForEdit(id: number): Observable<OrphanTaskCommentModel> {
    return this.http.get<OrphanTaskCommentModel>(this.baseUrl + "/GetOrphanTaskCommentForEdit?id=" + id);
  }

  getAllOrphanTasForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllOrphanTaskForTableDropdown");
  }
}
