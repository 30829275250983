import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsModel } from 'src/app/models/orphan/news';
import { NewsService } from 'src/app/services/orphan';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: NewsModel;

  constructor(private service: NewsService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.service.getNewsAndAnnouncement(this.id).subscribe(result => {
        this.model = result;
        this.isPageDone = true;
      })
    })
  }

  getDownloadUrl(id: string): string {
    return environment.apiRoot + '/File/DownloadBinaryFile?id=' + id;
  }
}
