import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { MessageInboxService, UserService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-chat-create-group',
  templateUrl: './chat-create-group.component.html',
  styleUrls: ['./chat-create-group.component.css']
})
export class ChatCreateGroupComponent extends ModalViewComponent<ChatCreateGroupComponent> implements OnInit {


  isPageDone = false;
  userSelectList: DropdownModel[] = [];
  selectedUserIds: any[] = [];
  selectedUsers: DropdownModel[] = [];
  form: FormGroup;
  constructor(
    private messageInboxService: MessageInboxService,
    public utilsService: UtilsService,
    private userService: UserService,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChatCreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      groupOrChatName: ['asdasd'],
      userIds: []
    });

    this.userService.getUsersLookupTable().subscribe(result => {
      this.userSelectList = result;
      this.isPageDone = true;
    });
  }

  addUserToSelectedList(selectedUserId: any) {
    if (this.selectedUserIds.find(p => p == selectedUserId)) {
      return;
    }
    this.selectedUserIds.push(selectedUserId);
    this.selectedUsers.push(this.userSelectList.find(p => p.id == selectedUserId))

    this.form.controls.groupOrChatName.setValue(this.selectedUsers.map(c => c.displayName).join(','));
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.selectedUserIds.push(this.utilsService.currentUserId);
      this.form.controls.userIds.setValue(this.selectedUserIds);
      this.messageInboxService.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
          this.dialogRef.close(this.form.value);
        }
        else {
          this.utilsService.showMessage("Bu kullanıcıyla konuşmanız bulunuyor.")
        }
      })
    }
  }
}
