import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { WorkJobModel, WorkJobViewModel } from 'src/app/models/orphan/job-work';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class WorkJobService extends GenericCrudService<WorkJobViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/WorkJobs")
  }

  getActiveAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetActiveAll?" + params);
  }

  getWorkJob(id: number): Observable<WorkJobModel> {
    return this.http.get<WorkJobModel>(this.baseUrl + "/GetWorkJobForView?id=" + id);
  }
}
