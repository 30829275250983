<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Bulunduğu İl</span>
          </div>
          <div class="select">
            <select formControlName="schoolProvinceId">
              <option value="">Seçiniz</option>
              <option [value]="item.id" *ngFor="let item of provinceSelect">
                {{ item.displayName }}
              </option>
            </select>
            <span class="text-danger" *ngIf="form.get('schoolProvinceId').errors &&
            form.get('schoolProvinceId').hasError('required')">* Bu Alan Zorunludur</span>
            <div class="icon">
              <span class="icon-down-thin"></span>
            </div>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Okul Adı</span>
          </div>
          <div class="input">
            <input type="text" formControlName="schoolName" />
            <span class="text-danger" *ngIf="form.get('schoolName').errors &&
            form.get('schoolName').hasError('required')">* Bu Alan Zorunludur</span>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Bölüm/Program Adı</span>
          </div>
          <div class="input">
            <input type="text" formControlName="programName" />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Eğitim Türü</span>
          </div>
          <div class="select">
            <select formControlName="educationTypeId">
              <option value="">Seçiniz</option>
              <option
                [value]="item.id"
                *ngFor="let item of educationTypeSelect"
              >
                {{ item.displayName }} eğitim türü
              </option>
            </select>
            <span class="text-danger" *ngIf="form.get('educationTypeId').errors &&
            form.get('educationTypeId').hasError('required')">* Bu Alan Zorunludur</span>
            <div class="icon">
              <span class="icon-down-thin"></span>
            </div>
          </div>
          <br>
          <div class="item-group mb-4">
            <div class="title">
              <span>Mezuniyet Durumu</span>
            </div>
            <div class="select">
              <select
                (change)="availabilityStatusChange()"
                formControlName="availabilityStatusId"
              >
                <option value="">Seçiniz</option>
                <option
                  [value]="item.id"
                  *ngFor="let item of availableStatusSelect"
                >
                  {{ item.displayName }}
                </option>
              </select>
              <span class="text-danger" *ngIf="form.get('availabilityStatusId').errors &&
            form.get('availabilityStatusId').hasError('required')">* Bu Alan Zorunludur</span>
              <div class="icon">
                <span class="icon-down-thin"></span>
              </div>
            </div>
          </div>
          <div *ngIf="isShow" class="default-input-group mb-4">
            <div class="title">
              <span>Sınıfı</span>
            </div>
            <div class="input">
              <input type="text" formControlName="schoolClassName" />
            </div>
          </div>
          <div *ngIf="isGraduationDateShow" class="item-date">
            <div class="title">
              <span>Mezuniyet Tarihi</span>
            </div>
            <div class="input">
              <input
                type="date"
                max="{{date | date:'yyyy-MM-dd'}}"
                #educationDate
                formControlName="availabilityDate"
                value="{{
                  this.form.controls.availabilityDate.value | date: 'yyyy-MM-dd'
                }}"
              />
              <span class="text-danger" *ngIf="availabilityDateRequired">
                * Bu Alan Zorunludur</span>
            </div>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Dosya</span>
          </div>
          <div class="input">
            <fieldset class="dc-fieldset" *ngIf="!model?.binaryFileName">
              <input
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <div class="file-upload">
                Dosya Yükleyin
                <button
                  mat-mini-fab
                  color="primary"
                  type="button"
                  class="upload-btn"
                  (click)="fileUpload.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
              <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
            </fieldset>
            <ng-container *ngIf="model?.binaryFileName">
              <a
                (click)="
                  downloadFile(
                    model.orphanSchool.binaryFileId,
                    model.binaryFileName
                  )
                "
                mat-button
              >
                {{ model.binaryFileName }}
                <mat-icon color="primary">download</mat-icon>
              </a>

              <a
                (click)="
                  deleteFile(model.orphanSchool.id, model.binaryFileName, i)
                "
                mat-button
              >
                Sil
                <mat-icon color="accent">delete</mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
        <div class="btn-content">
          <div
            class="delete-btn"
            *hasPermission="['Pages.OrphanSchools.Delete']"
          >
            <button
              type="button"
              *ngIf="model"
              (click)="delete(model.orphanSchool.id, model.orphanSchool.name)"
            >
              Öğrenim Bilgisini Sil
            </button>
          </div>
          <div
            class="add-btn"
            *hasPermission="['Pages.OrphanSchools.Edit']"
          >
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
