import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { AyniNakdiYardimDataRequestModel, AyniNakdiYardimModel, AyniNakdiYardimViewModel } from 'src/app/models/orphan';
import { OdemeRequestViewModel, OdemeResultViewModel } from 'src/app/models/orphan/ayni-yardim-kizilay';
import { AyniNakdiYardimService, AyniYardimKizilayService, ProfileService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-ayni-nakdi-yardim',
  templateUrl: './ayni-nakdi-yardim.component.html'
})
export class AyniNakdiYardimComponent implements OnInit {

  isPageDone = false;
  model: AyniNakdiYardimModel[];
  responseData: any[] = [];
  dataCount: number;
  requestModel = new AyniNakdiYardimDataRequestModel();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public service: AyniNakdiYardimService,
    public ayniYardimKizilayService: AyniYardimKizilayService,
    public profileService: ProfileService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) {
    this.utilsService.setTitle("Ayni nakdi yardım");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 200;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.orphanId = this.utilsService.selectedOrphanId;

    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);
    this.service.getAllWithOrphan(paramsString).subscribe(result => {
      this.model = result.items;
      this.dataCount = result.totalCount;
      this.isPageDone = true;
    });
  }


  ayniNakdiYardimDetail(belgeNo: string) {
    if (!this.utilsService.selectedOrphanId) {
      this.utilsService.showError("Kullanıcı bilgisine ulaşılamadı");
      return;
    }
    this.profileService.getOrphan(this.utilsService.selectedOrphanId).subscribe(result => {

      var model = new OdemeRequestViewModel();
      model.TCKN = result.orphan.tcNumber;
      model.BELGE_NO = belgeNo;
      model.ANA_BELGE_NO = belgeNo;
      model.MDG_ID = result.orphan.mdgMuhatapNo;

      //model.TCKN = "36989106136";
      //model.BELGE_NO = "5100129502";
      //model.ANA_BELGE_NO = "5100129502";
      //model.MDG_ID = "1000503069";

      console.log("Request - TCKN: " + model.TCKN + " / BELGE_NO: "+ model.BELGE_NO + " / ANA_BELGE_NO: " + model.ANA_BELGE_NO + " / MDG_ID: " + model.MDG_ID);
      this.ayniYardimKizilayService.OdemeDurumBilgisi(model).subscribe(response => {
      console.log(response)

        // var a = Math.floor(Math.random() * 12);

        this.model = response.result["result"];
        // var data = this.responseData[0];
        // for (let index = 0; index < a; index++) {
        //   this.responseData.push(data);
        // }

        // console.log(this.responseData)
      });
    })
  }
}
