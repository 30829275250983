<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-title">
        <span *ngIf="!model">Dil Ekle</span>
        <span *ngIf="model">Dili Düzenle</span>
      </div>
      <div class="row flex-column">
        <div class="col-lg-12 mb-4">
          <div class="default-input-group">
            <div class="title">
              <span>Dil Adı</span>
            </div>
            <div class="input">
              <input
                type="text"
                formControlName="name"
                placeholder="Dil Adını Yazınız"
              />
              <span class="text-danger" *ngIf="form.get('name').errors && 
              form.get('name').hasError('required')">* Bu Alan Zorunludur</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="default-input-group">
            <div class="title">
              <span>Okuma</span>
            </div>
            <div class="select">
              <select formControlName="languageLevelRead">
                <option selected hidden>Seç</option>
                <option value="0">İyi</option>
                <option value="1">Orta</option>
                <option value="2">Kötü</option>
              </select>
              <span class="text-danger" *ngIf="form.get('languageLevelRead').errors && 
              form.get('languageLevelRead').hasError('required')">* Bu Alan Zorunludur</span>
              <div class="icon">
                <span class="icon-down-thin"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="default-input-group">
            <div class="title">
              <span>Yazma</span>
            </div>
            <div class="select">
              <select formControlName="languageLevelWrite">
                <option selected hidden>Seç</option>
                <option value="0">İyi</option>
                <option value="1">Orta</option>
                <option value="2">Kötü</option>
              </select>
              <span class="text-danger" *ngIf="form.get('languageLevelWrite').errors && 
              form.get('languageLevelWrite').hasError('required')">* Bu Alan Zorunludur</span>
              <div class="icon">
                <span class="icon-down-thin"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <div class="default-input-group">
            <div class="title">
              <span>Konuşma</span>
            </div>
            <div class="select">
              <select formControlName="languageLevelTolk">
                <option selected hidden>Seç</option>
                <option value="0">İyi</option>
                <option value="1">Orta</option>
                <option value="2">Kötü</option>
              </select>
              <span class="text-danger" *ngIf="form.get('languageLevelTolk').errors && 
              form.get('languageLevelTolk').hasError('required')">* Bu Alan Zorunludur</span>
              <div class="icon">
                <span class="icon-down-thin"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-content mt-3">
        <div
          class="delete-btn"
          *hasPermission="['Pages.OrphanLanguageDetails.Delete']"
        >
          <button
            type="button"
            *ngIf="model"
            (click)="delete(model.id, model.name)"
          >
            Dili Sil
          </button>
        </div>
        <div
          class="add-btn"
          *hasPermission="['Pages.OrphanLanguageDetails.Create']"
        >
          <button>Kaydet</button>
        </div>
      </div>
    </div>
  </form>
</div>
