import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { ProfileService } from 'src/app/services/orphan/profile.service';
import { ProvinceService } from 'src/app/services/orphan/province.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { map as _map, filter as _filter } from 'lodash-es';
import { MaritalStatusName, OrphanViewDto, UserModel, UserRoleModel } from 'src/app/models/orphan';
import { UserService } from 'src/app/services/orphan';
import { MaritalStatus } from 'src/shared/service-proxies/service-proxies';
import { MaritalStatusLanguage } from 'src/app/models/generic-base/custom-enum-model';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.css']
})
export class PersonalInfoComponent implements OnInit {

  form: FormGroup;
  model: OrphanViewDto;
  isEdit = false;
  isPageDone = false;
  isSubmitted = false;
  provinceSelect: DropdownModel[];
  districtSelect: DropdownModel[];
  neighborhoodSelect: DropdownModel[];
  nationalSelect: DropdownModel[];
  totalUserCreatedDays: number = 0;
  userProxy: UserModel;
  userProxyRoles: UserRoleModel[] = [];
  maritalStatusSelect = Object.values(MaritalStatus).filter(p => !isNaN(Number(p)));
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(
    public service: ProfileService,
    public formBuilder: FormBuilder,
    private provinceService: ProvinceService,
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public utilsService: UtilsService
  ) {
    this.utilsService.setTitle("Kişisel Bilgilerim");
  }


  ngOnInit(): void {
    if (this.utilsService.currentUserRole == "yetim" || this.utilsService.selectedOrphanId > 0) {
      // if (this.utilsService.currentUserRole == "yetim") {
      this.form = this.formBuilder.group({
        address: [""],
        apartmentNumber: [""],
        dateOfBirth: [""],
        districtId: [""],
        eMail: [""],
        fatherName: [""],
        firstName: ["", Validators.required],
        gender:["",Validators.required],
        homeNumber: [""],
        id: 0,
        lastName: ["", Validators.required],
        maritalStatus: [""],
        mdgMuhatapNo: [""],
        mobileNumber: [""],
        motherName: [""],
        nationality: [""],
        neighborhoodId: [""],
        placeOfBirth: [""],
        postCode: [""],
        provinceId: [""],
        tcNumber: [""]
      });

      this.service.getOrphan(this.utilsService.selectedOrphanId ? this.utilsService.selectedOrphanId : this.utilsService.currentUserId).subscribe(result => {
        this.model = result;
        this.form.patchValue(this.model.orphan);
        this.form.controls["dateOfBirth"].setValue(this.model.orphan.dateOfBirth.toString().split('T')[0])
        this.provinceService.getAllProvincesForDropdown().subscribe(provinceResult => {
          this.provinceSelect = provinceResult;
        });
        if (this.model.orphan.provinceId) {
          this.provinceService.getAllDistrictWithProvinceIdForDropdown(this.model.orphan.provinceId).subscribe(districtResult => {
            this.districtSelect = districtResult;
            if (this.model.orphan.districtId) {
              this.provinceService.getAllNeighborhoodtWithDistrictIdForDropdown(this.model.orphan.districtId).subscribe(neighborhoodtResult => {
                this.neighborhoodSelect = neighborhoodtResult
              })
            }
          });
        }

        const nationalPromise$ = this.provinceService.getAllNationalForDropdown().toPromise();
        Promise.all([nationalPromise$]).then(response => {
          this.nationalSelect = response[0]
          this.isPageDone = true;
        })
      })
    }
    else {
      this.form = this.formBuilder.group({
        id: 0,
        name: [""],
        surname: [""],
        userName: [""],
        emailAddress: ["", Validators.required],
        phoneNumber: [""],
        //phoneNumber: ["", [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]],
        password: [""],
        isActive: [""],
        regionId: [""],
        branchId: [""],
        representativeId: [""],
        isOrphanManager: [""],
        dateBirth: [""],
        lifeCity: [""],
        address: [""],
        workTitle: [""],
        beforeSpecialist: [""],
        pastExperience: [""],
        workStartDate: [""],
        createdDate: [""],
        assignedRoleNames: []
      })

      this.userService.getUserForEdit(this.utilsService.currentUserId).subscribe(e => {

        this.form.patchValue(e.user);
        this.userProxyRoles = e.roles;
        this.form.controls["dateBirth"].setValue(e.user.dateBirth.toString().split('T')[0])
        this.form.controls["workStartDate"].setValue(e.user.workStartDate.toString().split('T')[0])
        var days = this.calculateDiff(e.user.createdDate)
        this.totalUserCreatedDays = days / 365;
        this.isPageDone = true;
      })
    }
    this.form.disable();
  }

  changeProvince(event) {
    this.districtSelect = null;
    this.neighborhoodSelect = null;

    this.provinceService.getAllDistrictWithProvinceIdForDropdown(event.target.value).subscribe(result => {
      this.districtSelect = result;
    });
  }

  changeDistrict(event) {
    this.neighborhoodSelect = null;

    this.provinceService.getAllNeighborhoodtWithDistrictIdForDropdown(event.target.value).subscribe(result => {
      this.neighborhoodSelect = result;
    });
  }

  changeEdit(): void {
    this.isEdit = !this.isEdit;
    if (this.isEdit) {
      this.form.enable();
    }
    else {
      this.form.disable();
    }
  }

  tcNoOnInput(event): boolean {
    const charCode = (event.which)?event.which: event.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    else
        return true;
}

  getMaritalStatusName(key: string | undefined): string {
    return MaritalStatusLanguage[key];
  }

  createOrEditOrphan() {
    this.service.createOrEdit(this.form.value).subscribe(result => {
      this.service.getOrphan(this.utilsService.selectedOrphanId).subscribe(response => {

        this.model = response;
        this.form.patchValue(this.model.orphan);
        this.form.controls["dateOfBirth"].setValue(this.model.orphan.dateOfBirth.toString().split('T')[0])
        this.isEdit = false;
        this.form.disable();
      });
    });
  }

  onSubmit(): void {
    debugger
    this.isSubmitted = true;
    console.log(this.form.controls);
    if (this.form.valid) {
      this.utilsService.showInfo("İşlem gerçekleştiriliyor,lütfen bekleyiniz")
      if (this.utilsService.currentUserRole == "yetim" || this.utilsService.selectedOrphanId > 0) {
        //if (this.form.controls.nationality.value == "Türkiye" && this.form.controls.tcNumber.value != null) {
        if (this.form.controls.tcNumber.value != null && this.form.controls.dateOfBirth.value != null) {
          this.service.validateTcNumber(this.form.value).subscribe(res => {
            if (res?.result == false) {
              this.utilsService.showError("Tc Kimlik numarası doğrulaması başarısız!\n Lütfen Tc Kimlik numarasını ve doğum tarihi bilgilerini kontrol ediniz");
              return;
            }
            else {
              this.createOrEditOrphan();
            }
          })
        }
        else {
          this.createOrEditOrphan();
        }

      }
      else {
        let input = new UserModel();
        input.user = this.form.value;

        input.assignedRoleNames = _map(
          _filter(this.userProxyRoles, { isAssigned: true, inheritedFromOrganizationUnit: false }),
          (role) => role.roleName
        );
        this.userService.createOrEdit(input).subscribe((res) => {
          this.isEdit = false;
          this.form.disable();
        });
      }
    }
  }

  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
  }
}
