import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { JobApplicationModel, JobApplicationViewModel } from 'src/app/models/orphan/job-work';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class JobApplicationService extends GenericCrudService<JobApplicationViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/JobApplications")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  getOrphanJobApplicationAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetOrphanJobApplicationAll?" + params);
  }


  getJobApplicationForEdit(id: number): Observable<JobApplicationModel> {
    return this.http.get<JobApplicationModel>(this.baseUrl + "/GetJobApplicationForEdit?id=" + id);
  }

  jobApplicationExists(orphanId: number, jobId: number): Observable<JobApplicationViewModel> {
    return this.http.post<JobApplicationViewModel>(this.baseUrl + "/OrphanJobApplicationExists?orphanId=" + orphanId + "&jobId=" + jobId, null);
  }


  downloadBinaryFile(binaryFileId: string): Observable<Blob> {
    return this.http.get(environment.apiRoot + "/File/DownloadBinaryFile?id=" + binaryFileId, { responseType: "blob" })
  }

  removeCatalogImageFile(id: number): Observable<any> {
    return this.http.delete<any[]>(this.baseUrl + "/RemoveCatalogImageFile?Id=" + id);
  }
}
