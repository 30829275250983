import { Pipe, PipeTransform } from '@angular/core';
import { MenuModel } from 'src/app/models/shared/menu';

import menus from '../../../assets/menu.json';

@Pipe({
  name: 'demo'
})

export class DemoPipe implements PipeTransform {
  menuList: MenuModel[] = menus;
  transform(name: string, role: string) {
    if (this.menuList.find(p => p.name == name)) {
      let menu = this.menuList.find(p => p.name == name);
      if (menu.name != "Yetim Listesi") {
        if (menu.roles.indexOf(role) != -1)
          return true;
        else {
          return false;
        }
      }
    }
    return false;
  }
}
