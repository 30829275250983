import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TaskTypes, TaskTypesLanguage, WorkTypes } from 'src/app/models/generic-base/custom-enum-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanExperienceModel } from 'src/app/models/orphan';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { ExperienceService } from 'src/app/services/orphan/experience.service';
import { SchoolInfoService } from 'src/app/services/orphan/school-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TaskType } from 'src/shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-experience-edit',
  templateUrl: './experience-edit.component.html',
  styleUrls: ['./experience-edit.component.css']
})
export class ExperienceEditComponent extends ModalViewComponent<ExperienceEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  model: OrphanExperienceModel;
  isPageDone = false;
  isSubmitted = false;
  todayDateFormatted: String;
  provinceSelect: DropdownModel[];
  taskTypeSelect = Object.values(TaskTypes).filter(p => !isNaN(Number(p)));
  workTypeSelect = Object.values(WorkTypes).filter(p => !isNaN(Number(p)));

  constructor(
    private service: ExperienceService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExperienceEditComponent>,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      companyName: ["", Validators.required],
      companySector: ["",],
      description: [""],
      companyPosition: [""],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      taskType: ["", Validators.required],
      oprhanWorkType: [""],
      companyProvinceId: [""],
      orphanId: [this.utilsService.selectedOrphanId !== 0 ? this.utilsService.selectedOrphanId : null],
      userId: [this.utilsService.currentUserId]
    })

    const provincePromise$ = this.service.getAllProvinceForTableDropdown().toPromise();
    Promise.all([provincePromise$]).then(response => {
      this.provinceSelect = response[0]
    }).then(() => {
      if (this.dialogData.id) {
        this.service.getOrphanExperienceForEdit(this.dialogData.id).subscribe(result => {
          this.model = result;
          this.form.patchValue(this.model.orphanExperience);
        })
      }
    });

    this.todayDateFormatted= this.datepipe.transform(new Date(),'yyyy-MM-dd')
    this.isPageDone = true;

  }

  getTaskTypeName(id: string): string {
    return TaskTypesLanguage[id];
  }

  getWorkTypeName(id: string): string {
    return WorkTypes[id];
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe((response) => {

          this.dialogRef.close();
        })
      }
    })
  }

  onSubmit(): void {
    if (this.form.valid) {
      if (this.form.controls.endDate.value <= this.form.controls.startDate.value) {
        this.utilsService.showError("Ayrılış tarihi başlama tarihinden önce olamaz");
        return;
      }
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }
}
