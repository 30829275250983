
import { ServiceProxyModule } from 'src/shared/service-proxies/service-proxy.module';
import { API_BASE_URL } from 'src/shared/service-proxies/service-proxies';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './shared/angular-material.module';
import { OrphanModule } from './components/orphan/orphan.module';
import { ManagerModule } from './components/yonetici/manager.module';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ServerErrorInterceptor } from './shared/intercepts/error.interceptor';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import localetr from '@angular/common/locales/tr'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { DemoPipe } from './shared/pipes/menu-permission.pipe';
import { HeaderNotificationsComponent } from './components/shared/notifications/header-notifications.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerInterceptor } from './shared/intercepts/spinner.interceptor';
export function tokenGetter() {
  return localStorage.getItem("access_token");
}

registerLocaleData(localetr);

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ConfirmationDialogComponent,
    HeaderNotificationsComponent,
    DemoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceProxyModule,
    OrphanModule,
    ManagerModule,
    SlickCarouselModule,
    FullCalendarModule,
    TextMaskModule,
    NgxSpinnerModule,
    OAuthModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", "localhost:44301", environment.applicationRootUrl, environment.apiRootDomain],
      },
    })
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiRoot
    },
    { provide: LOCALE_ID, useValue: "tr" },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
