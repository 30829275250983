import { DataRequestModel } from "../generic-base/data-model";

export class AyniNakdiYardimModel {
  ayniNakdiYardim: AyniNakdiYardimViewModel;
}

export class AyniNakdiYardimViewModel {
  tcNumber: string;
  mdgId: string;
  projeNo: string;
  notlar: string;
  yonlendirenSube: string;
  subeOrganizasyon: string;
  yardimBasvuruTarihi: Date;
  yardimBaslangicTarihi: Date;
  yardimBitisTarihi: Date;
  duzenliBagisAy: number;
  tutar: number;
  paraBirimi: string;
  isbirlikciKurumu: string;
  dosyaEki: string;
  lDapUser: string;
  orphanId: number;
  belgeNo: string;
  sonucKodu: string;
  sonucMesaj: string;
}



export class AyniNakdiYardimDataRequestModel extends DataRequestModel {
  filter: string;
  orphanId: number;
}
