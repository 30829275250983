import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanLanguageInfoModel, OrphanLanguageInfoViewModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageInfoService extends GenericCrudService<OrphanLanguageInfoViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanLanguageDetails")
  }

  getOrphanLanguagesAllWithOrphanId(id: number): Observable<OrphanLanguageInfoModel[]> {
    return this.http.get<OrphanLanguageInfoModel[]>(this.baseUrl + "/GetOrphanLanguagesAllWithOrphanId?orphanId=" + id);
  }

  getOrphanLanguageForEdit(id: number): Observable<OrphanLanguageInfoModel> {
    return this.http.get<OrphanLanguageInfoModel>(this.baseUrl + "/GetOrphanLanguageDetailForEdit?id=" + id);
  }

  getAllOrphanForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllOrphanForTableDropdown");
  }
}
