import { DateTime } from "luxon";

export class InterestModel {
  orphanInterestDetail: InterestViewModel;
  orphanFirstName: string;
}

export class InterestViewModel {
  name: string;
  orphanId: number;
  id: number;
}



