import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from '@fullcalendar/angular';
import { take } from 'rxjs/operators';
import { EventDataRequestModel, EventModel, InterestModel, JobApplicationDataRequestModel, JobApplicationModel, OnlineCourseModel, OrphanTaskandEventModel, WorkJobDataRequestModel, WorkJobModel } from 'src/app/models/orphan';
import { OrphanCommentDataRequestModel, OrphanCommentViewDto, OrphanDto, OrphanViewDto } from 'src/app/models/orphan/profile';
import { OrphanProjectModel, ProjectDataRequestModel } from 'src/app/models/orphan/project';
import { OrphanTaskModel, TaskDataRequestModel } from 'src/app/models/orphan/task-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { EventCalendarService, FileService, InterestService, JobApplicationService, OnlineCourseService, OrphanCommentService, ProjectService, TaskInfoService, WorkJobService } from 'src/app/services/orphan';
import { ProfileService } from 'src/app/services/orphan/profile.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProfileServiceProxy, UpdateProfilePictureInput, UserServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  slideConfig = {
    infinite: false,
    slidesToShow: 3,
    // slidesToScroll: 6,
    vertical: false,
    dots: false,
    swipeToSlide: true,
    arrows: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        }
      }
    ]
  };

  isYetim = false;
  isPageDone = false;
  selectedFileName: string;
  profilePicture: string = "../assets/images/userDefault.png";
  model: OrphanViewDto;
  orphan: OrphanDto;
  year = new Date().getFullYear();
  age: number;
  interestIsEdit = false;
  projectInfoList: OrphanProjectModel[] = [];
  taskInfoList: OrphanTaskModel[] = [];
  onlineCourseList: OnlineCourseModel[] = [];
  applicationCourseModel: OnlineCourseModel[] = [];
  interestList: InterestModel[] = [];
  interestElements = [];
  interestInput = "";
  interestInputShow = false;
  eventList: EventModel[] = [];
  eventandTaskList: OrphanTaskandEventModel[] = [];
  eventCalendarRequestModel = new EventDataRequestModel();
  selectedDate: any;
  workJobList: WorkJobModel[];
  orphanJobApplicationList: JobApplicationModel[];
  orphanCommentList: OrphanCommentViewDto[] = [];
  orphanCommentForm: FormGroup;
  date: Date = new Date;
  formData: FormData = new FormData();
  profileText: string;
  orphanFieldCoordinatorPicture: string = "../assets/images/userDefault.png";
  careerPlanningSpecialistPicture: string = "../assets/images/userDefault.png";
  guidanceCounselorPicture: string = "../assets/images/userDefault.png";

  constructor(
    public service: ProfileService,
    public orphanCommentService: OrphanCommentService,
    private projectService: ProjectService,
    private taskInfoService: TaskInfoService,
    private interestService: InterestService,
    public eventCalendarService: EventCalendarService,
    public workJobService: WorkJobService,
    public jobApplicationservice: JobApplicationService,
    public userServiceProxy: UserServiceProxy,
    private _profileService: ProfileServiceProxy,
    private fileService: FileService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    public router: Router,
    public utilsService: UtilsService,
    public onlineCourseService: OnlineCourseService,
    public datepipe: DatePipe
    ) {
    this.utilsService.setTitle("Profilim");
  }

  ngOnInit(): void {
    let taskRequestModel = new TaskDataRequestModel();
    taskRequestModel.isCompletedFilter = 0;

    if (this.utilsService.currentUserRole == "yetim" || this.utilsService.selectedOrphanId > 0) {
      this.service.getOrphan(this.utilsService.selectedOrphanId).subscribe(result => {
        this.model = result;
        this.orphan = this.model.orphan;

        if(this.orphan.orphanFieldCoordinatorId)
        {
          this._profileService.getProfilePictureByUser(this.orphan.orphanFieldCoordinatorId).subscribe((result) => {
            if (result && result.profilePicture) {
              this.orphanFieldCoordinatorPicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
          });
        }

        if(this.orphan.careerPlanningSpecialistId)
        {
          this._profileService.getProfilePictureByUser(this.orphan.careerPlanningSpecialistId).subscribe((result) => {
            if (result && result.profilePicture) {
              this.careerPlanningSpecialistPicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
          });
        }

        if(this.orphan.guidanceCounselorId)
        {
          this._profileService.getProfilePictureByUser(this.orphan.guidanceCounselorId).subscribe((result) => {
            if (result && result.profilePicture) {
              this.guidanceCounselorPicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
          });
        }

        let yearConvert = this.datepipe.transform(this.orphan.dateOfBirth.toString(), 'yyyy-MM-dd');
        var timeDiff = Math.abs(Date.now() - new Date(yearConvert).getTime());
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

        var districtNameValue="";
        if(this.model?.districtName)
        {
          districtNameValue = this.model?.districtName;
        }

        var provinceNameValue="";
        if(this.model?.provinceName)
        {
          provinceNameValue = this.model?.provinceName;
        }

        this.profileText = districtNameValue + " - " + provinceNameValue;

        if (this.model.orphan.dreamJob != null) {
          localStorage.setItem("dreamJob", this.model.orphan.dreamJob);
        }

        let projectRequestModel = new ProjectDataRequestModel();
        projectRequestModel.orphanIdFilter = this.utilsService.selectedOrphanId;
        let projectParamsString = this.utilsService.urlSearchParamConvertToString(projectRequestModel);

        let workJobRequestModel = new WorkJobDataRequestModel();
        workJobRequestModel.isActiveFilter = 1;
        let workJobParamsString = this.utilsService.urlSearchParamConvertToString(workJobRequestModel);

        let jobApplicationRequestModel = new JobApplicationDataRequestModel();
        jobApplicationRequestModel.orphanId = this.utilsService.selectedOrphanId;
        let jobApplicationParamsString = this.utilsService.urlSearchParamConvertToString(jobApplicationRequestModel);

        let orphanCommentRequestModel = new OrphanCommentDataRequestModel();
        orphanCommentRequestModel.orphanId = this.utilsService.selectedOrphanId;
        let orphanCommentParamsString = this.utilsService.urlSearchParamConvertToString(orphanCommentRequestModel);

        const projectPromise$ = this.projectService.getOrphanProjectAllWithOrphanId(this.utilsService.selectedOrphanId).toPromise();
        const interestPromise$ = this.interestService.getInterestAllWithOrphanId(this.utilsService.selectedOrphanId).toPromise();
        const workJobPromise$ = this.workJobService.getActiveAll(workJobParamsString).toPromise();
        const orphanJobApplcationPromise$ = this.jobApplicationservice.getOrphanJobApplicationAll(jobApplicationParamsString).toPromise();
        const orphanCommentPromise$ = this.orphanCommentService.getAllCommentsWitOrphanId(orphanCommentParamsString).toPromise();

        Promise.all([
          projectPromise$,
          interestPromise$,
          workJobPromise$,
          orphanJobApplcationPromise$,
          orphanCommentPromise$
        ]).then(result => {
          this.projectInfoList = result[0];
          this.interestList = result[1];
          this.workJobList = result[2].items;
          this.orphanJobApplicationList = result[3].items;
          this.orphanCommentList = result[4].items;

          this.orphanCommentForm = this.formBuilder.group({
            id: null,
            commentText: [""],
            commentDate: [this.date],
            userId: [this.utilsService.getTokenDecode()["user_identifier"]],
            orphanId: [this.utilsService.selectedOrphanId],
            orphanIsView: [false]
          });
          this.getProfilePicture(this.utilsService.currentUserId);
          taskRequestModel.orphanId = this.utilsService.selectedOrphanId;
          let taskParamsString = this.utilsService.urlSearchParamConvertToString(taskRequestModel);
          this.handleDateSelect();
          this.getTaskAll(taskParamsString);
          this.isPageDone = true;
        })


          this.onlineCourseService.getCourses().subscribe(response=>{
            this.onlineCourseList = response.result;
            if(this.onlineCourseList)
            {
              if(this.onlineCourseList)
              {
                this.onlineCourseList = this.onlineCourseList?.sort((a, b) => (a.startdate > b.startdate ? -1 : 1))
              }
           }
          });

          ////this.onlineCourseService.getCourses().subscribe(response=>{
            ////this.onlineCourseList = response.result;
            ////if(this.applicationCourseModel)
            ////{
              ////this.onlineCourseService.getEnrolCourses(this.utilsService.currentUserId).subscribe(responseNew => {
                ////this.applicationCourseModel = responseNew.result;
                ////if(this.applicationCourseModel)
                ////{
                    ////if (this.onlineCourseList && this.applicationCourseModel.length > 0) {
                    ////this.onlineCourseList = this.onlineCourseList.filter(p => !this.applicationCourseModel.includes(this.applicationCourseModel.find(c => c.id == p.id)));
                    ////}
                ////}
                ////if(this.onlineCourseList)
                ////{
                  ////this.onlineCourseList = this.onlineCourseList?.sort((a, b) => (a.startdate > b.startdate ? -1 : 1))
                ////}
              ////})
           //// }
          ////});
      })
    }
    else {
      this.userServiceProxy.getUserForEdit(this.utilsService.currentUserId).subscribe(e => {
        this.model = new OrphanViewDto();
        this.model.orphan = new OrphanDto();
        this.model.orphan.id = e.user.id;
        this.model.orphan.firstName = e.user.name;
        this.model.orphan.lastName = e.user.surname;
        taskRequestModel.targetUserId = this.utilsService.currentUserId;
        let taskParamsString = this.utilsService.urlSearchParamConvertToString(taskRequestModel);
        this.getTaskAll(taskParamsString);
        this.getProfilePicture(this.model.orphan.id);
        this.isPageDone = true;

        let myyearConvert = this.datepipe.transform(e.user.dateBirth.toString(), 'yyyy-MM-dd');
        var mytimeDiff = Math.abs(Date.now() - new Date(myyearConvert).getTime());
        this.age = Math.floor(mytimeDiff / (1000 * 3600 * 24) / 365.25);

        this.profileText = e.user.lifeCity;


        const interestPromise$ = this.interestService.getInterestAllWithUserId(this.utilsService.currentUserId).toPromise();

        Promise.all([
          interestPromise$
        ]).then(result => {
          this.interestList = result[0];
        })
      })
    }

  if (localStorage.getItem("orphanId") != null) {
    this.getProfilePicture(this.utilsService.selectedOrphanId);
    this.isYetim = true;
  }
  else
  {
    this.getProfilePicture(this.utilsService.currentUserId);
        this.isYetim = false;
  }

}

  getTaskAll(params: string) {
    this.taskInfoService.getOrphanTaskAll(params).subscribe(result => {
      this.taskInfoList = result.items;
    });
  }

  interestEditChangeStatus(): void {
    this.interestIsEdit = !this.interestIsEdit;
  }

  createInterestElement(): void {
    this.interestElements.push("test");
    this.interestInputShow = true;

  }

  onSubmit(): void {
    var form;
    if(this.isYetim)
    {
      form = this.formBuilder.group({
        orphanId: this.utilsService.selectedOrphanId,
        name: this.interestInput
      })
    }
    else
    {
      form = this.formBuilder.group({
        userId: this.utilsService.currentUserId,
        name: this.interestInput
      })
    }

    this.interestService.createOrEdit(form.value).subscribe(result => {
      this.getInterestItems();
      this.interestInput = "";
      this.interestInputShow = false;
    })


  }

  onSubmitComment(): void {
    if (this.orphanCommentForm.valid) {
      this.orphanCommentService.createOrEdit(this.orphanCommentForm.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
      })
    }
  }

  getInterestItems(): void {
    if(this.isYetim)
    {
      this.interestService.getInterestAllWithOrphanId(this.utilsService.selectedOrphanId).subscribe(response => {
        this.interestList = response;
        this.interestIsEdit = false;
        this.interestElements = [];
      })
    }
    else
    {
      this.interestService.getInterestAllWithUserId(this.utilsService.currentUserId).subscribe(response => {
        this.interestList = response;
        this.interestIsEdit = false;
        this.interestElements = [];
      })
    }
  }

  deleteInterestItem(id: number, key: string): void {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.interestService.delete(id).subscribe(response => {
          this.getInterestItems();
        })
      }
    })
  }

  getProfilePicture(userId: number): void {
    // if (!userId) {
    //     this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
    //     return;
    // }

    if(this.isYetim)
    {
      this._profileService.getOrphanProfilePictureByUser(this.utilsService.selectedOrphanId).subscribe((result) => {
        if (result && result.profilePicture) {
          this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
          //this.utilsService.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
        }
      });
    }
    else
    {
      this._profileService.getProfilePictureByUser(userId).subscribe((result) => {
        if (result && result.profilePicture) {
          this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
          this.utilsService.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
        }
      });
    }
  }

  calendarOptions: CalendarOptions = {
    locale: "tr",
    firstDay: 1,
    contentHeight: 217,
    editable: false,
    selectable: true,
    selectMirror: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    headerToolbar: {
      start: "title",
      center: "",
      end: "prev next"
    },
    events: [{
      title: 'event1',
      start: '2022-04-08',
      allDay: false
    }, {
      title: 'event3',
      start: '2022-03-31',
      allDay: false
    }, {
      title: 'event3',
      start: '2022-04-21',
      allDay: false
    }],
    displayEventTime: true
  };

  calendarVisible = true;
  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo?: DateSelectArg) {
    let currentDate = new Date();

    if (selectInfo) {
      // const calendarApi = selectInfo.view.calendar;
      this.selectedDate = selectInfo.start;
    }

    this.eventCalendarRequestModel.minStartDateFilter = selectInfo ? selectInfo.startStr : currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
    this.eventCalendarRequestModel.maxStartDateFilter = selectInfo ? selectInfo.startStr : currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
    // calendarApi.unselect(); // clear date selection

    let eventCalendarParamsString = this.utilsService.urlSearchParamConvertToString(this.eventCalendarRequestModel);
    this.eventCalendarService.getAll(eventCalendarParamsString).subscribe(result => {
      this.eventandTaskList = result.items;
      let taskRequestModel = new TaskDataRequestModel();
      taskRequestModel.isCompletedFilter = 0;
      taskRequestModel.targetCompletedDateFilter = selectInfo ? selectInfo.startStr : currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
      taskRequestModel.orphanId = this.utilsService.currentUserRole == 'yetim' ? this.utilsService.selectedOrphanId : this.utilsService.currentUserId;
      let taskParamsString = this.utilsService.urlSearchParamConvertToString(taskRequestModel);

      this.taskInfoService.getOrphanTaskAll(taskParamsString).subscribe(response => {
        if (result) {
          for (let index = 0; index < response.items.length; index++) {

            this.eventandTaskList.push(response.items[index]);

          }
        }
      });
    })

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   });
    // }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      let selectedFile = <File>event.target.files[0];
      this.formData.append(selectedFile.name, selectedFile);
      this.formData.append('FileType', selectedFile.type);
      this.formData.append('FileName', 'ProfilePicture');
      this.formData.append('FileToken', this.guid());
      this.selectedFileName = selectedFile.name;

      this.fileService.uploadCatalogImageFile("/Profile/UploadProfilePicture", this.formData).subscribe(res => {
        const input = new UpdateProfilePictureInput();
        input.fileToken = res.result.fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        if(this.isYetim)
        {
          this.service.updateOrphanProfilePicture(input, this.utilsService.selectedOrphanId).subscribe(response => {
            debugger
            if (response.statusText == 'OK') {
              localStorage.removeItem('pcBase64');
              this.getProfilePicture(this.utilsService.selectedOrphanId)
              this.selectedFileName = '';
            }
          })
        }
        else
        {
          this.service.updateProfilePicture(input).subscribe(response => {
            if (response.statusText == 'OK') {
              localStorage.removeItem('pcBase64');
              this.getProfilePicture(this.utilsService.currentUserId)
              this.selectedFileName = '';
            }
          })
        }

        // this.form.controls.binaryFileToken.setValue(res.result.fileToken)
      })
    }
  }

  enrolCourse(courseId: number) {
    this.onlineCourseService.enrolCourseKaptanUser(this.utilsService.currentUserId, courseId).subscribe(result => {
      this.onlineCourseList.splice(this.onlineCourseList.findIndex(p => p.id == courseId), 1);
    })


  }

  guid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}
