<div *ngIf="isPageDone" class="content announcements-page sub-page-content">
  <div class="announcements-content" *ngIf="model && model.length > 0">
    <div class="row row-gap-30">
      <ng-container *ngFor="let item of model">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="item bg-yellow">
            <div class="icon-content">
              <div class="icon-bg">
                <span class="icon-notification"></span>
              </div>
            </div>
            <div class="item-content">
              <div class="title">
                <a [routerLink]="['/duyurular-detay/' + item.announcement.id]">
                  <span>{{ item.announcement.headerText }}</span>
                </a>
              </div>
              <div class="content-footer">
                <div class="date">
                  <span>{{
                    item.announcement.publishDate | date: "dd/MM/yyyy"
                  }}</span>
                </div>
                <div class="detail-link">
                  <a
                    [routerLink]="['/duyurular-detay/' + item.announcement.id]"
                  >
                    <span class="text">Detay</span>
                    <span class="icon-brush-right-arrow icon"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <mat-paginator
      (page)="getServerData()"
      [length]="dataCount"
      [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
  <div *ngIf="!model || model.length <= 0">
    <h6>Görüntülenecek veri bulunamadı</h6>
  </div>
</div>
