<div
  *ngIf="isPageDone"
  class="content job-posting-detail-page sub-page-content"
>
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a [routerLink]="['/is-ilanlari']">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <div class="scroll-content">
    <div class="job-posting-detail-content">
      <div class="row">

      <div *ngIf="!isEdit">
        <div
          class="col-12 job-posting-application d-flex flex-wrap justify-content-end"
          *hasPermission="['Pages.JobApplications.Create']"
        >
          <a *ngIf="!applicationExists" (click)="edit(id)">İlana Başvuru Yap</a>
          <a *ngIf="utilsService.isAdmin" (click)="edit(id)">
            <span class="icon-edit icon"></span>
            <span>Düzenle</span>
          </a>
          <a *ngIf="applicationExists">Başvuru Yaptın</a>
          <a *ngIf="applicationExists" (click)="delete(applicationExists.id)"
            >Başvuruyu Geri Çek</a
          >

          <!-- <button>İlana Başvuru Yap</button> -->
          <!-- Button da kullanılabilir. Css'de bir problem çıkarmaz. -->
        </div>
      </div>

        <div class="job-posting-name col-12">
          <div class="row">
            <div class="col-12 title">
              <span>İlan Adı :</span>
            </div>
            <div class="col-12 name">
              <span>{{ model.workJob.headerText }}</span>
            </div>
          </div>
        </div>
        <div class="job-posting-property col-12">
          <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 reference-range">
              <div class="title">
                <span>Başvuru Aralığı : </span>
              </div>
              <div class="description">
                <span
                  >{{ model.workJob.startDate | date: "dd/MM/yyyy" }} -
                  {{ model.workJob.endDate | date: "dd/MM/yyyy" }}</span
                >
              </div>
            </div>
            <div
              class="col-xl-7 col-lg-7 col-md-6 col-sm-12 mt-md-auto mt-sm-3 location"
            >
              <div class="title">
                <span>Yer : </span>
              </div>
              <div class="description">
                <span>{{ model.workJob.companyLocation }}</span>
              </div>
            </div>
            <div class="mt-3 col-12"></div>
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 reference-range">
              <div class="title">
                <span>Sektör : </span>
              </div>
              <div class="description">
                <span>{{ model.workJob.sectorInfo }}</span>
              </div>
            </div>
            <div
              class="col-xl-7 col-lg-7 col-md-6 col-sm-12 mt-md-auto mt-sm-3 location"
            >
              <div class="title">
                <span>Çalışma Şekli : </span>
              </div>
              <div class="description">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="job-posting-description col-12">
          <div class="title">
            <span>İşin Tanımı ve İstenile Nitelikler:</span>
          </div>
          <div class="description">
            <p>
              {{ model.workJob.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
