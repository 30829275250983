<div class="content my-trainings-content sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div class="new-item" *hasPermission="['Pages.OrphanEducations.Create']">
      <a (click)="edit(null)">
        <span>Yeni Ekle</span>
        <span class="icon-plus-circle-thin"></span>
      </a>
    </div>
  </div>
  <div class="scroll-content">
    <div class="my-trainings-list" *ngIf="model && model.length > 0">
      <ng-container *ngFor="let item of model">
        <div class="my-trainings-item">
          <div class="item-update">
            <a
              (click)="edit(item.orphanEducation.id)"
              *hasPermission="['Pages.OrphanEducations.Edit']"
            >
              <span class="icon-edit icon"></span>
              <span>Düzenle</span>
            </a>
          </div>
          <div class="item-name-group">
            <div class="item-name">
              <span>Eğitim Adı</span>
            </div>
            <div class="item-input">
              <input
                type="text"
                value="{{ item.orphanEducation.educationName }}"
                disabled
              />
            </div>
          </div>
          <div class="item-input-group">
            <div class="item-date">
              <div class="item-name">
                <span>Eğitim Tarihi</span>
              </div>
              <div class="item-input">
                <input
                  type="text"
                  value="{{
                    item.orphanEducation.educationDate | date: 'dd/MM/yyyy'
                  }}"
                  disabled
                />
              </div>
            </div>

            <div class="item-time">
              <div class="item-name">
                <span>Süresi</span>
              </div>
              <div class="item-input">
                <input
                  type="text"
                  value="{{ item.orphanEducation.educationTime }}"
                  disabled
                />
              </div>
            </div>
            <a
            (click)="
            downloadFile(
              item.orphanEducation.binaryFileId,
              item.binaryFileName
            )
            "
              *ngIf="item.orphanEducation.binaryFileId"
              class="item-time"
            >
              <div class="item-name">
                <span>Ek Dosya Var</span>
              </div>
              <div class="item-input">Tıklayınız</div>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="no-content-text" *ngIf="!model || model.length <= 0">
      <h6>Görüntülenecek veri bulunamadı</h6>
    </div>
    <app-footer></app-footer>
  </div>
</div>
