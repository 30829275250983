import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map as _map, filter as _filter } from 'lodash-es';
import { ExternalAuthenticateModel, ExternalAuthenticateResultModel, TokenAuthServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})


export class JwtTokenService {

  externalLoginProviders: ExternalLoginProvider[] = [];

  constructor(private http: HttpClient,
    private _tokenAuthService: TokenAuthServiceProxy,
    private oauthService: OAuthService) {
  }

  getToken() {

    this.initExternalLoginProviders();


    // return this.http.post<any>(environment.apiRoot + '/api/TokenAuth/Authenticate', {
    //   "userNameOrEmailAddress": "admin",
    //   "password": "123qwe"
    // })
    // .subscribe(result => {

    //   localStorage.setItem('access_token', result.result.accessToken);
    // })
  }

  logout(str: any): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + str
    });
    const requestOptions = { headers: headers };
    window.localStorage.removeItem("access_token");
    return this.http.get(environment.apiRoot + '/api/TokenAuth/LogOut?isYetimSite=' + true, requestOptions);
  }

  initt(callback?: any) {
    this.initExternalLoginProviders(callback);
  }

  getExternalAuthenticationProviders(): Observable<any> {
    return this.http.get(environment.apiRoot + "/api/TokenAuth/GetExternalAuthenticationProviders?module=yetim");
  }


  private initExternalLoginProviders(callback?: any) {
    this.getExternalAuthenticationProviders()
      .subscribe((providers: any) => {
        this.externalLoginProviders = _map(providers.result, (p) => new ExternalLoginProvider(p));
        if (callback) {
          callback();
        }
      });
  }

  public keycloakLoginCallback(code: string, sessionState: string) {
    const model = new ExternalAuthenticateModel();
    model.authProvider = ExternalLoginProvider.KEYCLOAK;
    model.providerAccessCode = code;
    model.providerKey = sessionState;
    model.module = "yetim";
    this._tokenAuthService
      .externalAuthenticate(model)
      .pipe()
      .subscribe((result: ExternalAuthenticateResultModel) => {
        if (result.waitingForActivation) {
          return;
        }

        localStorage.setItem('access_token', result.accessToken);
        localStorage.setItem('currentUserName', result.fullName);
        window.location.href = '/';
      });
  }


  externalAuthenticate(provider: ExternalLoginProvider): void {
    this.ensureExternalLoginProviderInitialized(provider, () => {
    });
  }

  ensureExternalLoginProviderInitialized(loginProvider: ExternalLoginProvider, callback: () => void) {
    if (loginProvider.initialized) {
      callback();
      return;
    }

    if (loginProvider.name === ExternalLoginProvider.KEYCLOAK) {
      const authConfig = this.getKeycloakConfig(loginProvider);
      this.oauthService.configure(authConfig);
      this.oauthService.initLoginFlow('keycload=1');
    }
  }

  private getKeycloakConfig(loginProvider: ExternalLoginProvider): AuthConfig {
    let authConfig = new AuthConfig();
    authConfig.loginUrl = loginProvider.additionalParams['Authority'] + '/auth/realms/' + loginProvider.additionalParams['Realm'] + '/protocol/openid-connect/auth';
    authConfig.issuer = loginProvider.additionalParams['Authority'];
    authConfig.skipIssuerCheck = true;
    authConfig.clientId = loginProvider.clientId;
    authConfig.responseType = 'code';
    authConfig.scope = 'openid profile';
    authConfig.disablePKCE = true;
    //authConfig.requireHttps=false;

    //authConfig.redirectUri = AppConsts.publicUrl;
    // todo change redirecturi
    //authConfig.redirectUri = window.location.origin + '/account/login';
    authConfig.redirectUri = window.location.origin;
    return authConfig;
  }

  getUserPermissions(id: number): Observable<any> {
    return this.http.get(environment.apiRoot + "/api/services/app/User/GetUserPermissions?Id=" + id);
  }
}


export class ExternalLoginProviderInfoModel {
  name!: string | undefined;
  clientId!: string | undefined;
  additionalParams!: { [key: string]: string; } | undefined;
}

export class ExternalLoginProvider extends ExternalLoginProviderInfoModel {
  static readonly KEYCLOAK: string = 'Keycloak';

  icon: string;
  initialized = false;

  constructor(providerInfo: ExternalLoginProviderInfoModel) {
    super();

    this.name = providerInfo.name;
    this.clientId = providerInfo.clientId;
    this.additionalParams = providerInfo.additionalParams;
    this.icon = providerInfo.name.toLowerCase();
  }
}
