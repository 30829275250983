<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>

      <div class="modal-title">
        <span *ngIf="!model">Yeni Kitap Ekle</span>
        <span *ngIf="model">Kitabı Düzenle</span>
      </div>
      <div class="default-input-group mb-4">
        <div class="title">
          <span>Türü</span>
        </div>
        <div class="select">
          <select formControlName="bookTypeId">
            <option selected hidden>Seç</option>
            <option value="{{ item.id }}" *ngFor="let item of bookTypeSelect">
              {{ item.displayName }}
            </option>
          </select>
          <span class="text-danger" *ngIf="form.get('bookTypeId').errors && 
          form.get('bookTypeId').hasError('required')">* Bu Alan Zorunludur</span>
          <div class="icon">
            <span class="icon-down-thin"></span>
          </div>
        </div>
      </div>
      <div class="default-input-group mb-4">
        <div class="title">
          <span>Kitabın Adı</span>
        </div>
        <div class="input">
          <input
            type="text"
            formControlName="bookName"
            maxlength="150"
            placeholder="Kitabın Adını Yazınız"
          />
          <span class="text-danger" *ngIf="form.get('bookName').errors && 
          form.get('bookName').hasError('required')">* Bu Alan Zorunludur</span>
        </div>
      </div>
      <div class="default-input-group mb-4">
        <div class="title">
          <span>Yazarı</span>
        </div>
        <div class="input">
          <input
            type="text"
            maxlength="90"
            formControlName="bookAuthor"
            placeholder="Yazarın Adını Yazınız"
          />
          <span class="text-danger" *ngIf="form.get('bookAuthor').errors && 
          form.get('bookAuthor').hasError('required')">* Bu Alan Zorunludur</span>
        </div>
      </div>
      <div class="default-input-group mb-4">
        <div class="row row-gap-xl-1">
          <div class="col-lg-4">
            <div class="title">
              <span>Sayfa Sayısı</span>
            </div>
            <div class="input">
              <input
                type="number"
                formControlName="pageCount"
                placeholder="1234"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">
              <span>Başlangıç</span>
            </div>
            <div class="input">
              <input
                type="date"
                formControlName="startDate"
                placeholder="GG / AA / 20__"
                value="{{
                  this.form.controls.startDate.value | date: 'yyyy-MM-dd'
                }}"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">
              <span>Bitiş</span>
            </div>
            <div class="input">
              <input
                type="date"
                formControlName="endDate"
                placeholder="GG / AA / 20__"
                value="{{
                  this.form.controls.endDate.value | date: 'yyyy-MM-dd'
                }}"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="btn-content">
        <div
          class="delete-btn"
          *hasPermission="['Pages.OrphanBookDetails.Delete']"
        >
          <button
            type="button"
            *ngIf="model"
            (click)="delete(model.id, model.bookName)"
          >
            Kitabı Sil
          </button>
        </div>
        <div class="add-btn" *hasPermission="['Pages.OrphanBookDetails.Edit']">
          <button type="submit">Kaydet</button>
        </div>
      </div>
    </div>
  </form>
</div>
