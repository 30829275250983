import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AppConstants } from 'src/app-constants';
import { DataRequestModel } from 'src/app/models/generic-base/data-model';
import { OrphanTaskModel, TaskDataRequestModel } from 'src/app/models/orphan/task-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { TaskInfoService } from 'src/app/services/orphan/task-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TaskInfoEditComponent } from './task-info-edit/task-info-edit.component';

@Component({
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.css']
})
export class TaskInfoComponent implements OnInit {

  completedModel: OrphanTaskModel[];
  waitingModel: OrphanTaskModel[];
  dataCount: number;
  requestModel = new TaskDataRequestModel();
  isCompleted = 0;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    public service: TaskInfoService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Görevlerim");
  }

  ngOnInit(): void {
    this.reloadList(0);
  }

  reloadList(isCompleted: number): void {
    this.isCompleted = isCompleted;
    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 5;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.isCompletedFilter = this.isCompleted;
    if (this.utilsService.currentUserRole == 'yetim' || this.utilsService.selectedOrphanId > 0) {
      this.requestModel.orphanId = this.utilsService.selectedOrphanId;
    }
    else {
      this.requestModel.targetUserId = this.utilsService.currentUserId;
    }

    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);


    this.service.getOrphanTaskAll(paramsString).subscribe(result => {
      if (isCompleted) {
        this.completedModel = result.items.sort((a, b) => (a.targetCompletedDate < b.targetCompletedDate ? -1 : 1));
      }
      else {
        this.waitingModel = result.items.sort((a, b) => (a.targetCompletedDate > b.targetCompletedDate ? -1 : 1));
      }
      this.dataCount = result.totalCount;
    });
  }

  getServerData() {
    this.requestModel.maxResultCount = this.paginator.pageSize;
    this.requestModel.skipCount = this.paginator.pageSize * this.paginator.pageIndex;
    this.reloadList(this.isCompleted);
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(TaskInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList(0);
      }
    });
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe(result => {
          this.reloadList(0);
        })
      }
    })
  }
}
