import { DataRequestModel } from "../generic-base/data-model";
import { TaskViewModel } from "./task-info";

export class EventModel {
  eventCalendarDetail: EventViewModel;
  orphanEventTypeName: string;
  provinceName: string;
  exists: boolean;
  eventJoinId: number;
}

export class EventViewModel {
  subject: string;
  description: string;
  startDate: Date;
  endDate: Date;
  isOnline: boolean;
  eventLocationOrUrl: string;
  orphanEventTypeId: number;
  provinceId: number;
  id: number;
}

export class EventCalendarJoinModel {
  eventCalendarDetailId: number;
  userId: number;
}

export class EventTypeModel {
  orphanEventType: EventTypeModel
}

export class EventTypeViewModel {
  name: string;
  id: number;
}

export class EventCommentViewModel {
  commentDate: Date;
  commentText: string;
  userId: number;
  orphanTaskId: number;
  id: number;
}

export class EventDataRequestModel extends DataRequestModel {
  subjectFilter: string;
  descriptionFilter: string;
  maxStartDateFilter: string;
  minStartDateFilter: string;
  maxEndDateFilter: string;
  minEndDateFilter: string;
  isOnlineFilter: number;
  eventLocationOrUrlFilter: string;
  orphanEventTypeNameFilter: string;
  geoAreaFilter: string;
  ageLimitFilter: string;
}

export class EventCommentDataRequestModel extends DataRequestModel {
  eventId: number;
}


export class OrphanTaskandEventModel {
  orphanTask: TaskViewModel;
  orphanTaskTypeName: string;
  orphanFirstName: string;
  userName: string;
  creatorName: string;
  eventCalendarDetail: EventViewModel;
  orphanEventTypeName: string;
  provinceName: string;
  exists: boolean;
  eventJoinId: number;
}


