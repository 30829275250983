import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanCommentViewDto, OrphanTaskCommentModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class OrphanCommentService extends GenericCrudService<OrphanCommentViewDto, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanComments")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  getAllCommentsWitOrphanId(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAllCommentsWitOrphanId?" + params);
  }

  getOrphanCommentForEdit(id: number): Observable<OrphanCommentViewDto> {
    return this.http.get<OrphanCommentViewDto>(this.baseUrl + "/GetOrphanCommentForEdit?id=" + id);
  }
}
