import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataResponseModel } from "src/app/models/generic-base/data-model";
import { AyniNakdiYardimModel } from "src/app/models/orphan";
import { environment } from "src/environments/environment";
import { GenericCrudService } from "../generic-base/generic-crud.service";


@Injectable({
  providedIn: 'root'
})
export class AyniNakdiYardimService extends GenericCrudService<AyniNakdiYardimModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/AyniNakdiYardims")
  }

  getAllWithOrphan(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAllWithOrphan?" + params);
  }
}
