export enum MaritalStatus {
  Married = 0,
  Single = 1
}

export enum WorkTypes {
  PartTime,
  FullTime
}

export enum TaskTypes {
  Work = 0,
  Application = 1,
  Meeting = 2,
  Visit = 3
}

export enum TaskTypesLanguage {
  "Çalışma" = 0,
  "İş veya Staj Başvurusu" = 1,
  "İş Görüşmesi" = 2,
  "Firma Ziyareti" = 3
}

export enum MaritalStatusLanguage {
  "Evli" = 0,
  "Bekar" = 1
}

export enum ProjectStatusLanguage {
  "Tamamlandı" = 0,
  "Tamamlanmadı" = 1,
  "Devam Ediyor" = 2,
}

export enum ProjectStatus {
  Completed = 0,
  UnCompleted,
  Continues
}

export enum LanguageLevels {
  İyi = 0,
  Orta,
  Kötü
}
