
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DateTime } from 'luxon';
import { Component, ViewEncapsulation, OnInit, Input, Injector, NgZone } from '@angular/core';
import { UserNotification } from 'src/shared/service-proxies/service-proxies';
import { forEach as _forEach } from 'lodash-es';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/services/shared/notification.service';

@Component({
  templateUrl: './header-notifications.component.html',
  selector: 'header-notifications',
  styleUrls: ['./header-notifications.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderNotificationsComponent implements OnInit {
  notifications: any[] = [];
  unreadNotificationCount = 0;


  @Input() customStyle = 'btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px me-2';
  @Input() iconStyle = 'flaticon-alert-2 unread-notification fs-4';

  constructor(
    injector: Injector,
    private service: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.loadNotifications();
    // this.registerToEvents();
  }

  loadNotifications(): void {
    // if (UrlHelper.isInstallUrl(location.href)) {
    //   return;
    // }

    this.service.getUserNotifications(undefined, undefined, undefined, 3, 0).subscribe((result) => {
      this.unreadNotificationCount = result.unreadCount;
      this.notifications = [];
      _forEach(result.items, (item: UserNotification) => {
        this.notifications.push(item);
      });

    });
  }

  // registerToEvents() {
  //
  //   let self = this;

  //   function onNotificationReceived(userNotification) {
  //     self._userNotificationHelper.show(userNotification);
  //     self.loadNotifications();
  //   }

  //   this.subscribeToEvent('abp.notifications.received', (userNotification) => {
  //     self._zone.run(() => {
  //       onNotificationReceived(userNotification);
  //     });
  //   });

  //   function onNotificationsRefresh() {
  //     self.loadNotifications();
  //   }

  //   this.subscribeToEvent('app.notifications.refresh', () => {
  //     self._zone.run(() => {
  //       onNotificationsRefresh();
  //     });
  //   });

  //   function onNotificationsRead(userNotificationId, success) {
  //     for (let i = 0; i < self.notifications.length; i++) {
  //       if (self.notifications[i].userNotificationId === userNotificationId) {
  //         self.notifications[i].state = 'READ';
  //         self.notifications[i].isUnread = false;
  //       }
  //     }

  //     if (success) {
  //       self.unreadNotificationCount -= 1;
  //     }
  //   }

  //   this.subscribeToEvent('app.notifications.read', (userNotificationId, success) => {
  //     self._zone.run(() => {
  //       onNotificationsRead(userNotificationId, success);
  //     });
  //   });
  // }

  // setAllNotificationsAsRead(): void {
  //   this._userNotificationHelper.setAllAsRead();
  // }

  // openNotificationSettingsModal(): void {
  //   this._userNotificationHelper.openSettingsModal();
  // }

  // setNotificationAsRead(userNotification: IFormattedUserNotification): void {
  //
  //   if (userNotification.state !== 'READ') {
  //
  //     this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
  //   }
  // }

  gotoUrl(url): void {
    if (url) {
      location.href = url;
    }
  }
}



