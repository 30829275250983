import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app-constants';
import { WorkTypes,TaskTypesLanguage } from 'src/app/models/generic-base/custom-enum-model';
import { OrphanViewDto } from 'src/app/models/orphan';
import { OrphanExperienceModel } from 'src/app/models/orphan/experience';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { ProfileService } from 'src/app/services/orphan';
import { ExperienceService } from 'src/app/services/orphan/experience.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { CertificateFileAddComponent } from './certificate-file-add/certificate-file-add.component';
import { ExperienceEditComponent } from './experience-edit/experience-edit.component';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {

  model: OrphanExperienceModel[];
  orphanModel: OrphanViewDto;
  carrierPlanInfo: string;
  dreamJob: string;
  isEditCarrienPlanInfo = false;
  isPageDone = false;
  isDeleted = false;

  constructor(
    public service: ExperienceService,
    public profileService: ProfileService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog,
    private overlay: Overlay,
  ) {
    this.utilsService.setTitle("Kariyer Bilgilerim");
  }

  editCarrierPlanInfo(): void {
    this.isEditCarrienPlanInfo = !this.isEditCarrienPlanInfo;
  }

  onSubmitCarrierPlanInfo(): void {
    let form = this.formBuilder.group({
      id: 0,
      dreamJob: [""],
      carrierPlanInfo: [""]
    })
    form.patchValue(this.orphanModel.orphan);
    // form.controls.dreamJob.setValue(this.dreamJob);
    // form.controls.carrierPlanInfo.setValue(this.carrierPlanInfo);
    this.profileService.updateDreamJobAndCareerPlanInfo(form.value).subscribe(result => {
      localStorage.setItem("dreamJob", this.orphanModel.orphan.dreamJob);
      this.isEditCarrienPlanInfo = false;
      this.utilsService.showMessage("İşlem başarılı")
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
  }

  ngOnInit(): void {
    this.reloadList();

    this.utilsService.currentUserPermissionsForUser.forEach(element => {
      if(element == "Pages_NewsAndAnnouncements_Edit")
      {
        this.isDeleted = true;
      }
    });
  }

  reloadList(): void {
    let queryString = "";
    if (this.utilsService.currentUserRole == "yetim" || this.utilsService.selectedOrphanId > 0) {
      queryString = "orphanId=" + this.utilsService.selectedOrphanId;
      this.profileService.getOrphan(this.utilsService.selectedOrphanId).subscribe(result => {
        this.orphanModel = result;
      })
    }
    else {
      queryString = "userId=" + this.utilsService.currentUserId;
    }

    this.service.getOrphanExperienceAllWithOrphanId(queryString).subscribe(result => {
      this.model = result;
      this.isPageDone = true;
    });
  }

  edit(id: number): void {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(ExperienceEditComponent, {
      data: { id: id },
      width: "650px",
      // height: '100%',
      autoFocus: false,
      scrollStrategy
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

  addCertificateFile(): void {
    const dialogRef = this.dialog.open(CertificateFileAddComponent, {
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.profileService.getOrphan(this.utilsService.selectedOrphanId).subscribe(response => {
          this.orphanModel = response;
        })
      }
    });
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe(result => {
          this.reloadList();
        })
      }
    })
  }

  downloadFile(certificateFileId: string, fileName: string): void {
    this.profileService.downloadBinaryFile(certificateFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  deleteFile(id: number, key: string, index: number) {
    const dialogData: DialogDataModel = {
      title: "Dosya Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.profileService.removeCatalogImageFile(id).subscribe(response => {
          this.orphanModel.certificateFileName = null;
        })
      }
    })
  }

  getTaskTypeName(id: string): string {
    return TaskTypesLanguage[id];
  }
  getWorkTypeName(id: string): string {
    return WorkTypes[id];
  }
}
