<div *ngIf="isPageDone" class="content profile-content">
  <div class="scroll-content">
    <div class="profile-header">
      <div class="profile-photo">
        <div class="photo-content">
          <img src="{{ profilePicture }}" alt="" />
          <div class="edit-overlay" (click)="fileUpload.click()">
            <span class="icon-edit"></span>
            <input
              type="file"
              class="file-input"
              (change)="onFileSelected($event)"
              #fileUpload
            />
          </div>
        </div>
      </div>
      <div class="profile-name">
        <span>{{ model.orphan?.firstName }} {{ model.orphan?.lastName }}</span>
      </div>
      <div class="profile-description">
        <span
          >Yaş {{ age }} / {{profileText}}
          </span>
      </div>
    </div>
    <div class="profile-content">
      <nav class="tab-button">
        <div class="nav" id="nav-tab" role="tablist">
          <a
            *ngIf="utilsService.selectedOrphanId > 0"
            class="active"
            data-toggle="tab"
            href="#profile-guide"
            role="tab"
            aria-selected="true"
            >Mentörlerim</a
          >
          <a
            routerLinkActive="active"
            *ngIf="utilsService.selectedOrphanId > 0"
            routerLink="/ogrenim-bilgilerim"
            >Öğrenim Bilgilerim</a
          >
          <a
            data-toggle="tab"
            href="#profile-hobbies"
            role="tab"
            aria-selected="false"
            >İlgi Alanlarım</a
          >
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          *ngIf="utilsService.selectedOrphanId > 0"
          class="tab-pane fade show active"
          id="profile-guide"
          role="tabpanel"
        >
          <div class="profile-advisor">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="advisor-item">
                  <div class="img">
                    <img src="{{orphanFieldCoordinatorPicture}}" alt="" />
                  </div>
                  <div class="advisor-name">
                    <span>{{ model.orphanFieldCoordinatorName }}</span>
                  </div>
                  <div class="advisor-statu">
                    <span>Yetim Saha Koordinatörü</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="advisor-item">
                  <div class="img">
                    <img src="{{guidanceCounselorPicture}}" alt="" />
                  </div>
                  <div class="advisor-name">
                    <span>{{ model.guidanceCounselorName }}</span>
                  </div>
                  <div class="advisor-statu">
                    <span>Rehber Öğretmen </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="advisor-item">
                  <div class="img">
                    <img src="{{careerPlanningSpecialistPicture}}" alt="" />
                  </div>
                  <div class="advisor-name">
                    <span>{{ model.careerPlanningSpecialistName }}</span>
                  </div>
                  <div class="advisor-statu">
                    <span>Kariyer Planlama Uzmanı </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="ogrenim-bilgilerim"
          class="tab-pane fade"
          id="profile-education"
          role="tabpanel"
        >
        </a>
        <div class="tab-pane fade" id="profile-hobbies" role="tabpanel">
          <div class="hobbies-content">
            <ul>
              <li
                [ngClass]="interestIsEdit ? 'edit-active effect-active' : ''"
                class="hobbies-item"
                *ngFor="let item of interestList"
              >
                <span *hasPermission="['Pages.OrphanInterestDetails.Delete']">
                  <span
                    *ngIf="interestIsEdit"
                    (click)="
                      deleteInterestItem(
                        item.orphanInterestDetail.id,
                        item.orphanInterestDetail.name
                      )
                    "
                    class="icon-modal-close icon"
                  ></span>
                </span>
                <span>{{ item.orphanInterestDetail.name }}</span>
              </li>
              <ng-container *ngFor="let textBox of interestElements">
                <li class="hobbies-item">
                  <input type="text" [(ngModel)]="interestInput" />
                </li>
              </ng-container>

              <li
                class="hobbies-edit"
                *hasPermission="['Pages.OrphanInterestDetails.Edit']"
              >
                <a *ngIf="!interestIsEdit" (click)="interestEditChangeStatus()">
                  <span class="icon-edit"></span>
                  <span>Düzenle</span>
                </a>
              </li>

              <li
                class="hobbies-add"
                *hasPermission="['Pages.OrphanInterestDetails.Edit']"
              >
                <a
                  (click)="createInterestElement()"
                  *ngIf="interestIsEdit && !interestInputShow"
                >
                  <span class="icon-plus-circle-thin"></span>
                  <span>Ekle</span>
                </a>
              </li>
            </ul>
            <div
              class="hobbies-button"
              *hasPermission="['Pages.OrphanInterestDetails.Edit']"
            >
              <button
                *ngIf="interestIsEdit"
                (click)="interestEditChangeStatus()"
                class="cancel"
              >
                Vazgeç
              </button>
              <button (click)="onSubmit()" *ngIf="interestIsEdit" class="add">
                Kaydet
              </button>
            </div>
          </div>
        </div>
        <div class="tasks-slick-container">
          <div class="module-header">
            <div class="module-title">
              <div class="icon">
                <span class="icon-star"></span>
              </div>
              <div class="module-name">
                <span>Görevlerim</span>
              </div>
            </div>
            <div class="module-link">
              <a [routerLink]="['/gorevlerim']">
                <span class="text">Tümü</span>
                <span class="icon-brush-right-arrow icon"></span>
              </a>
            </div>
          </div>
          <div
            class="slick-container"
            *ngIf="taskInfoList && taskInfoList.length > 0"
          >
            <div class="tasks-slick">
              <!-- <div #slickModal [config]="slideConfig"> -->
              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig"
              >
                <div
                  ngxSlickItem
                  *ngFor="let item of taskInfoList"
                  class="item slide"
                >
                  <div class="task-title">
                    <span>{{ item.orphanTaskTypeName }}</span>
                  </div>
                  <div class="task-description">
                    <span class="description-title"></span>
                    <span class="description-content"
                      ><b> {{ item.orphanTask.subject }}</b></span
                    >
                  </div>
                  <div class="task-date">
                    <span class="date-title">Hedeflenen Tarih : </span>
                    <span class="date-content"
                      >{{
                        item.orphanTask.targetCompletedDate | date: "dd/MM/yyyy"
                      }}
                    </span>
                  </div>
                  <div class="task-constituent">
                    <div class="constituent-title">
                      <span>Atayan :</span>
                    </div>
                    <div class="constituent-person">
                      <div class="img">
                        <img src="../assets/images/userDefault.png" alt="" />
                      </div>
                      <div class="constituent-name">
                        <span>{{ item.creatorName }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="task-bottom">
                    <!-- <div class="task-comments">
                    <span class="icon icon-comments"></span>
                    <span class="text">12 YORUM</span>
                  </div> -->
                    <div class="task-detail">
                      <a
                        [routerLink]="[
                          '/gorevlerim-detay/' + item.orphanTask.id
                        ]"
                      >
                        <span class="text">Detay</span>
                        <span class="icon icon-brush-right-arrow"></span>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- </div><<<< -->
              </ngx-slick-carousel>
            </div>
          </div>
          <div *ngIf="!taskInfoList || taskInfoList.length <= 0">
            <h6>Görüntülenecek veri bulunamadı</h6>
          </div>
        </div>

        <div class="calendar">
          <div class="module-header">
            <div class="module-title">
              <div class="icon">
                <span class="icon-calender"></span>
              </div>
              <div class="module-name">
                <span>Takvimim</span>
              </div>
            </div>
            <div class="module-link">
              <a href="etkinlik-takvimi">
                <span class="text">Tümü</span>
                <span class="icon-brush-right-arrow icon"></span>
              </a>
            </div>
          </div>
          <div class="activity-calendar-content module-content d-flex px-0">
            <div class="activity-calendar col-lg-5">
              <full-calendar
                id="calendar"
                *ngIf="calendarVisible"
                locale="tr"
                [options]="calendarOptions"
              ></full-calendar>
            </div>
            <div class="col-lg-7">
              <div class="event-detail">
                <div class="title">
                  <span
                    >{{ selectedDate | date: "d MMMM EEEE" }}
                    <span *ngIf="eventandTaskList.length > 0"
                      >{{ eventandTaskList.length }} Etkinlik Var</span
                    >
                  </span>
                </div>
                <div class="event-content">
                  <div *ngIf="eventandTaskList.length <= 0" class="event-item">
                    <div class="event-title">
                      <span>Bu tarihte etkinlik bulunmuyor</span>
                    </div>
                  </div>
                  <ng-container *ngFor="let item of eventandTaskList">
                    <div class="event-item">
                      <div class="event-title">
                        <span *ngIf="item.eventCalendarDetail">{{
                          item.eventCalendarDetail?.subject
                        }}</span>
                        <span *ngIf="item.orphanTask">{{
                          item.orphanTask?.subject
                        }}</span>
                      </div>
                      <div class="event-description">
                        <span *ngIf="item.eventCalendarDetail"
                          >{{ item.eventCalendarDetail?.description }}
                        </span>
                        <span *ngIf="item.orphanTask"
                          >{{ item.orphanTask?.description }}
                        </span>
                      </div>
                      <div class="event-info">
                        <span class="info-title">Saat : </span>
                        <span
                          >{{
                            item.eventCalendarDetail?.startDate | date: "dd.MM.yyyy HH:mm"
                          }}
                          /
                          {{
                            item.eventCalendarDetail?.endDate | date: "dd.MM.yyyy HH:mm"
                          }}</span
                        >
                      </div>
                      <div class="event-info">
                        <span class="info-title">Yer : </span>
                        <span>{{
                          item?.provinceName
                        }}</span>
                      </div>
                      <div
                        class="event-info"
                        *ngIf="item.eventCalendarDetail?.isOnline"
                      >
                        <span class="info-title">Link : </span>
                        <span><a href="#">www</a></span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="education-slick-container"
          *ngIf="
            utilsService.selectedOrphanId > 0
          "
        >
          <div class="module-header">
            <div class="module-title">
              <div class="icon">
                <span class="icon-meeting"></span>
              </div>
              <div class="module-name">
                <span>Online Eğitimler</span>
              </div>
            </div>
            <div class="module-link">
              <a routerLink="/kurslar">
                <span class="text">Tümü</span>
                <span class="icon-brush-right-arrow icon"></span>
              </a>
            </div>
          </div>
          <div class="slick-container">
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-danger"  *ngIf="
              onlineCourseList &&
              onlineCourseList.length <= 0
            "></div>
            </div>

            <div class="online-education-slick"
            *ngIf="
            utilsService.selectedOrphanId > 0 &&
            onlineCourseList &&
            onlineCourseList.length > 0
          ">

              <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig"
              >
                <div
                  ngxSlickItem
                  class="item slide"
                  *ngFor="let item of onlineCourseList"
                >
                  <figure class="m-0">
                    <img
                      src="../../../../assets//images/egitim-gorsel.png"
                      class="img-fluid"
                    />
                  </figure>
                  <div class="p-3">
                    <div class="box-content text-center">
                      <span class="box-title d-block">Eğitim Adı: </span>
                      <p>
                        {{ item.fullname }}
                      </p>
                    </div>
                    <div class="box-content d-flex align-items-center flex-row">
                      <div class="text-center">
                        <span class="d-block">Başlangıç Tarihi:</span>
                        <p class="d-block">
                          {{ item.startdate * 1000 | date: "dd/MM/yyyy" }}
                        </p>
                      </div>
                      <div class="text-center">
                        <span class="d-block">Bitiş Tarihi:</span>
                        <p class="d-block">
                          {{ item.enddate * 1000 | date: "dd/MM/yyyy" }}
                        </p>
                      </div>
                    </div>
                    <div class="box-bottom-content">
                      <a (click)="enrolCourse(item.id)"
                        ><span class="d-block icon-meeting icon"></span>Eğitime
                        Katıl</a
                      >
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
        <div
          *ngIf="utilsService.selectedOrphanId > 0"
          class="projects-slick-container"
        >
          <div class="module-header">
            <div class="module-title">
              <div class="icon">
                <span class="icon-star"></span>
              </div>
              <div class="module-name">
                <span>Projelerim</span>
              </div>
            </div>
            <div class="module-link">
              <a [routerLink]="['/projelerim']">
                <span class="text">Tümü</span>
                <span class="icon-brush-right-arrow icon"></span>
              </a>
            </div>
          </div>
          <div
            class="slick-container"
            *ngIf="projectInfoList && projectInfoList.length > 0"
          >
            <div class="projects-slick">
              <ngx-slick-carousel
                class="carousel"
                #slickModal2="slick-carousel"
                [config]="slideConfig"
              >
                <div
                  ngxSlickItem
                  *ngFor="let item of projectInfoList"
                  class="item slide"
                >
                  <div class="projects-date">
                    <span>{{ item.orphanProjectDetail.year }}</span>
                  </div>
                  <div class="projects-bottom">
                    <div class="projects-title">
                      <span>{{ item.orphanProjectDetail.name }}</span>
                    </div>
                    <div class="projects-description">
                      <span></span
                      >
                    </div>
                    <div class="projects-footer">
                      <!-- <div class="projects-comments">
                      <span class="icon icon-comments"></span>
                       <span class="text">12 YORUM</span>
                    </div> -->
                      <div class="projects-detail">
                        <a
                          [routerLink]="[
                            '/projelerim-detay/' + item.orphanProjectDetail.id
                          ]"
                        >
                          <span class="text">Detay</span>
                          <span class="icon icon-brush-right-arrow"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
          <div *ngIf="!projectInfoList || projectInfoList.length <= 0">
            <h6>Görüntülenecek veri bulunamadı</h6>
          </div>
        </div>

        <div
          *ngIf="utilsService.selectedOrphanId > 0"
          class="job-slick-container"
        >
          <div class="module-header">
            <div class="module-title">
              <div class="icon">
                <span class="icon-statistics"></span>
              </div>
              <div class="module-name">
                <span>İş İlanları</span>
              </div>
            </div>
            <div class="module-link">
              <a [routerLink]="['/is-ilanlari']">
                <span class="text">Tümü</span>
                <span class="icon-brush-right-arrow icon"></span>
              </a>
            </div>
          </div>
          <nav class="tab-button">
            <div class="nav" id="nav-tab" role="tablist">
              <a
                class="active"
                data-toggle="tab"
                href="#current-listings"
                role="tab"
                aria-selected="false"
                >Güncel İlanlar</a
              >
              <a
                data-toggle="tab"
                href="#my-applications"
                role="tab"
                aria-selected="false"
                >Başvurularım</a
              >
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="current-listings"
              role="tabpanel"
            >
              <div
                class="slick-container"
                *ngIf="workJobList && workJobList.length > 0"
              >
                <div class="job-posting-slick">
                  <ngx-slick-carousel
                    class="carousel"
                    #slickModal3="slick-carousel"
                    [config]="slideConfig"
                  >
                    <div *ngFor="let item of workJobList">
                      <div ngxSlickItem class="item slide">
                        <div class="item-title">
                          <span>{{ item?.workJob?.headerText }}</span>
                        </div>
                        <div class="item-description">
                          <span>{{ item.workJob.description }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold">Yer : </span>
                          <span>{{ item.workJob.location }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold">Sektör : </span>
                          <span>{{ item.workJob.sectorInfo }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold date-title"
                            >Başvuru Tarihleri :
                          </span>
                          <span class="date"
                            >{{ item.workJob.startDate | date: "dd/MM/yyyy" }} -
                            {{ item.workJob.endDate | date: "dd/MM/yyyy" }}
                            Arasında</span
                          >
                        </div>
                        <div class="item-detail">
                          <a
                            [routerLink]="[
                              '/is-ilanlari-detay/' + item.workJob.id
                            ]"
                          >
                            <span class="text">İlanı İncele</span>
                            <span class="icon-brush-right-arrow"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>
              </div>
              <div *ngIf="!workJobList || workJobList.length <= 0">
                <h6>Görüntülenecek veri bulunamadı</h6>
              </div>
            </div>
            <div class="tab-pane fade" id="my-applications" role="tabpanel">
              <div
                class="slick-container"
                *ngIf="
                  orphanJobApplicationList &&
                  orphanJobApplicationList.length > 0
                "
              >
                <div class="job-posting-slick">
                  <ngx-slick-carousel
                    class="carousel"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                  >
                    <div *ngFor="let item of orphanJobApplicationList">
                      <div ngxSlickItem class="item slide">
                        <div class="item-title">
                          <span>{{ item?.workJob?.headerText }}</span>
                        </div>
                        <div class="item-description">
                          <span>{{ item?.workJob?.description }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold">Yer : </span>
                          <span>{{ item?.workJob?.companyLocation }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold">Sektör : </span>
                          <span>{{ item?.workJob?.sectorInfo }}</span>
                        </div>
                        <div class="item-list">
                          <span class="bold date-title"
                            >Başvuru Tarihleri :
                          </span>
                          <span class="date"
                            >{{ item?.workJob?.startDate | date: "dd/MM/yyyy" }}
                            {{ item?.workJob?.endDate | date: "dd/MM/yyyy" }}
                            Arasında</span
                          >
                        </div>
                        <div class="item-detail">
                          <a href="#">
                            <span class="text">İlanı İncele</span>
                            <span class="icon-brush-right-arrow"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>
              </div>
              <div
                *ngIf="
                  !orphanJobApplicationList ||
                  orphanJobApplicationList.length <= 0
                "
              >
                <h6>Görüntülenecek veri bulunamadı</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="utilsService.selectedOrphanId > 0" class="col-12 px-0 mt-5">
        <div class="profile-comment">
          <div class="comment-title">
            <span>Yorumlar</span>
          </div>
          <form
            *ngIf="
              orphanCommentForm &&
              utilsService.currentUserRole != 'yetim' &&
              this.utilsService.selectedOrphanId > 0
            "
            [formGroup]="orphanCommentForm"
            (ngSubmit)="onSubmitComment()"
          >
            <div class="item-full-group">
              <div class="item-textarea-name">
                <span>Yorum</span>
              </div>
              <div class="textarea">
                <textarea formControlName="commentText"></textarea>
              </div>
              <div class="default-input-group mb-4">
                <div class="title">
                  <input type="checkbox" formControlName="orphanIsView" />
                  &nbsp; &nbsp;
                  <span>Yorumumu yetim görüntülesin mi?</span>
                </div>
              </div>
            </div>
            <div class="new-item">
              <a mat-raised-button style="background-color: #ec3a32">
                <span style="color: #ffffff">Gönder</span>
              </a>
            </div>
          </form>
          <div class="comment-list" *ngIf="orphanCommentList.length > 0">
            <div class="comment" *ngFor="let item of orphanCommentList">
              <ng-container
                *ngIf="
                  utilsService.selectedOrphanId > 0 &&
                  utilsService.currentUserRole == 'yetim' &&
                  item.orphanComment.orphanIsView
                "
              >
                <div class="comment-content pl-0 col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">{{ item.userName }}</span>
                    <span class="date">{{
                      item.orphanComment.commentDate | date: "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="content-description">
                    <p>
                      {{ item.orphanComment.commentText }}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="utilsService.currentUserRole != 'yetim'">
                <div class="comment-content pl-0 col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">{{ item.userName }}</span>
                    <span class="date">{{
                      item.orphanComment.commentDate | date: "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="content-description">
                    <p>
                      {{ item.orphanComment.commentText }}
                    </p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
