import { DateTime } from "luxon";
import { DataRequestModel } from "../generic-base/data-model";

export class ProfileModel {
  name: string;
  surname: string;
  age: string;
  profilePicture: string;
  phone: string;
}
export class OrphanViewDto {
  orphan: OrphanDto;
  provinceName: string;
  districtName: string;
  neighborhoodName: string;
  certificateFileName: string;
  guidanceCounselorName: string;
  careerPlanningSpecialistName: string;
  orphanFieldCoordinatorName: string;
  certificateDateOfIssue: DateTime;
}

export class OrphanDreamJobAndCarrierPlanInfoDto {
  id: number;
  carrierPlanInfo: string;
  dreamJob: string;
}

export class OrphanDto {
  nationality: string;
  mdgMuhatapNo: string;
  tcNumber: string;
  firstName: string;
  lastName: string;
  placeOfBirth: string;
  dateOfBirth: DateTime;
  motherAndFatherName: string;
  address: string;
  apartmentNumber: string;
  postCode: number;
  mobileNumber: string;
  homeNumber: string;
  eMail: string;
  maritalStatus: number;
  provinceId: number;
  districtId: number;
  neighborhoodId: number;
  dreamJob: string;
  carrierInfo: string;
  id: number;
  careerPlanningSpecialistId: number;
  orphanFieldCoordinatorId: number;
  guidanceCounselorId: number;
}

export class OrphanCommentViewDto {
  commentDate: Date;
  commentText: string;
  orphanIsView: boolean;
  userId: number;
  orphanId: number;
}

export class OrphanCommentDataRequestModel extends DataRequestModel {
  orphanId: number;
}

export enum MaritalStatusName {
  Evli = 0,
  Bekar = 1,
}



