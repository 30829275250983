export class UserNotificationPermissionModel {
  userNotificationPermission: UserNotificationPermissionViewModel;
  userName: string;
}

export class UserNotificationPermissionViewModel {
  eventEMail: boolean;
  announcementEMail: boolean;
  taskEMail: boolean;
  jobEMail: boolean;
  newEMail: boolean;
  userId: number;
  id: number;
}
