import { DataRequestModel } from "../generic-base/data-model";

export class ReadBookModel {
  orphanBookDetail: ReadBookViewModel;
  bookTypeName: string;
  orphanFirstName: string;
}

export class ReadBookViewModel {
  bookName: string;
  bookAuthor: string;
  pageCount: number;
  startDate: Date;
  endDate: Date;
  bookTypeId: number;
  orphanId: number;
  id: number;
}

export class ReadBookDataRequestModel extends DataRequestModel {
  orphanId: number;
}
