<div *ngIf="isPageDone" class="content events-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a [routerLink]="['/etkinlik-takvimi']">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <div class="scroll-content">
    <div class="events-content">
      <div class="events-category">
        <nav>
          <ul>
            <li>
              <a
                (click)="listClick($event, '')"
                [ngClass]="{ active: !selectedItem }"
                >Tümü</a
              >
            </li>
            <li *ngFor="let item of eventTypeListModel">
              <a
                [ngClass]="{
                  active: selectedItem == item.orphanEventType.name
                }"
                (click)="listClick($event, item.orphanEventType.name)"
                >{{ item.orphanEventType.name }}</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div
        class="events-list"
        id="accordion"
        *ngIf="eventListModel && eventListModel.length > 0"
      >
        <div class="item" *ngFor="let item of eventListModel; let i = index">
          <div class="item-header">
            <div class="event-date">
              <span class="date-day">{{
                item.eventCalendarDetail.startDate | date: "dd"
              }}</span>
              <span class="date-month">{{
                item.eventCalendarDetail.startDate | date: "MM"
              }}</span>
              <span class="date-year">{{
                item.eventCalendarDetail.startDate | date: "yyyy"
              }}</span>
            </div>
            <div class="event-detail">
              <div class="event-name">
                <span>{{ item.eventCalendarDetail.subject }}</span>
              </div>
              <div class="event-desc">
                <div class="desc-time">
                  <span class="desc-title">Saat: </span>
                  <span class="desc-content"
                    >{{ item.eventCalendarDetail.startDate | date: "dd.MM.yyyy HH:mm" }} /
                    {{ item.eventCalendarDetail.endDate | date: "dd.MM.yyyy HH:mm" }}</span
                  >
                </div>
                <div class="desc-location">
                  <span class="desc-title">Yer: </span>
                  <span class="desc-content">{{ item.provinceName }}</span>
                </div>
              </div>
              <div class="event-added">
                <a
                  *ngIf="!item.exists"
                  (click)="joinEventCalendar(item.eventCalendarDetail.id, i)"
                >
                  <span class="icon-plus-circle-thin"></span>
                  <span>Katılımcı Ol</span>
                </a>
                <a
                  *ngIf="item.exists"
                  (click)="
                    delete(
                      i,
                      item.eventCalendarDetail.subject,
                      item.eventCalendarDetail.id
                    )
                  "
                >
                  <span class="icon-plus-circle-thin"></span>
                  <span>Katıldınız</span>
                </a>
              </div>
            </div>
            <div class="accordion-btn">
              <a
                [routerLink]="[
                  '/etkinlik-takvimi-detay/' + item.eventCalendarDetail.id
                ]"
              >
                <span class="text">Detay</span>
                <span class="icon-right-thin icon"></span>
              </a>
            </div>
          </div>
          <div class="item-body">
            <div class="collapse" id="accordionItem1" data-parent="#accordion">
              <div class="accordion-content">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="item-header">
            <div class="event-date">
              <span class="date-day">22</span>
              <span class="date-month">HAZİRAN</span>
              <span class="date-year">2022</span>
            </div>
            <div class="event-detail">
              <div class="event-name">
                <span>Loroipsum Dolor Sit Amed Dolor Sit Amed</span>
              </div>
              <div class="event-desc">
                <div class="desc-time">
                  <span class="desc-title">Saat :</span>
                  <span class="desc-content">15:30 - 17:30</span>
                </div>
                <div class="desc-location">
                  <span class="desc-title">Yer :</span>
                  <span class="desc-content">İstanbul</span>
                </div>
              </div>
              <div class="event-added">
                <a href="#">
                  <span class="icon-plus-circle-thin"></span>
                  <span>Katılımcı Ol</span>
                </a>
              </div>
            </div>
            <div class="accordion-btn">
              <a
                data-toggle="collapse"
                class="collapsed"
                href="#accordionItem2"
                role="button"
                aria-expanded="false"
                data-target="#accordionItem2"
                aria-controls="accordionItem2"
              >
                <span class="text">Detay</span>
                <span class="icon-right-thin icon"></span>
              </a>
            </div>
          </div>
          <div class="item-body">
            <div class="collapse" id="accordionItem2" data-parent="#accordion">
              <div class="accordion-content">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni dolores eos qui ratione voluptatem sequi
                nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                sit amet, consectetur
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div *ngIf="!eventListModel || eventListModel.length <= 0">
        <h6>Görüntülenecek veri bulunamadı</h6>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
