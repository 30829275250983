import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { UserModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericCrudService<UserModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/User")
  }

  getUserForEdit(id: number): Observable<UserModel> {
    return this.http.get<UserModel>(this.baseUrl + "/GetUserForEdit?id=" + id);
  }

  createOrEdit(body: UserModel): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/CreateOrUpdateUser", body);
  }

  getUsersLookupTable(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetUsersLookupTable");
  }
}
