import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetOrphanFileForViewDto, OrphanFileModel } from 'src/app/models/orphan/file';
import { OperationResultModel } from 'src/app/models/shared/operation-result';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class FileService extends GenericCrudService<OrphanFileModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanFiles")
  }

  getFilesWithReferenceNumber(referenceNumber: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/GetFilesWithReferenceNumber?referenceNumber=" + referenceNumber);
  }

  getFilesWithId(id: number): Observable<GetOrphanFileForViewDto> {
    return this.http.get<GetOrphanFileForViewDto>(this.baseUrl + "/GetFilesWithId?fileId=" + id);
  }

  deleteFileWithReferenceNumber(referenceNumber: string): Observable<OperationResultModel> {
    return this.http.delete<OperationResultModel>(this.baseUrl + "/DeleteileWithReferenceNumber?referenceNumber=" + referenceNumber);
  }

  deleteFileWithId(id: number): Observable<OperationResultModel> {
    return this.http.delete<OperationResultModel>(this.baseUrl + "/DeleteFileWithId?fileId=" + id);
  }

  uploadCatalogImageFile(url: string, formData: FormData): Observable<any> {
    return this.http.post<any>(environment.apiRoot + url, formData)
  }
}
