import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { Flight, ListOrphanModel } from 'src/app/models/manager/list-orphan';
import { ListOrphanService } from 'src/app/services/manager/list-orphan.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EditViewComponent } from '../../generic-base/generic-base.component';
import { TaskInfoEditComponent } from '..';

@Component({
  selector: 'app-list-orphan',
  templateUrl: './list-orphan.component.html',
  styleUrls: ['./list-orphan.component.css']
})
export class ListOrphanComponent
  implements OnInit {

  isPageDone = false;

  displayedColumns: string[] = ['select', 'setTask', 'firstName', 'lastName', 'dateOfBirth'];
  dataSource: any;
  constructor(
    public service: ListOrphanService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Yetim Listesi");
  }

  ngOnInit(): void {
    this.service.getOrphans().subscribe(result => {
      this.dataSource = result.items;
      this.isPageDone = true;
    })
  }

  selectOrphan(id: number): void {

    this.utilsService.setObjectLocalStorage("orphanId", id);
    this.utilsService.selectedOrphanId = id;
    this.router.navigate(["profil"]);

  }

  setTaskForOrphan(orphanId: number): void {
    const dialogRef = this.dialog.open(TaskInfoEditComponent, {
      data: { id: null, orphanId: orphanId },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        // this.reloadList(0);
      }
    });
  }
}
