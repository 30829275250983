import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  activerequest = 0;

  constructor(
    private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.activerequest === 0) {
      this.spinner.show()
    }
    if(!request.url.includes('GetCourses')){
      this.activerequest++;
    }

    return next.handle(request).pipe(finalize(() => {
      if(!request.url.includes('GetCourses')){
        this.activerequest--;
      }

      if (this.activerequest == 0) {
        this.spinner.hide();
      }
    })
    );
  }
}
