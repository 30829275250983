<div *ngIf="isPageDone" class="content task-detail-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="/etkinlik-takvimi-liste">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <div class="scroll-content">
    <div class="task-detail-content">
      <div class="row">
        <div class="col-12">
          <div class="task-detail">
            <div class="task-detail-title">
              <span>Etkinlik Adı :</span>
            </div>
            <div class="task-name">
              <span>{{ model.eventCalendarDetail.subject }}</span>
            </div>
            <div class="task-detail-title description-title">
              <span>Etkinlik Açıklaması :</span>
            </div>
            <div class="task-description">
              <span>{{ model.eventCalendarDetail.description }}</span>
            </div>
            <div class="task-detail-title date-title">
              <span>Tarih :</span>
            </div>
            <div class="task-date">
              <span
                >{{ model.eventCalendarDetail.startDate | date: "dd.MM.yyyy HH:mm" }}
                /
                {{
                  model.eventCalendarDetail.endDate | date: "dd.MM.yyyy HH:mm"
                }}</span
              >
            </div>
          </div>
        </div>

        <div class="col-12 mt-5">
          <div class="task-comment">
            <form [formGroup]="form" (ngSubmit)="onSubmitComment()">
              <div class="item-full-group">
                <div class="item-textarea-name">
                  <span>Yorum</span>
                </div>
                <div class="textarea">
                  <textarea formControlName="commentText"></textarea>
                </div>
              </div>
              <div class="new-item">
                <button type="submit" mat-raised-button style="background-color: #ec3a32">
                  <span style="color: #ffffff">Gönder</span>
                </button>
              </div>
            </form>
            <div class="comment-title">
              <span>Yorumlar ({{ commentListModel.length }})</span>
            </div>
            <div class="comment-list">
              <div class="comment" *ngFor="let item of commentListModel">
                <div class="profile-photo">
                  <img src="../assets/images/userDefault.png" alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name"> {{ item.userName }} </span>
                    <span class="date">{{
                      item.eventCalendarComment.commentDate | date: "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="content-description">
                    <p>
                      {{ item.eventCalendarComment.commentText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
