<div class="content job-posting-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <section class="page-tab">
      <nav class="tab-button">
        <div class="nav" id="nav-tab" role="tablist">
          <a
            class="active"
            data-toggle="tab"
            href="#current-job-postings"
            role="tab"
            aria-selected="true"
            >Güncel İlanlar</a
          >
          <a
            data-toggle="tab"
            href="#my-applications"
            role="tab"
            aria-selected="false"
            >İş Başvurularım</a
          >
        </div>
      </nav>
    </section>
  </div>
  <div class="scroll-content">
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="current-job-postings"
        role="tabpanel"
      >
        <div class="current-job-postings" *ngIf="model && model.length > 0">
          <div class="row gap-30">
            <div
              *ngFor="let item of model"
              class="col-6-1440 col-xl-4 col-lg-6"
            >
              <div class="item">
                <div class="body">
                  <div class="body-header">
                    <div class="icon">
                      <span class="icon-business"></span>
                    </div>
                    <div class="text-content">
                      <div class="title two-line-limiter">
                        <span>{{ item.workJob.headerText }}</span>
                      </div>
                      <div class="description two-line-limiter">
                        <span
                          >{{ item.workJob.description }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="body-footer">
                    <div class="features">
                      <span class="sub-title">Yer : </span>
                      <span>{{ item.workJob.companyLocation }}</span>
                    </div>
                    <div class="features">
                      <span class="sub-title">Sektör : </span>
                      <span>{{ item.workJob.sectorInfo }}</span>
                    </div>
                    <div class="features date-features">
                      <span class="sub-title">Başvuru Tarihleri : </span>
                      <span class="date"
                        >{{ item.workJob.startDate | date: "dd/MM/yyyy" }} -
                        {{ item.workJob.endDate | date: "dd/MM/yyyy" }}
                        Arasında</span
                      >
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <div class="detail-link">
                    <a [routerLink]="['/is-ilanlari-detay/' + item.workJob.id]">
                      <span class="text">İlanı İncele</span>
                      <span class="icon-brush-right-arrow icon"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="no-content-text" *ngIf="!model || model.length <= 0">
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>
      </div>

      <div class="tab-pane fade" id="my-applications" role="tabpanel">
        <div
          class="my-applications"
          *ngIf="
            orphanJobApplicationModel && orphanJobApplicationModel.length > 0
          "
        >
          <div class="row gap-30">
            <div
              *ngFor="let item of orphanJobApplicationModel"
              class="col-6-1440 col-xl-4 col-lg-6"
            >
              <div class="item">
                <div class="body">
                  <div class="body-header">
                    <div class="icon">
                      <span class="icon-business"></span>
                    </div>
                    <div class="text-content">
                      <div class="title two-line-limiter">
                        <span>{{ item.workJob.headerText }}</span>
                      </div>
                      <div class="description two-line-limiter">
                        <span>{{ item.workJob.description }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="body-footer">
                    <div class="features">
                      <span class="sub-title">Yer : </span>
                      <span>{{ item.workJob.companyLocation }}</span>
                    </div>
                    <div class="features">
                      <span class="sub-title">Sektör : </span>
                      <span>{{ item.workJob.sectorInfo }}</span>
                    </div>
                    <div class="features date-features">
                      <span class="sub-title">Başvuru Tarihleri : </span>
                      <span class="date"
                        >{{ item.workJob.startDate | date: "dd/MM/yyyy" }}
                        {{ item.workJob.endDate | date: "dd/MM/yyyy" }}
                        arasında</span
                      >
                    </div>
                  </div>
                </div>
                <div class="footer">
                  <div class="you-applied">
                    <span>Başvuru Yaptın</span>
                  </div>
                  <div class="detail-link">
                    <a
                      [routerLink]="[
                        '/is-ilanlari-detay/' + item.jobApplication.workJobId
                      ]"
                    >
                      <span class="text">İlanı İncele</span>
                      <span class="icon-brush-right-arrow icon"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="no-content-text"
          *ngIf="
            !orphanJobApplicationModel || orphanJobApplicationModel.length <= 0
          "
        >
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
