export class OrphanExperienceModel {
  orphanExperience: ExperienceViewModel;
  orphanFirstName: string;
  provinceName: string;
}
export class ExperienceViewModel {
  companyName: string;
  companySector: string;
  description: string;
  companyPosition: string;
  startDate: Date;
  endDate: Date;
  orphanId: number;
  companyProvinceId: number;
  taskType: number;
  oprhanWorkType: number;
  id: number;
}


