import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataRequestModel, DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanTaskModel, TaskViewModel } from 'src/app/models/orphan/task-info';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class TaskInfoService extends GenericCrudService<TaskViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanTasks")
  }

  getOrphanTaskAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetOrphanTaskAll?" + params.toString());
  }

  getOrphanTaskForEdit(id: number): Observable<OrphanTaskModel> {
    return this.http.get<OrphanTaskModel>(this.baseUrl + "/GetOrphanTaskForEdit?id=" + id);
  }

  getAllOrphanTaskTypeForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllOrphanTaskTypeForTableDropdown");
  }
}
