<html>
  <head>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <meta name="format-detection" content="telephone=no" />
    <title>Kızılay Kılavuz</title>
    <meta name="description" content="Kızılay Kılavuz" />
    <meta name="keywords" content="Kızılay Kılavuz" />
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="./favicon/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="./favicon/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="./favicon/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="./favicon/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="./favicon/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="./favicon/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="./favicon/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="./favicon/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="./favicon/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="./favicon/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="./favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="./favicon/favicon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="./favicon/favicon-16x16.png"
    />
    <link rel="manifest" href="./favicon/manifest.json" />
    <link rel="icon" href="./favicon/favicon.ico" />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
    <meta name="theme-color" content="#ffffff" />
  </head>

  <body class="career-form">
    <div class="col-12">
      <div class="profile">
        <a
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="profile-name">
            <span class="name">{{ utilsService.getUserName() }}</span>
            <span class="icon-down-circle-thin"></span>
          </div>
          <div class="profile-photo">
            <img src="{{ utilsService.profilePicture }}" alt="" />
          </div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" (click)="logOut()">Çıkış Yap</a>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-xl-6 mx-auto">
          <div class="form">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-lg-12">
                  <div class="logo">
                    <img
                      src="../../../../assets/images/logo-white.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <div class="form-desc">
                    <span>
                      Henüz aktif bir rolünüz bulunmamaktadır. Yetkili
                      kullanıcılarla ileitşim geçmek
                      için kizilay@kizilay.com.tr adresine mail gönderebilir
                      veya Kariyer Planlama Uzmanı olmak isterseniz aşağıdaki
                      formu doldurabilirsiniz.
                    </span>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-title">
                    <span>Kariyer Planlama Uzmanı Başvuru Formu</span>
                  </div>
                </div>
                <div class="col-lg-12 mb-5">
                  <div class="career-form-input">
                    <div class="label">
                      <label for="">Adınız Soyadınız</label>
                    </div>
                    <div class="form-input">
                      <input
                        type="text"
                        formControlName="nameAndSurname"
                        placeholder="Adınız Soyadınız"
                        class="error"
                      />
                    </div>
                    <div
                      *ngIf="form.controls.nameAndSurname.errors?.['required']"
                      class="error-message"
                    >
                      <span>*Bu Alanın Doldurulması Zorunludur.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mb-3">
                  <div class="row">
                    <div class="col-lg-6 mb-lg-auto mb-3">
                      <div class="career-form-input">
                        <div class="label">
                          <label for="">Doğum Tarihi</label>
                        </div>
                        <div class="form-input">
                          <input
                            type="date"
                            formControlName="dateBirth"
                            placeholder="GG / AA / 20__"
                            value="{{
                              this.form.controls.dateBirth.value
                                | date: 'yyyy-MM-dd'
                            }}"
                          />
                          <div class="date-icon">
                            <span class="icon-calender icon"></span>
                          </div>
                        </div>
                        <div
                          *ngIf="form.controls.dateBirth.errors?.['required']"
                          class="error-message"
                        >
                          <span>*Bu Alanın Doldurulması Zorunludur.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="career-form-input">
                        <div class="label">
                          <label for="">Yaşadığınız Şehir</label>
                        </div>
                        <div class="form-input">
                          <input
                            type="text"
                            formControlName="lifeCity"
                            class="error"
                          />
                          <!-- <select
                            formControlName="lifeCity"
                            class="selectpicker"
                            title="Şehir Seç"
                            data-live-search="true"
                            data-size="8"
                            data-live-search-placeholder="Ara"
                            data-language="tr_TR"
                          >
                            <option value="">Seçiniz</option>
                            <option
                              [value]="item.displayName"
                              *ngFor="let item of provinceSelect"
                            >
                              {{ item.displayName }}
                            </option>
                          </select> -->

                          <!-- <select

                          >
                            <option>Adana</option>
                            <option>Adıyaman</option>
                            <option>Afyonkarahisar</option>
                            <option>Ağrı</option>
                            <option>Amasya</option>
                            <option>Ankara</option>
                            <option>Antalya</option>
                            <option>Artvin</option>
                            <option>Aydın</option>
                            <option>Balıkesir</option>
                            <option>Bilecik</option>
                            <option>Bingöl</option>
                            <option>Bitlis</option>
                            <option>Bolu</option>
                            <option>Burdur</option>
                            <option>Bursa</option>
                            <option>Çanakkale</option>
                            <option>Çankırı</option>
                            <option>Çorum</option>
                            <option>Denizli</option>
                            <option>Diyarbakır</option>
                            <option>Edirne</option>
                            <option>Elazığ</option>
                            <option>Erzincan</option>
                            <option>Erzurum</option>
                            <option>Eskişehir</option>
                            <option>Gaziantep</option>
                            <option>Giresun</option>
                            <option>Gümüşhane</option>
                            <option>Hakkâri</option>
                            <option>Hatay</option>
                            <option>Isparta</option>
                            <option>Mersin</option>
                            <option>İstanbul</option>
                            <option>İzmir</option>
                          </select> -->
                          <!-- <div class="icon-select">
                            <span class="icon-right-thin icon"></span>
                          </div> -->
                        </div>
                        <div
                          *ngIf="form.controls.lifeCity.errors?.['required']"
                          class="error-message"
                        >
                          <span>*Bu Alanın Doldurulması Zorunludur.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mb-3">
                  <div class="row">
                    <div class="col-lg-6 mb-lg-auto mb-3">
                      <div class="career-form-input">
                        <div class="label">
                          <label for="">Telefon Numaranız</label>
                        </div>
                        <div class="form-input">
                          <input
                            type="text"
                            formControlName="phoneNumber"
                            placeholder="+9 0 ( - - - )   - - -   - -  - -"
                            maxlength="12"
                            class="error"
                          />
                        </div>
                        <div
                          *ngIf="form.controls.phoneNumber.errors?.['required']"
                          class="error-message"
                        >
                          <span>*Eksik veya Hatalı Bilgi Girdiniz.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="career-form-input">
                        <div class="label">
                          <label for=""
                            >Ünvanınız
                            <span
                              >(İş yerinde çalıştığınız rol/title)</span
                            ></label
                          >
                        </div>
                        <div class="form-input">
                          <input
                            type="text"
                            formControlName="workTitle"
                            placeholder="Yazınız"
                            class="error"
                          />
                        </div>
                        <div
                        *ngIf="form.controls.workTitle.errors?.['required']"
                        class="error-message"
                      >
                        <span>*Bu Alanın Doldurulması Zorunludur.</span>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mb-5">
                  <div class="career-form-input">
                    <div class="label">
                      <label for="">Adresiniz</label>
                    </div>
                    <div class="form-input">
                      <textarea
                        formControlName="address"
                        placeholder="Yazınız"
                        class="error"
                      ></textarea>
                    </div>
                    <div
                    *ngIf="form.controls.address.errors?.['required']"
                    class="error-message"
                  >
                    <span>*Bu Alanın Doldurulması Zorunludur.</span>
                  </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="career-question">
                    <div class="title">
                      <span
                        >Daha önce Kariyer Planlama Uzmanlık deneyimi yaşadınız
                        mı?</span
                      >
                    </div>
                    <div class="answers">
                      <label class="answer">
                        <span>Evet</span>
                        <input
                          formControlName="beforeSpecialist"
                          name="beforeSpecialist"
                          type="radio"
                          [value]="true"
                          checked="checked"
                        />
                        <span class="checkmark"></span>
                      </label>
                      <label class="answer">
                        <span>Hayır</span>
                        <input
                          formControlName="beforeSpecialist"
                          name="beforeSpecialist"
                          [value]="false"
                          type="radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="career-form-input">
                      <div class="col-lg-6 mt-3 mx-auto">
                        <!-- <div class="form-input">
                          <select
                            class="selectpicker"
                            title="Deneyim Seç"
                            data-live-search="true"
                            data-size="8"
                            data-live-search-placeholder="Ara"
                            data-language="tr_TR"
                            formControlName="pastExperience"
                          >
                            <option [value]="'Deneyim 1'">Deneyim 1</option>
                            <option [value]="'Deneyim 2'">Deneyim 2</option>
                            <option [value]="'Deneyim 3'">Deneyim 3</option>
                          </select>
                          <div class="icon-select">
                            <span class="icon-right-thin icon"></span>
                          </div>
                        </div> -->
                        <div
                          class="form-input"
                          *ngIf="form.controls.beforeSpecialist.value"
                        >
                          <div class="select">
                            <select
                              formControlName="pastExperience"
                              data-live-search="true"
                              data-size="8"
                              data-live-search-placeholder="Ara"
                              data-language="tr_TR"
                            >
                              <option selected>Seçiniz</option>
                              <option value="Kariyer Planlama Uzmanı">
                                Kariyer Planlama Uzmanı
                              </option>
                              <option value="Kariyer Planlama Menti">
                                Kariyer Planlama Menti
                              </option>
                              <option value="Hepsi">Hepsi</option>
                            </select>
                            <div class="icon">
                              <span class="icon-down-thin"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mb-5">
                  <div class="form-file-upload">
                    <input
                      type="file"
                      id="careerFormFile"
                      (change)="onFileSelected($event)"
                    />
                    <label for="careerFormFile">Özgeçmiş (CV) Ekle</label>
                  </div>
                </div>
                <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
                <div class="my-certificates-list">
                  <div class="row row-gap-15">
                    <div
                      class="col-xl-12"
                      *ngIf="model?.careerSpecialistForm.cvFileFileName"
                    >
                      <div class="my-certificates-item">
                        <div class="item-body">
                          <div class="body-header">
                            <span class="title">
                              {{
                                model?.careerSpecialistForm.cvFileFileName
                              }}</span
                            >
                            <!-- <a
                              href="#my-certificate-edit"
                              data-toggle="modal"
                              data-target="#my-certificate-edit"
                              class="edit"
                            >
                              <span class="icon-edit"></span>
                              <span>Düzenle</span>
                            </a> -->
                          </div>
                          <div class="body-footer">
                            <div class="detail-redirect">
                              <a
                                (click)="
                                  downloadFile(
                                    model.careerSpecialistForm.cvFile,
                                    model.careerSpecialistForm.cvFileFileName
                                  )
                                "
                                mat-button
                                >İndir
                                <mat-icon color="primary">download</mat-icon>
                              </a>
                              <a
                                (click)="
                                  deleteFile(
                                    model.careerSpecialistForm.id,
                                    model.careerSpecialistForm.cvFileFileName,
                                    i
                                  )
                                "
                                mat-button
                              >
                                Sil
                                <mat-icon color="accent">delete</mat-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mb-5">
                  <div class="career-form-send">
                    <a (click)="onSubmit()">Gönder</a>
                  </div>
                </div>

                <div
                  class="col-lg-12 mb-5"
                  *ngIf="
                    this.model?.careerSpecialistForm.joinStatus ==
                      'Başvuru Reddedildi' &&
                    this.form.controls.id.value !== null
                  "
                >
                  <div class="career-form-send">
                    <a (click)="restartForm()">Yeniden Doldur</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" *ngIf="model?.careerSpecialistForm.id">
                <div class="register-statu">
                  <div class="title">
                    <span>Kayıt durum bilgisi:</span>
                  </div>
                  <div class="status">
                    <span>{{ model?.careerSpecialistForm.joinStatus }}</span>
                  </div>
                </div>

                <div
                  class="register-statu"
                  *ngIf="model?.careerSpecialistForm.isAcceptedDescription"
                >
                  <div class="title">
                    <span>Açıklama:</span>
                  </div>
                  <div class="status">
                    <span>{{
                      model?.careerSpecialistForm.isAcceptedDescription
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <script src="./scripts/kizilay-kilavuz.lib.min.js?ver=1"></script>
    <script src="./scripts/kizilay-kilavuz.app.min.js?ver=1"></script>
  </body>
</html>
