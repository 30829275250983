<div *ngIf="isPageDone">
  <div class="content my-information-content sub-page-content">
    <div class="fixed-area">
      <section class="col-12 px-0 history-back">
        <a href="#">
          <span class="icon-brush-left-arrow icon"></span>
          <span class="text">Geri</span>
        </a>
      </section>
    </div>
    <div class="scroll-content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div *ngIf="
            utilsService.currentUserRole == 'yetim' ||
            this.utilsService.selectedOrphanId > 0
          " class="information-module">
          <div class="row">
            <div class="col-12">
              <div class="information-module-header">
                <div class="module-title">
                  <span>Kimlik Bilgilerim</span>
                </div>
                <!-- <ng-template *hasPermission="['Pages.Orphans.Create']"> -->
                <div class="module-update">
                  <a *ngIf="!isEdit">
                    <span class="icon-edit icon"></span>
                    <span (click)="changeEdit()">Düzenle</span>
                  </a>
                  <a *ngIf="isEdit">
                    <span class="icon-edit icon"></span>
                    <span (click)="onSubmit()">Kaydet</span>
                    <!-- <button type="submit">Kaydet</button> -->
                  </a>
                </div>
                <!-- </ng-template> -->
              </div>

              <div class="information-module-content">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="row inputs-container">
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Ad</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="firstName" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Soyad</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="lastName" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Cinsiyet</span>
                              </div>
                              <div class="select">
                                <select formControlName="gender">
                                  <option value="0">Erkek</option>
                                  <option value="1">Kadın</option>
                                </select>
                                <div class="icon">
                                  <span class="icon-down-thin"></span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Medeni Durumu</span>
                              </div>
                              <div class="select">
                                <select formControlName="maritalStatus">
                                  <option value="">Seçiniz</option>
                                  <option value="{{ item }}" *ngFor="let item of maritalStatusSelect">
                                    {{ getMaritalStatusName(item) }}
                                  </option>
                                </select>
                                <div class="icon">
                                  <span class="icon-down-thin"></span>
                                </div>
                              </div>
                              <!-- <div class="input">
                              <input
                                type="text"
                                formControlName="maritalStatus"
                              />
                            </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Doğum Yeri</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="placeOfBirth" />
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Doğum Tarihi</span>
                              </div>
                              <!-- <div class="input">
                              <input
                                #datee
                                type="text"
                                formControlName="dateOfBirth"
                                value="{{ datee.value | date: 'dd/MM/yyyy' }}"
                              />
                            </div> -->
                              <div class="input">
                                <input type="date" formControlName="dateOfBirth" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                    <div class="row inputs-container">
                      <div class="col-12">
                        <!-- <div class="module-input-group">
                        <div class="input-name">
                          <span>Uyruk</span>
                        </div>
                        <div class="input">
                          <input type="text" formControlName="nationality" />
                        </div>
                      </div> -->
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Uyruk</span>
                          </div>
                          <div class="select">
                            <select formControlName="nationality">
                              <option selected value="">Seçiniz</option>
                              <option value="{{ item.id }}" *ngFor="let item of nationalSelect">
                                {{ item.displayName }}
                              </option>
                            </select>
                            <div class="icon">
                              <span class="icon-down-thin"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>TC Kimlik</span>
                          </div>
                          <div class="input">
                            <input type="text" minlength="11" maxlength="11" formControlName="tcNumber" (keypress)="tcNoOnInput($event)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Anne Adı / Soyadı</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="motherName" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Baba Adı / Soyadı</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="fatherName" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-5">
              <div class="information-module-header">
                <div class="module-title">
                  <span>İletişim Bilgilerim</span>
                </div>
              </div>
              <div class="information-module-content">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="row inputs-container">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>İl</span>
                              </div>
                              <div class="select">
                                <select (change)="changeProvince($event)" formControlName="provinceId">
                                  <option [value]="null">Seçiniz</option>
                                  <option value="{{ item.id }}" *ngFor="let item of provinceSelect">
                                    {{ item.displayName }}
                                  </option>
                                </select>
                                <div class="icon">
                                  <span class="icon-down-thin"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>İlçe</span>
                              </div>
                              <div class="select">
                                <select (change)="changeDistrict($event)" formControlName="districtId">
                                  <option selected [value]="null">
                                    Seçiniz
                                  </option>
                                  <option value="{{ item.id }}" *ngFor="let item of districtSelect">
                                    {{ item.displayName }}
                                  </option>
                                </select>
                                <div class="icon">
                                  <span class="icon-down-thin"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Mahalle</span>
                              </div>
                              <div class="select">
                                <select formControlName="neighborhoodId">
                                  <option selected [value]="null">
                                    Seçiniz
                                  </option>
                                  <option value="{{ item.id }}" *ngFor="let item of neighborhoodSelect">
                                    {{ item.displayName }}
                                  </option>
                                </select>
                                <div class="icon">
                                  <span class="icon-down-thin"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Bina / Kapı No</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="apartmentNumber" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Posta Kodu</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="postCode" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Adres</span>
                          </div>
                          <div class="textarea">
                            <textarea formControlName="address"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                    <div class="row inputs-container">
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Cep Telefonu</span>
                          </div>
                          <div class="input">
                            <input type="tel" [textMask]="{ mask: mask }" maxlength="15"
                              formControlName="mobileNumber" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Ev Telefonu</span>
                          </div>
                          <div class="input">
                            <input type="tel" [textMask]="{ mask: mask }" formControlName="homeNumber" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>E-Posta Adresi</span>
                          </div>
                          <div class="input">
                            <input type="mail" formControlName="eMail" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="
            utilsService.currentUserRole != 'yetim' &&
            utilsService.selectedOrphanId == 0
          " class="information-module">
          <div class="row">
            <div class="col-12">
              <div class="information-module-header">
                <div class="module-title">
                  <span>Kimlik Bilgilerim</span>
                </div>

                <div class="module-update" *hasPermission="['Pages.Orphans.Create']">
                  <a *ngIf="!isEdit">
                    <span class="icon-edit icon"></span>
                    <span (click)="changeEdit()">Düzenle</span>
                  </a>
                  <a *ngIf="isEdit">
                    <span class="icon-edit icon"></span>
                    <span (click)="onSubmit()">Kaydet</span>
                    <!-- <button type="submit">Kaydet</button> -->
                  </a>
                </div>
              </div>

              <div class="information-module-content">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="row inputs-container">
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Ad</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Soyad</span>
                          </div>
                          <div class="input">
                            <input type="text" formControlName="surname" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Email</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="emailAddress" />
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Telefon numarası</span>
                              </div>
                              <div class="input">
                                <input
                                  type="tel"
                                  [textMask]="{ mask: mask }"
                                  maxlength="15"
                                  formControlName="phoneNumber"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Yaşadığı Şehir</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="lifeCity" />
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Doğum Tarihi</span>
                              </div>
                              <div class="input">
                                <input type="date" #educationDate formControlName="dateBirth" value="{{
                                    this.form.controls.dateBirth.value
                                      | date: 'yyyy-MM-dd'
                                  }}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="module-input-group">
                          <div class="input-name">
                            <span>Adres</span>
                          </div>
                          <div class="textarea">
                            <textarea formControlName="address"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <div class="module-input-group">
                              <div class="checkbox">
                                <input type="checkbox" formControlName="beforeSpecialist" />
                                <label>Daha önce Kariyer Planlama Uzmanlık deneyimi
                                  yaşadınız mı?</label>
                              </div>
                              <div *ngIf="
                                  this.form.controls.beforeSpecialist.value
                                " class="module-input-group">
                                <div class="input-name">
                                  <span>Görevi </span>
                                </div>
                                <div class="select">
                                  <select formControlName="pastExperience">
                                    <option value="">Seçiniz!!!</option>
                                    <option value="Başvuru Değerlendirmeye Gönderildi">
                                      Kariyer Planlama Uzmanı
                                    </option>
                                    <option value="Başvuru Onaylandı">
                                      Kariyer Planlama Menti
                                    </option>
                                    <option value="Başvuru Reddedildi">
                                      Hepsi
                                    </option>
                                  </select>
                                  <div class="icon">
                                    <span class="icon-down-thin"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Unvanı</span>
                              </div>
                              <div class="input">
                                <input type="text" formControlName="workTitle" />
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="module-input-group">
                              <div class="input-name">
                                <span>Gönüllü Kayıt Tarihi</span>
                              </div>
                              <div class="input">
                                <input type="date" #educationDate formControlName="workStartDate" value="{{
                                    this.form.controls.workStartDate.value
                                      | date: 'yyyy-MM-dd'
                                  }}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                  <div class="row inputs-container">
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Uyruk</span>
                        </div>
                        <div class="input">
                          <input type="text" formControlName="nationality" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>TC Kimlik</span>
                        </div>
                        <div class="input">
                          <input type="text" formControlName="tcNumber" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Anne Adı / Soyadı</span>
                        </div>
                        <div class="input">
                          <input
                            type="text"
                            formControlName="motherAndFatherName"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Baba Adı / Soyadı</span>
                        </div>
                        <div class="input">
                          <input
                            type="text"
                            formControlName="motherAndFatherName"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="information-module-content">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="row inputs-container">
                      <!-- <div class="col-12">
                      <div class="row">
                        <div class="col-6">
                          <div class="module-input-group">
                            <div class="input-name">
                              <span>İl</span>
                            </div>
                            <div class="select">
                              <select
                                (change)="changeProvince($event)"
                                formControlName="provinceId"
                              >
                                <option
                                  value="{{ item.id }}"
                                  *ngFor="let item of provinceSelect"
                                >
                                  {{ item.displayName }}
                                </option>
                              </select>
                              <div class="icon">
                                <span class="icon-down-thin"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="module-input-group">
                            <div class="input-name">
                              <span>İlçe</span>
                            </div>
                            <div class="select">
                              <select
                                (change)="changeDistrict($event)"
                                formControlName="districtId"
                              >
                                <option
                                  value="{{ item.id }}"
                                  *ngFor="let item of districtSelect"
                                >
                                  {{ item.displayName }}
                                </option>
                              </select>
                              <div class="icon">
                                <span class="icon-down-thin"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    -->

                      <!-- <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Adres</span>
                        </div>
                        <div class="textarea">
                          <textarea formControlName="address"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                  <div class="row inputs-container">
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Cep Telefonu</span>
                        </div>
                        <div class="input">
                          <input type="text" formControlName="mobileNumber" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>Ev Telefonu</span>
                        </div>
                        <div class="input">
                          <input type="text" formControlName="homeNumber" />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="module-input-group">
                        <div class="input-name">
                          <span>E-Posta Adresi</span>
                        </div>
                        <div class="input">
                          <input type="mail" formControlName="eMail" />
                        </div>
                      </div>
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <app-footer></app-footer>
    </div>
  </div>
</div>
