import { DateTime } from "luxon";

export class OrphanSchoolModel {
  orphanSchool: SchoolInfoViewModel;
  orphanFirstName: string;
  educationTypeName: string;
  availabilityStatusName: string;
  provinceName: string;
  binaryFileName: string;
}
export class SchoolInfoViewModel {
  programName: string;
  schoolClassName: string;
  schoolName: string;
  availabilityDate: Date;
  orphanId: number;
  educationTypeId: number;
  availabilityStatusId: number;
  schoolProvinceId: number;
  referenceNumber: string;
  binaryFileToken: string;
  id: number;
}


