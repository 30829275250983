import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { InterestModel } from 'src/app/models/orphan/interest';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class InterestService extends GenericCrudService<InterestModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanInterestDetails")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  getInterestAllWithOrphanId(orphanId: number): Observable<InterestModel[]> {
    return this.http.get<InterestModel[]>(this.baseUrl + "/GetOrphanInterestAllWithOrphanId?orphanId=" + orphanId);
  }

  getInterestAllWithUserId(userId: number): Observable<InterestModel[]> {
    return this.http.get<InterestModel[]>(this.baseUrl + "/GetOrphanInterestAllWithOrphanId?userId=" + userId);
  }

  getOrphanInterestDetailForEdit(id: number): Observable<InterestModel> {
    return this.http.get<InterestModel>(this.baseUrl + "/GetOrphanInterestDetailForEdit?id=" + id);
  }
}
