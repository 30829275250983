import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { CareerSpecialistFormModel } from 'src/app/models/manager/career-specialist-form';
import { AnnouncementModel, AnnouncementViewModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class CareerSpecialistFormService extends GenericCrudService<CareerSpecialistFormModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/CareerSpecialistForms")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  getCareerSpecialistFormForView(id: number): Observable<CareerSpecialistFormModel> {
    return this.http.get<CareerSpecialistFormModel>(this.baseUrl + "/GetCareerSpecialistFormForView?id=" + id);
  }

  getCareerSpecialistFormForEdit(id: number): Observable<CareerSpecialistFormModel> {
    return this.http.get<CareerSpecialistFormModel>(this.baseUrl + "/GetCareerSpecialistFormForEdit?id=" + id);
  }

  getAllProvinceForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(environment.apiRoot + "/api/services/app/OrphanSchools/GetAllProvinceForTableDropdown");
  }

  downloadBinaryFile(binaryFileId: string): Observable<Blob> {
    return this.http.get(environment.apiRoot + "/File/DownloadBinaryFile?id=" + binaryFileId, { responseType: "blob" })
  }

  removeCvFile(id: number): Observable<any> {
    return this.http.delete<any[]>(this.baseUrl + "/RemoveCvFileFile?Id=" + id);
  }
}
