import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { ExperienceViewModel, OrphanExperienceModel } from 'src/app/models/orphan/experience';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService extends GenericCrudService<ExperienceViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanExperiences")
  }

  getOrphanExperienceAllWithOrphanId(queryString: string): Observable<OrphanExperienceModel[]> {
    return this.http.get<OrphanExperienceModel[]>(this.baseUrl + "/GetOrphanExperienceAllWithOrphanId?" + queryString);
  }

  getOrphanExperienceForEdit(id: number): Observable<OrphanExperienceModel> {
    return this.http.get<OrphanExperienceModel>(this.baseUrl + "/GetOrphanExperienceForEdit?id=" + id);
  }

  getAllProvinceForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllProvinceForTableDropdown");
  }
}
