import { DateTime } from "luxon";

export class UserModel {
  user: UserViewModel;
  assignedRoleNames: string[];
  roles: UserRoleModel[];
}


export class UserViewModel {
  id: number;
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  isActive: boolean;
  shouldChangePasswordOnNextLogin: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
  mdG_ID: number;
  regionId: number;
  branchId: number;
  representativeId: number;
  isOrphanManager: boolean;
  dateBirth: DateTime;
  lifeCity: string;
  address: string;
  workTitle: string;
  beforeSpecialist: boolean;
  pastExperience: string;
  workStartDate: DateTime;
  createdDate: DateTime;
}


export class UserRoleModel {
  roleId: number;
  roleName: string;
  roleDisplayName: string;
  isAssigned: boolean;
  inheritedFromOrganizationUnit: boolean;
}
