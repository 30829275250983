<div *ngIf="isPageDone" class="content task-detail-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="/gorevlerim">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div class="item-header">
      <div class="edit-item" *hasPermission="['Pages.OrphanTasks.Edit']">
        <a (click)="edit(model.orphanTask.id)">
          <span class="icon-edit icon"></span>
          <span>Düzenle</span>
        </a>
      </div>
    </div>
  </div>
  <div class="scroll-content">
    <div class="task-detail-content">
      <div class="row">
        <div class="col-12">
          <div class="task-detail">
            <div class="task-detail-title">
              <span>Görev Adı :</span>
            </div>
            <div class="task-name">
              <span>{{ model.orphanTaskTypeName }}</span>
            </div>
            <div class="task-detail-title description-title">
              <span>Görev Konusu :</span>
            </div>
            <div class="task-description">
              <span>{{ model.orphanTask.subject }}</span>
            </div>
            <div class="task-detail-title description-title">
              <span>Açıklama :</span>
            </div>
            <div class="task-description">
              <span>{{ model.orphanTask.description }}</span>
            </div>
            <div class="task-detail-title date-title">
              <span>Hedeflenen Tarih :</span>
            </div>
            <div class="task-date">
              <span>{{
                model.orphanTask.targetCompletedDate | date: "dd/MM/yyyy"
              }}</span>
            </div>
            <ng-container *ngIf="model.orphanTask.isCompleted">
              <div class="task-detail-title date-title">
                <span>Tamamlanma Tarihi :</span>
              </div>
              <div class="task-date">
                <span>{{
                  model.orphanTask.completedDate | date: "dd/MM/yyyy"
                }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- <div class="col-12 mt-5">
          <div class="task-comment">
            <form [formGroup]="form" (ngSubmit)="onSubmitComment()">
              <div class="item-full-group">
                <div class="item-textarea-name">
                  <span>Yorum</span>
                </div>
                <div class="textarea">
                  <textarea formControlName="commentText"></textarea>
                </div>
              </div>
              <button align="end" mat-button>
                <span class="icon-edit icon"></span> <span>Ekle</span>
              </button>
            </form>
            <div class="comment-title">
              <span>Yorumlar ({{ model?.orphanTaskComments?.length }})</span>
            </div>
            <div class="comment-list">
              <div
                class="comment"
                *ngFor="let item of model.orphanTaskComments"
              >
                <div class="profile-photo">
                  <img src="https://picsum.photos/200/300 " alt="" />
                </div>
                <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                  <div class="content-title">
                    <span class="name">{{ item.userName }}</span>
                    <span class="date">{{
                      item.orphanTaskComment.commentDate | date: "dd/MM/yyyy"
                    }}</span>
                  </div>
                  <div class="content-description">
                    <p>
                      {{ item.orphanTaskComment.commentText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
