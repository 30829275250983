import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { ProjectStatusLanguage } from 'src/app/models/generic-base/custom-enum-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { ProjectViewModel } from 'src/app/models/orphan/project';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { FileService, ProjectService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProjectStatus } from 'src/shared/service-proxies/service-proxies';

@Component({
  selector: 'app-project-info-edit',
  templateUrl: './project-info-edit.component.html',
  styleUrls: ['./project-info-edit.component.css']
})
export class ProjectInfoEditComponent extends ModalViewComponent<ProjectInfoEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  model: ProjectViewModel;
  form: FormGroup;
  isEdit = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;
  projectStatusSelect = Object.values(ProjectStatus).filter(p => !isNaN(Number(p)));

  constructor(
    private service: ProjectService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ProjectInfoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      year: ["", Validators.required],
      name: ["", Validators.required],
      projectStatus: ["", Validators.required],
      orphanId: [this.utilsService.selectedOrphanId, Validators.required]
    })

    // const taskTypePromise$ = this.service.getAllOrphanTaskTypeForTableDropdown().toPromise();

    if (this.dialogData.id) {
      this.service.getOrphanProjectDetailForEdit(this.dialogData.id).subscribe(result => {
        this.model = result.orphanProjectDetail;
        this.form.patchValue(this.model);
      })
    }
    this.form.patchValue(this.model);
    this.isPageDone = true;
  }

  get f() {
    return this.form.controls;
  }

  getProjectStatusName(id: string): string {
    return ProjectStatusLanguage[id];
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe((response) => {
          this.dialogRef.close(result);
        })
      }
    })
  }
}
