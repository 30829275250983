import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanTaskCommentModel, UserNotificationPermissionModel, UserNotificationPermissionViewModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationPermissionService extends GenericCrudService<UserNotificationPermissionViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/UserNotificationPermission")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  getUserNotificationPermissionForEdit(id: number): Observable<UserNotificationPermissionModel> {
    return this.http.get<UserNotificationPermissionModel>(this.baseUrl + "/GetUserNotificationPermissionForEdit?id=" + id);
  }

  getUserNotificationPermissionForView(id: number): Observable<UserNotificationPermissionModel> {
    return this.http.get<UserNotificationPermissionModel>(this.baseUrl + "/GetUserNotificationPermissionForView?id=" + id);
  }
}
