<footer class="sub-footer">
  <div class="copyright">
    <span>2022 © Türk Kızılay. Tüm hakları saklıdır.</span>
  </div>
  <div class="logo">
    <a href="#">
      <img src="../../../../assets/images//footer-logo.png" alt="" />
    </a>
  </div>
</footer>
