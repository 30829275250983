import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataRequestModel } from 'src/app/models/generic-base/data-model';
import { UserNotificationPermissionModel } from 'src/app/models/orphan';
import { UserNotificationPermissionService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-user-notification-permission',
  templateUrl: './user-notification-permission.component.html'
})
export class UserNotificationPermissionComponent implements OnInit {

  requestModel = new DataRequestModel();
  form: FormGroup;
  model: UserNotificationPermissionModel;
  isPageDone = false;
  isSubmitted = false;

  constructor(private service: UserNotificationPermissionService,
    private utilsService: UtilsService,
    public formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    // let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);
    // this.service.getAll(paramsString).subscribe(result => {
    // })
    this.form = this.formBuilder.group({
      id: "",
      userId: ["", Validators.required],
      eventEMail: [false],
      announcementEMail: [false],
      taskEMail: [false],
      jobEMail: [false],
      newEMail: [false],
      eventSms: [false],
      announcementSms: [false],
      taskSms: [false],
      jobSms: [false],
      newSms: [false],
    })

    this.getData();
  }

  getData(): void {
    this.service.getUserNotificationPermissionForEdit(this.utilsService.getTokenDecode()["user_identifier"]).subscribe(result => {
      this.model = result;
      if (result != null) {
        this.form.patchValue(this.model.userNotificationPermission);
      }

      this.form.controls.userId.setValue(this.utilsService.getTokenDecode()["user_identifier"]);
      this.isPageDone = true;
    })
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.service.createOrEdit(this.form.value).subscribe(result => {
        this.utilsService.showMessage("İşlem Başarılı")
        this.getData();
      })
    }
  }
}
