import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AppConstants } from 'src/app-constants';
import { ReadBookDataRequestModel, ReadBookModel } from 'src/app/models/orphan/read-book';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { ReadBookService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ReadBookEditComponent } from './read-book-edit/read-book-edit.component';

@Component({
  selector: 'app-read-book',
  templateUrl: './read-book.component.html'
})
export class ReadBookComponent implements OnInit {

  isPageDone = false;
  model: ReadBookModel[];
  requestModel = new ReadBookDataRequestModel();
  dataCount: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;


  constructor(
    public service: ReadBookService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Okuduğum Kitaplar");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {

    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 5;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.orphanId = this.utilsService.selectedOrphanId;
    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);

    this.service.getOrphanBookAllWithOrphanId(paramsString).subscribe(result => {
      this.model = result.items;
      this.dataCount = result.totalCount;
      this.isPageDone = true;
    });
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(ReadBookEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

  getServerData() {
    this.requestModel.maxResultCount = this.paginator.pageSize;
    this.requestModel.skipCount = this.paginator.pageSize * this.paginator.pageIndex;
    this.reloadList();
  }
}
