export class OnlineCourseModel {
  id: 0;
  fullname: string;
  displayname: string;
  shortname: string;
  categoryid: 0;
  categoryname: string;
  sortorder: 0;
  summary: string;
  summaryformat: 0;
  summaryfiles: [
    string
  ];
  overviewfiles: [
    {
      filename: string;
      filepath: string;
      filesize: 0;
      fileurl: string;
      timemodified: 0;
      mimetype: string
    }
  ];
  showactivitydates: true;
  showcompletionconditions: true;
  contacts: [
    {
      id: 0;
      fullname: string
    }
  ];
  enrollmentmethods: [
    string
  ];
  customfields: [
    {
      type: string;
      value: string;
      name: string;
      shortname: string
    }
  ];
  idnumber: string;
  format: string;
  showgrades: 0;
  newsitems: 0;
  startdate: 0;
  enddate: 0;
  maxbytes: 0;
  showreports: 0;
  visible: 0;
  groupmode: 0;
  groupmodeforce: 0;
  defaultgroupingid: 0;
  enablecompletion: 0;
  completionnotify: 0;
  lang: string;
  theme: string;
  marker: 0;
  legacyfiles: 0;
  calendartype: string;
  timecreated: 0;
  timemodified: 0;
  requested: 0;
  cacherev: 0;
  filters: [
    {
      filter: string;
      localstate: 0;
      inheritedstate: 0
    }
  ];
  courseformatoptions: [
    {
      name: string;
      value: {
        integer: 0;
        string: string
      }
    }
  ]
}

export class ApplicationCourseModel {
  id: 0;
  shortname: string;
  fullname: string;
  displayname: string;
  enrolledusercount: 0;
  lang: string;
}
