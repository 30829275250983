<div class="content foreign-lang-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div
      *hasPermission="['Pages.OrphanLanguageDetails.Create']"
      class="new-item"
    >
      <a (click)="edit(null)">
        <span>Yeni Ekle</span>
        <span class="icon-plus-circle-thin"></span>
      </a>
    </div>
  </div>
  <div class="scroll-content">
    <div class="foreign-lang-content" *ngIf="model && model.length > 0">
      <div class="item" *ngFor="let item of model">
        <div class="item-header">
          <div
            class="edit-item"
            *hasPermission="['Pages.OrphanLanguageDetails.Edit']"
          >
            <a (click)="edit(item.orphanLanguageDetail.id)">
              <span class="icon-edit icon"></span>
              <span>Düzenle</span>
            </a>
          </div>
        </div>
        <div class="item-body">
          <div class="row row-gap-xs-15">
            <div class="col-lg-6">
              <div class="default-input-group">
                <div class="title">
                  <span>Dil Adı</span>
                </div>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    value="{{ item.orphanLanguageDetail.name }}"
                    placeholder="Dil Adını Yazınız"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="default-input-group">
                <div class="title">
                  <span>Okuma</span>
                </div>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    value="{{
                      getTaskTypeName(
                        item.orphanLanguageDetail.languageLevelRead
                      )
                    }}"
                    placeholder="Dil Adını Yazınız"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="default-input-group">
                <div class="title">
                  <span>Yazma</span>
                </div>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    value="{{
                      getTaskTypeName(
                        item.orphanLanguageDetail.languageLevelWrite
                      )
                    }}"
                    placeholder="Dil Adını Yazınız"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="default-input-group">
                <div class="title">
                  <span>Konuşma</span>
                </div>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    value="{{
                      getTaskTypeName(
                        item.orphanLanguageDetail.languageLevelTolk
                      )
                    }}"
                    placeholder="Dil Adını Yazınız"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-content-text" *ngIf="!model || model.length <= 0">
      <h6>Görüntülenecek veri bulunamadı</h6>
    </div>
    <div
      class="modal fade lang-modal"
      id="lang-edit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="lang-modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-title">
              <span>Dili Düzenle</span>
            </div>
            <div class="row flex-column">
              <div class="col-lg-12 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Dil Adı</span>
                  </div>
                  <div class="input">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Dil Adını Yazınız"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Okuma</span>
                  </div>
                  <div class="select">
                    <select name="" id="">
                      <option selected hidden>Seç</option>
                      <option>Tür #1</option>
                      <option>Tür #2</option>
                      <option>Tür #3</option>
                    </select>
                    <div class="icon">
                      <span class="icon-down-thin"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Yazma</span>
                  </div>
                  <div class="select">
                    <select name="" id="">
                      <option selected hidden>Seç</option>
                      <option>Tür #1</option>
                      <option>Tür #2</option>
                      <option>Tür #3</option>
                    </select>
                    <div class="icon">
                      <span class="icon-down-thin"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Konuşma</span>
                  </div>
                  <div class="select">
                    <select name="" id="">
                      <option selected hidden>Seç</option>
                      <option>Tür #1</option>
                      <option>Tür #2</option>
                      <option>Tür #3</option>
                    </select>
                    <div class="icon">
                      <span class="icon-down-thin"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-content mt-3">
              <div class="delete-btn">
                <button>Dili Sil</button>
              </div>
              <div class="add-btn">
                <button>Kaydet</button>
              </div>
            </div>
            <button
              type="button"
              class="close modal-close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span class="icon-modal-close"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
