import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OnlineCourseModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineCourseService extends GenericCrudService<OnlineCourseModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/Lms")
  }

  getCourses(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/GetCourses");
  }

  enrolCourseKaptanUser(userId: number, courseId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/EnrolCourseKaptanUser?userId=" + userId + "&courseId=" + courseId, null);
  }

  unEnrolCourseKaptanUser(userId: number, courseId: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/UnEnrolCourseKaptanUser?userId=" + userId + "&courseId=" + courseId, null);
  }

  getEnrolCourses(userId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/GetEnrolCourses?userId=" + userId);
  }
}
