import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AppConstants } from 'src/app-constants';
import { ProjectStatusLanguage } from 'src/app/models/generic-base/custom-enum-model';
import { ProjectDataRequestModel, OrphanProjectModel } from 'src/app/models/orphan/project';
import { ProjectService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProjectStatus } from 'src/shared/service-proxies/service-proxies';
import { ProjectInfoEditComponent } from './project-info-edit/project-info-edit.component';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.css']
})
export class ProjectInfoComponent implements OnInit {

  isPageDone = false;
  model: OrphanProjectModel[];
  dataCount: number;
  requestModel = new ProjectDataRequestModel();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public service: ProjectService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Projelerim");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 5;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.orphanIdFilter = this.utilsService.selectedOrphanId;

    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);
    this.service.getOrphanProjectAll(this.utilsService.selectedOrphanId, paramsString).subscribe(result => {
      this.model = result.items;
      this.dataCount = result.totalCount;
    });
  }

  getServerData() {
    this.requestModel.maxResultCount = this.paginator.pageSize;
    this.requestModel.skipCount = this.paginator.pageSize * this.paginator.pageIndex;
    this.requestModel.orphanIdFilter = this.utilsService.selectedOrphanId;
    this.reloadList();
  }

  getProjectStatusName(id: string): string {
    return ProjectStatusLanguage[id];
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(ProjectInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

}
