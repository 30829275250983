import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AnnouncementModel, NewsModel, EventModel, AnnouncementDataRequestModel, EventDataRequestModel, OrphanViewDto } from 'src/app/models/orphan';
import { MenuModel } from 'src/app/models/shared/menu';
import { JwtTokenService } from 'src/app/services/auth/jwt-token.service';
import { AnnouncementService, NewsService, EventCalendarService, EventCalendarJoinService } from 'src/app/services/orphan';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { forEach as _forEach } from 'lodash-es';
import menus from '../../../assets/menu.json';
import { UserNotification } from 'src/shared/service-proxies/service-proxies';
import { environment } from 'src/environments/environment';
import { DateTime } from 'luxon';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit, AfterViewInit {

  title = 'yetim';
  isPageDone = false;
  announcementList: AnnouncementModel[] = [];
  newsList: NewsModel[] = [];
  eventCalendarList: EventModel[] = [];
  menuList: MenuModel[] = menus;
  notifications: any[] = [];
  unreadNotificationCount = 0;
  dreamJob2: string = "";
  slides = [
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" },
    { img: "https://picsum.photos/200/300" }
  ];
  announcementSlideConfig = {
    "infinite": false,
    "slidesPerRow": 1,
    "rows": 2,
    "vertical": false,
    "dots": true,
    "swipeToSlide": true,
    "appendDots": ".announcement-dots",
    "prevArrow": ".announcementPrevButton",
    "nextArrow": ".announcementNextButton",
  };

  eventCalendarSlideConfig = {
    "infinite": false,
    "slidesPerRow": 1,
    "rows": 2,
    "vertical": false,
    "dots": true,
    "swipeToSlide": true,
    "appendDots": ".events-dots",
    "prevArrow": ".upcomingEventsPrevButton",
    "nextArrow": ".upcomingEventsNextButton",
  };

  newsSlideConfig = {
    "infinite": false,
    "slidesPerRow": 1,
    "rows": 2,
    "vertical": false,
    "dots": true,
    "swipeToSlide": true,
    "appendDots": ".news-dots",
    "prevArrow": ".newsPrevButton",
    "nextArrow": ".newsNextButton",
  };

  constructor(
    public activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    private announcementService: AnnouncementService,
    private newsService: NewsService,
    private eventService: EventCalendarService,
    private eventCalendarJoinService: EventCalendarJoinService,
    private notificationService: NotificationService,
    private jwtLoginService: JwtTokenService,
    public dialog: MatDialog
  ) {

  }

  logOut(): void {
    this.jwtLoginService.logout(this.utilsService.getLocalStorage("access_token")).subscribe(() => {
      this.utilsService.removeLocalStorageItem("userId");
      this.utilsService.removeLocalStorageItem("currentUserName");
      this.utilsService.removeLocalStorageItem("pcBase64");
      this.utilsService.removeLocalStorageItem("access_token");
      window.location.reload();
    });
  }

  ngOnInit(): void {
    var token = String(localStorage.getItem('access_token'));
    if (token != "null") {
      this.initData();
    }
  }

  initData(): void {
    if (localStorage.getItem("dreamJob") != null) {
      this.dreamJob2 = localStorage.getItem("dreamJob");
    }
    const helper = new JwtHelperService();
    var myRawToken = null;
    var token = String(localStorage.getItem('access_token'));
    if (token != "null") {
      myRawToken = helper.decodeToken(token);

    }
    if (myRawToken) {
      this.utilsService.setObjectLocalStorage("userId", myRawToken["user_identifier"]);
      let userId = this.utilsService.getObjectLocalStorage("userId");
      if (userId != null) {
        this.utilsService.currentUserId = userId;
      }
      this.utilsService.currentUserRole = String(myRawToken["CustomRole"]).toLowerCase();
      if (this.utilsService.currentUserRole == "admin") {
        this.utilsService.isAdmin = true;
      }
      else if (this.utilsService.currentUserRole.includes("varsay")) {
        window.location.href = "kariyer-basvuru-formu";
        return
      }
      else if (this.utilsService.currentUserRole == "yetim") {
        this.utilsService.selectedOrphanId = myRawToken["ReferanceId"];
      }

      let orphanIdStorageVal = this.utilsService.getObjectLocalStorage("orphanId");
      if (orphanIdStorageVal != null) {
        this.utilsService.selectedOrphanId = orphanIdStorageVal;
      }

      let announcementParamsString = this.utilsService.urlSearchParamConvertToString(new AnnouncementDataRequestModel());
      let newsParamsString = this.utilsService.urlSearchParamConvertToString(new AnnouncementDataRequestModel());
      let eventDataRquestModel = new EventDataRequestModel();
      eventDataRquestModel.minStartDateFilter = DateTime.local().toString();
      eventDataRquestModel.sorting = "startDate asc"
      const announcementPromise$ = this.announcementService.getActiveAll(announcementParamsString).toPromise();
      const newsPromise$ = this.newsService.getActiveAll(newsParamsString).toPromise();
      const eventPromise$ = this.eventService.getAll(this.utilsService.urlSearchParamConvertToString(eventDataRquestModel)).toPromise();

      Promise.all([
        announcementPromise$,
        newsPromise$,
        eventPromise$
      ]).then(result => {
        this.announcementList = result[0].items;
        this.newsList = result[1].items;
        this.eventCalendarList = result[2].items;
        this.notificationService.getUserNotifications(undefined, undefined, undefined, 10, 0).subscribe((result) => {
          this.unreadNotificationCount = result.unreadCount;
          this.notifications = [];
          _forEach(result.items, (item: UserNotification) => {
            this.notifications.push(item)
          });
        });
        this.utilsService.setUserName(this.utilsService.getLocalStorage("currentUserName"));

        this.jwtLoginService.getUserPermissions(this.utilsService.getTokenDecode()["user_identifier"]).subscribe(res => {
          this.isPageDone = true;
          this.utilsService.setPermissions(res.grantedPermissionNames);
        })
      })
    }
  }

  ngAfterViewInit(): void {
    if (window.location.href == environment.applicationRootUrl + "/") {
      window.location.href = "profil"
    }
    // this.utilsService.subject.subscribe((dreamJob: string) => {
    //   this.dreamJob = dreamJob;
    // })
  }

  backToMyProfile() {
    this.utilsService.removeLocalStorageItem("orphanId");
    this.utilsService.selectedOrphanId = 0;
    window.location.href = "/profil"

  }

  loadNotifications(): void {
    this.notificationService.getUserNotifications(undefined, undefined, undefined, 3, 0).subscribe((result) => {
      this.unreadNotificationCount = result.unreadCount;
      this.notifications = [];
      _forEach(result.items, (item: UserNotification) => {
      });
    });
  }

  addSlide() {
    this.slides.push({ img: "https://picsum.photos/200/300" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e: any) {
  }

  breakpoint(e: any) {
  }

  afterChange(e: any) {
  }

  beforeChange(e: any) {
  }

  joinEventCalendar(id: number, index: number) {
    let form = new FormGroup({
      eventCalendarDetailId: new FormControl(id),
      userId: new FormControl(this.utilsService.currentUserId),
    });
    this.eventCalendarJoinService.createOrEdit(form.value).subscribe(result => {
      if (result.message == "Ok") {
        this.utilsService.showMessage("Etkinliğe kayıt oldunuz");
        this.eventCalendarList[index].exists = true
        this.eventCalendarList[index].eventJoinId = result.data.id
      }
    })
  }


  delete(index: number, key: string, eventId: number) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let joinId = this.eventCalendarList[index].eventJoinId;
        this.eventCalendarJoinService.delete(joinId).subscribe(result => {

          this.eventCalendarJoinService.existEvent(this.utilsService.currentUserId, eventId).subscribe(response => {
            if (response === 0) {
              this.eventCalendarList[index].exists = false;
              this.eventCalendarList[index].eventJoinId = 0;
            }
          })

        })
      }
    })
  }
}
