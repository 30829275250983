import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { OrphanTaskModel, TaskViewModel } from 'src/app/models/orphan/task-info';
import { TaskCommentService } from 'src/app/services/orphan/task-comment.service';
import { TaskInfoService } from 'src/app/services/orphan/task-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { TaskInfoEditComponent } from '../task-info-edit/task-info-edit.component';

@Component({
  selector: 'app-task-info-detail',
  templateUrl: './task-info-detail.component.html',
  styleUrls: ['./task-info-detail.component.css']
})
export class TaskInfoDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: OrphanTaskModel;
  form: FormGroup;
  date: Date = new Date;

  constructor(
    public service: TaskInfoService,
    public commentService: TaskCommentService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Görevlerim");
  }

  ngOnInit(): void {
    let userId = this.utilsService.getTokenDecode()["user_identifier"];
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.form = this.formBuilder.group({
        id: null,
        commentText: [""],
        commentDate: [this.date],
        userId: [userId],
        orphanTaskId: [this.id]
      })
      this.service.getOrphanTaskForEdit(this.id).subscribe(result => {
        this.model = result;
        this.isPageDone = true;
      })
    })
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(TaskInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.utilsService.showMessage("İşlem başarılı");
      }
    });
  }

  onSubmitComment(): void {
    if (this.form.valid) {
      this.commentService.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
      })
    }
  }

}
