import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app-constants';
import { OrphanClubInfoModel } from 'src/app/models/orphan/club-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { PermissionOptions } from 'src/app/models/shared/permissionOptions.interface';
import { ClubInfoService } from 'src/app/services/orphan/club-info.service';
import { HasPermissionDirective } from 'src/app/services/permission/has-permissions.directive';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { ExperienceEditComponent } from '../experience/experience-edit/experience-edit.component';
import { ClubInfoDetailComponent } from './club-info-detail/club-info-detail.component';
import { ClubInfoEditComponent } from './club-info-edit/club-info-edit.component';

@Component({
  selector: 'app-club-info',
  templateUrl: './club-info.component.html'
})
export class ClubInfoComponent implements OnInit {

  model: OrphanClubInfoModel[];
  isUzmanKullanici: boolean = false;

  @ViewChildren(HasPermissionDirective) permissionDirective: QueryList<HasPermissionDirective>
  constructor(
    public service: ClubInfoService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Kulüp Bilgilerim");
  }


  ngOnInit(): void {

    this.reloadList();

    if(this.utilsService.currentUserRole == "uzmankullanıcı")
    {
      this.isUzmanKullanici = true;
    }
  }

  reloadList(): void {
    this.service.getOrphanClubAllWithOrphanId(this.utilsService.selectedOrphanId).subscribe(result => {
      this.model = result;
    });
  }


  edit(id: number): void {
    const dialogRef = this.dialog.open(ClubInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

  detail(id: number): void {
    const dialogRef = this.dialog.open(ClubInfoDetailComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

}
