import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AnnouncementDataRequestModel, AnnouncementModel } from './models/orphan/announcement';
import { EventModel } from './models/orphan/event-calendar';
import { NewsModel } from './models/orphan/news';
import { MenuModel } from './models/shared/menu';
import { ExternalLoginProvider, JwtTokenService } from './services/auth/jwt-token.service';
import { EventCalendarService, NewsService } from './services/orphan';
import { AnnouncementService } from './services/orphan/announcement.service';
import { UtilsService } from './services/utils/utils.service';
import { filter as _filter } from 'lodash-es';
import menus from '../assets/menu.json';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'yetim';
  isPageDone = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    private jwtLoginService: JwtTokenService
  ) {

  }

  ngOnInit(): void {

    var token = String(localStorage.getItem('access_token'));
    if (token != "null") {
    }
    else {

      // this.jwtLoginService.getToken().subscribe(() => {
      //   //   localStorage.setItem('access_token', result.result.accessToken);
      //   //   myRawToken = helper.decodeToken(result.result.accessToken);
      //   //   this.initData(myRawToken);
      // });
      this.jwtLoginService.initt(() => {
        let keycloakProvider = _filter(this.jwtLoginService.externalLoginProviders, {
          name: ExternalLoginProvider.KEYCLOAK,
        })[0];
        let queryParameters = document.location.href.split('?');
        if (queryParameters != undefined && queryParameters.length > 1 && queryParameters[1].split("state").length > 0 && queryParameters[1].split("state")[1].indexOf('keycload') >= 0) {
          let code = queryParameters[1].split("code=")[1].split("&")[0];
          let sessionState = queryParameters[1].split("session_state=")[1].split("&")[0];

          this.jwtLoginService.keycloakLoginCallback(code, sessionState);
          return;
        }
        this.jwtLoginService.externalAuthenticate(keycloakProvider);
      })
    }
  }
}
