import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app-constants';
import { LanguageLevels } from 'src/app/models/generic-base/custom-enum-model';
import { OrphanLanguageInfoModel } from 'src/app/models/orphan/language-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { LanguageInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { LanguageInfoEditComponent } from './language-info-edit/language-info-edit.component';

@Component({
  selector: 'app-language-info',
  templateUrl: './language-info.component.html'
})
export class LanguageInfoComponent implements OnInit {

  model: OrphanLanguageInfoModel[];

  constructor(
    public service: LanguageInfoService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Dil Bilgilerim");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.service.getOrphanLanguagesAllWithOrphanId(this.utilsService.selectedOrphanId).subscribe(result => {
      this.model = result;
    });
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(LanguageInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }

  getTaskTypeName(id: string): string {
    return LanguageLevels[id];
  }
}
