<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <div mat-dialog-actions align="end">
          <button type="button" class="close modal-close">
            <span (click)="onClose()" class="icon-modal-close"> </span>
          </button>
        </div>
      </div>

      <div class="modal-body">
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Yıl</span>
          </div>
          <div class="input">
            <input type="text" formControlName="year" />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Ad</span>
          </div>
          <div class="input">
            <input type="text" formControlName="name" />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Tamamlanma Durumu</span>
          </div>
          <div class="select">
            <select formControlName="projectStatus">
              <option value="">Seçiniz</option>
              <option [value]="item" *ngFor="let item of projectStatusSelect">
                {{ getProjectStatusName(item) }}
              </option>
            </select>
          </div>
        </div>
        <div class="btn-content">
          <div
            class="delete-btn"
            *hasPermission="['Pages.OrphanProjectDetails.Delete']"
          >
            <button
              type="button"
              *ngIf="model"
              (click)="delete(model.id, model.name)"
            >
              Projeyi Sil
            </button>
          </div>
          <div
            class="add-btn"
            *hasPermission="['Pages.OrphanProjectDetails.Edit']"
          >
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
