import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppConstants } from 'src/app-constants';
import { OrphanSchoolModel } from 'src/app/models/orphan/school-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { SchoolInfoService } from 'src/app/services/orphan/school-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { SchoolInfoEditComponent } from './school-info-edit/school-info-edit.component';

@Component({
  selector: 'app-school-info',
  templateUrl: './school-info.component.html'
})
export class SchoolInfoComponent implements OnInit {

  model: OrphanSchoolModel[];

  constructor(
    public service: SchoolInfoService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Öğrenim Bilgilerim");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    let queryString = "";
    if (this.utilsService.currentUserRole == "yetim" || this.utilsService.selectedOrphanId > 0) {
      queryString = "orphanId=" + this.utilsService.selectedOrphanId;
    }
    else {
      queryString = "userId=" + this.utilsService.currentUserId;
    }
    this.service.getOrphanSchoolAllWithOrphanId(queryString).subscribe(result => {
      this.model = result;
    });
  }

  downloadFile(binaryFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(binaryFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(SchoolInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.reloadList();
      }
    });
  }


}
