<div *ngIf="isPageDone" class="content read-book-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div class="new-item" *hasPermission="['Pages.OrphanBookDetails.Create']">
      <a (click)="edit(null)">
        <span>Yeni Ekle</span>
        <span class="icon-plus-circle-thin"></span>
      </a>
    </div>
  </div>
  <div class="scroll-content">
    <div class="read-book-content" *ngIf="model && model.length > 0">
      <div class="row">
        <div class="col-12 table-container">
          <div class="row">
            <div class="col-12 table-content">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Türü</th>
                      <th scope="col">Kitabın Adı</th>
                      <th scope="col">Yazarı</th>
                      <th scope="col" class="text-center">Sayfa Sayısı</th>
                      <th scope="col" class="text-center">Başlangıç - Bitiş</th>
                      <th scope="col" class="text-center">Düzenle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of model">
                      <td>{{ item.bookTypeName }}</td>
                      <td>{{ item.orphanBookDetail.bookName }}</td>
                      <td>{{ item.orphanBookDetail.bookAuthor }}</td>
                      <td class="text-center">
                        {{ item.orphanBookDetail.pageCount }}
                      </td>
                      <td>
                        {{
                          item.orphanBookDetail.startDate | date: "dd/MM/yyyy"
                        }}
                        -
                        {{ item.orphanBookDetail.endDate | date: "dd/MM/yyyy" }}
                      </td>
                      <td class="text-center edit-item p-0 mx-auto">
                        <!-- <a (click)="edit(item.orphanBookDetail.id)">
                          <span class="icon-edit"></span>
                        </a> -->
                        <a (click)="edit(item.orphanBookDetail.id)">
                          <span class="icon-edit icon"></span>
                          <span></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-paginator
        (page)="getServerData()"
        [length]="dataCount"
        [pageSize]="5"
        [pageSizeOptions]="[5, 10, 25, 100]"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
    <div class="no-content-text" *ngIf="!model || model.length <= 0">
      <h6>Görüntülenecek veri bulunamadı</h6>
    </div>
    <app-footer></app-footer>
  </div>
</div>
