import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanClubInfoViewModel } from 'src/app/models/orphan/club-info';
import { ClubInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-club-info-detail',
  templateUrl: './club-info-detail.component.html'
})
export class ClubInfoDetailComponent extends ModalViewComponent<ClubInfoDetailComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  model: OrphanClubInfoViewModel;
  isPageDone = false;
  isSubmitted = false;
  bookTypeSelect: DropdownModel[];

  constructor(
    private service: ClubInfoService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ClubInfoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    if (this.dialogData.id) {
      this.service.getOrphanClubDetailForEdit(this.dialogData.id).subscribe(result => {
        this.model = result.orphanClubDetail;
        this.isPageDone = true;
      })
    }
  }
}
