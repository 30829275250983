import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementModel, AnnouncementViewModel } from 'src/app/models/orphan/announcement';
import { AnnouncementService } from 'src/app/services/orphan/announcement.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html'
})
export class AnnouncementDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: AnnouncementViewModel;

  constructor(private service: AnnouncementService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService) {
    this.utilsService.setTitle("Duyuru Detay");
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.service.getAnnouncement(this.id).subscribe(result => {
        this.model = result;
        this.isPageDone = true;
      })
    })
  }

}
