import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanSchoolModel, SchoolInfoViewModel } from 'src/app/models/orphan/school-info';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolInfoService extends GenericCrudService<OrphanSchoolModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanSchools")
  }

  getOrphanSchoolAllWithOrphanId(queryString: string): Observable<OrphanSchoolModel[]> {
    return this.http.get<OrphanSchoolModel[]>(this.baseUrl + "/GetOrphanSchoolAllWithOrphanId?" + queryString);
  }

  getOrphanSchoolForEdit(id: number): Observable<OrphanSchoolModel> {
    return this.http.get<OrphanSchoolModel>(this.baseUrl + "/GetOrphanSchoolForEdit?id=" + id);
  }

  getAllProvinceForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllProvinceForTableDropdown");
  }

  getAllEducationTypeForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllEducationTypeForTableDropdown");
  }

  getAllAvailabilityStatusForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllAvailabilityStatusForTableDropdown");
  }

  downloadBinaryFile(binaryFileId: string): Observable<Blob> {
    return this.http.get(environment.apiRoot + "/File/DownloadBinaryFile?id=" + binaryFileId, { responseType: "blob" })
  }

  removeCatalogImageFile(id: number): Observable<any> {
    return this.http.delete<any[]>(this.baseUrl + "/RemoveCatalogImageFile?Id=" + id);
  }
}
