<div *ngIf="isPageDone">
  <div mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button type="button" class="close modal-close">
        <span (click)="onClose()" class="icon-modal-close"> </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="content">
        <p>
          {{ model.description }}
        </p>
      </div>
    </div>
  </div>
</div>
