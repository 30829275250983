import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

constructor(private router: Router){

}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].includes(err.status)) {
          console.log(err.status)
          if (err.status === 401) {
             localStorage.clear();
             this.router.navigate(['/'])
             
          }
        }
        console.log(err.status)

        return throwError(() => err);
      })
    );
  }
}
