import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { AnnouncementModel, AnnouncementViewModel } from 'src/app/models/orphan/announcement';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends GenericCrudService<AnnouncementModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/Announcements")
  }

  getActiveAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetActiveAll?" + params);
  }

  getAnnouncement(id: number): Observable<AnnouncementViewModel> {
    return this.http.get<AnnouncementViewModel>(this.baseUrl + "/GetAnnouncementForView?id=" + id);
  }
}
