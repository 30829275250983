import { DataRequestModel } from "../generic-base/data-model";

export class OdemeDurumBilgisiModel {
  result: OdemeResultViewModel[];
  mesaj: string;
}

export class OdemeRequestViewModel {
  TCKN: string;
  MDG_ID: string;
  ANA_BELGE_NO: string;
  BELGE_NO: string;
}

export class OdemeResultViewModel {
  PARA_BIRIMI: string;
  TUTAR: string;
  PLANLAMA_TARIHI: string;
  DURUM: string;
  PERIYOT: string;
  KACINCI_ODEME: string;
  TESLIMAT_TARIHI: Date;
  BANKAYA_GONDERIM_TARIHI: Date;
  PROJE_KODU: Date;
  BUTCE_KODU: number;
  PROJE_KODU_TEXT_ADI: number;
  BUTCE_KODU_TEXT_ADI: string;
  YONLENDIREN_SUBE_KODU: string;
  SUBE_ORGANIZASYON_KODU: string;
  DAGITIM_ARACI: string;
  ISBIRLIKCI_KURUM: number;
  IHTIYAC_TIPI_KODU: string;
}
