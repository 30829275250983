import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { OrphanRoutingModule } from './orphan-routing.module';
import { SchoolInfoComponent } from './school-info/school-info.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { TakenEducationComponent } from './taken-education/taken-education.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './profile/profile.component';
import { TakeEducationEditComponent } from './taken-education/take-education-edit/take-education-edit.component';
import { SchoolInfoEditComponent } from './school-info/school-info-edit/school-info-edit.component';
import { ExperienceComponent } from './experience/experience.component';
import { ExperienceEditComponent } from './experience/experience-edit/experience-edit.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { ProjectInfoDetailComponent } from './project-info/project-info-detail/project-info-detail.component';
import { TaskInfoComponent } from './task-info/task-info.component';
import { TaskInfoEditComponent } from './task-info/task-info-edit/task-info-edit.component';
import { TaskInfoDetailComponent } from './task-info/task-info-detail/task-info-detail.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { NewsComponent } from './news/news.component';
import { AnnouncementDetailComponent } from './announcement/announcement-detail/announcement-detail.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { ProjectInfoEditComponent } from './project-info/project-info-edit/project-info-edit.component';
import { WorkJobComponent } from './work-job/work-job.component';
import { WorkJobDetailComponent } from './work-job/work-job-detail/work-job-detail.component';
import { JobApplicationEditComponent } from './work-job/job-application-edit/job-application-edit.component';
import { ReadBookComponent } from './read-book/read-book.component';
import { ReadBookEditComponent } from './read-book/read-book-edit/read-book-edit.component';
import { LanguageInfoComponent } from './language-info/language-info.component';
import { LanguageInfoEditComponent } from './language-info/language-info-edit/language-info-edit.component';
import { ClubInfoComponent } from './club-info/club-info.component';
import { ClubInfoEditComponent } from './club-info/club-info-edit/club-info-edit.component';
import { ClubInfoDetailComponent } from './club-info/club-info-detail/club-info-detail.component';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { EventCalendarDetailComponent } from './event-calendar/event-calendar-detail/event-calendar-detail.component';
import { CertificateFileAddComponent } from './experience/certificate-file-add/certificate-file-add.component';
import { EventCalendarListComponent } from './event-calendar/event-calendar-list/event-calendar-list.component';
import { EventCalendarListDetailComponent } from './event-calendar/event-calendar-list-detail/event-calendar-list-detail.component';
import { UserNotificationPermissionComponent } from './user-notification-permission/user-notification-permission.component';
import { AyniNakdiYardimComponent } from './ayni-nakdi-yardim/ayni-nakdi-yardim.component';
import { GonulluOlComponent } from './gonullu-ol/gonullu-ol.component';
import { HasPermissionDirective } from 'src/app/services/permission/has-permissions.directive';
import { TextMaskModule } from 'angular2-text-mask';
import { ChatComponent } from './chat/chat.component';
import { ChatCreateGroupComponent } from './chat/chat-create-group/chat-create-group.component';
import { FooterComponent } from '../shared/footer/footer.component';
import { ListOrphanComponent } from './list-orphan/list-orphan.component';
import { OnlineCourseComponent } from './online-course/online-course.component';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
])

@NgModule({
  declarations: [
    PersonalInfoComponent,
    ProfileComponent,
    PersonalInfoComponent,
    SchoolInfoComponent,
    TakenEducationComponent,
    ExperienceComponent,
    ExperienceEditComponent,
    TakeEducationEditComponent,
    SchoolInfoEditComponent,
    ProjectInfoComponent,
    ProjectInfoDetailComponent,
    TaskInfoComponent,
    TaskInfoEditComponent,
    TaskInfoDetailComponent,
    AnnouncementComponent,
    NewsComponent,
    AnnouncementDetailComponent,
    NewsDetailComponent,
    ProjectInfoEditComponent,
    WorkJobComponent,
    WorkJobDetailComponent,
    JobApplicationEditComponent,
    ReadBookComponent,
    ReadBookEditComponent,
    LanguageInfoComponent,
    LanguageInfoEditComponent,
    ClubInfoComponent,
    ClubInfoEditComponent,
    ClubInfoDetailComponent,
    EventCalendarComponent,
    EventCalendarDetailComponent,
    CertificateFileAddComponent,
    EventCalendarListComponent,
    EventCalendarListDetailComponent,
    UserNotificationPermissionComponent,
    AyniNakdiYardimComponent,
    GonulluOlComponent,
    ChatComponent,
    HasPermissionDirective,
    ChatCreateGroupComponent,
    FooterComponent,
    ListOrphanComponent,
    OnlineCourseComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    OrphanRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    SlickCarouselModule,
    TextMaskModule
  ],
  exports: [
    HasPermissionDirective
  ],
  providers: [
    DatePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class OrphanModule { }
