import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { HeaderNotificationsComponent } from './components/shared/notifications/header-notifications.component';
import { CareerSpecialistFormComponent } from './components/yonetici/career-specialist-form/career-specialist-form.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent
  },
  // {
  //   path: 'kizilaykaptanyetim.demo.nuevoyazilim.com',
  //   component: MainLayoutComponent
  // },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'kariyer-basvuru-formu',
    component: CareerSpecialistFormComponent
  },
  {
    path: 'not',
    component: HeaderNotificationsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
