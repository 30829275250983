import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { GetOrphanFileForViewDto } from 'src/app/models/orphan/file';
import { TaskViewModel } from 'src/app/models/orphan/task-info';
import { FileService } from 'src/app/services/orphan/file.service';
import { TaskInfoService } from 'src/app/services/orphan/task-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-task-info-edit',
  templateUrl: './task-info-edit.component.html',
  styleUrls: ['./task-info-edit.component.css']
})
export class TaskInfoEditComponent extends ModalViewComponent<TaskInfoEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  model: TaskViewModel;
  form: FormGroup;
  isEdit = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;
  taskTypeSelect: DropdownModel[];
  orphanFiles: GetOrphanFileForViewDto[] = [];

  constructor(
    private service: TaskInfoService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TaskInfoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      subject: ["", Validators.required],
      description: ["", Validators.required],
      askQuestion: [false],
      question: [""],
      questionAnswer: [""],
      pointValue: [""],
      isCompleted: [false],
      completedDate: [""],
      targetUserId: [""],
      orphanTaskTypeId: ["", Validators.required],
      targetCompletedDate: ["", Validators.required],
      batchTask: [""],
      batchWorkTaskReques: [],
      orphanId: [this.utilsService.selectedOrphanId, Validators.required],
      createOrEditOrphanFileDto: null
    })

    const taskTypePromise$ = this.service.getAllOrphanTaskTypeForTableDropdown().toPromise();
    Promise.all([taskTypePromise$]).then(response => {
      this.taskTypeSelect = response[0];
    }).then(() => {
      if (this.dialogData.id) {
        this.service.getOrphanTaskForEdit(this.dialogData.id).subscribe(result => {
          this.model = result.orphanTask;
          this.form.patchValue(this.model);
          // this.form.controls.isCompleted.setValidators(Validators.required);
          // this.fileService.getFilesWithReferenceNumber(result.orphanTask.referanceNumber).subscribe(response => {
          //   this.orphanFiles = response;
          // })
        })
      }
      this.form.patchValue(this.model);
      this.isPageDone = true;

    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.form.controls.batchTask.setValue(true)
      if (this.dialogData.orphanId) {
        this.form.controls.orphanId.setValue(this.dialogData.orphanId)
      }
      this.form.controls.batchWorkTaskReques.setValue({ regionId: null, provinceId: null, ageLimitId: null, roleId: null, userId: this.dialogData.orphanId ? this.dialogData.orphanId : this.utilsService.selectedOrphanId })
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
        }
        this.dialogRef.close(this.form.value);
      })
    }
  }
}
