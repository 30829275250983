import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrphanViewDto, ProfileModel,OrphanDreamJobAndCarrierPlanInfoDto } from 'src/app/models/orphan/profile';
import { GenericCrudService } from '../generic-base/generic-crud.service';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  baseUrl = environment.apiRoot + "/api/services";

  constructor(
    public http: HttpClient
  ) { }

  getOrphan(id: number): Observable<OrphanViewDto> {
    return this.http.get<OrphanViewDto>(this.baseUrl + "/app/Orphans/GetOrphanForView?id=" + id);
  }

  createOrEdit(body: OrphanViewDto): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/app/Orphans/CreateOrEdit", body);
  }

  updateDreamJobAndCareerPlanInfo(body: OrphanDreamJobAndCarrierPlanInfoDto): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/app/Orphans/UpdateDreamJobAndCareerPlanInfo", body);
  }

  downloadBinaryFile(binaryFileId: string): Observable<Blob> {
    return this.http.get(environment.apiRoot + "/File/DownloadBinaryFile?id=" + binaryFileId, { responseType: "blob" })
  }

  removeCatalogImageFile(id: number): Observable<any> {
    return this.http.delete<any[]>(this.baseUrl + "/app/Orphans/RemoveCatalogImageFile?Id=" + id);
  }

  validateTcNumber(body: OrphanViewDto): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/app/KizilayMernis/ValidateTcNumber", body);
  }

  updateProfilePicture(body: any) {
    return this.http.put<any>(this.baseUrl + "/app/Profile/UpdateProfilePicture", body, { observe: 'response', responseType: 'blob' as 'json' });
  }

  updateOrphanProfilePicture(body: any, userId: number) {
    return this.http.put<any>(this.baseUrl + "/app/Orphans/UpdateProfilePicture?userId="+userId, body, { observe: 'response', responseType: 'blob' as 'json' })
  }
}
