import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GonulluOlResultModel } from 'src/app/models/orphan';
import { OrphanViewDto, OrphanDto } from 'src/app/models/orphan/profile';
import { KizilayGonulluOlService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProfileService } from 'src/app/services/orphan/profile.service';

@Component({
  selector: 'app-gonullu-ol',
  templateUrl: './gonullu-ol.component.html',
  styleUrls: ['./gonullu-ol.component.css']
})
export class GonulluOlComponent implements OnInit {

  model = new GonulluOlResultModel;
  muhattapNo : string;
  modelOrphanDto: OrphanViewDto;
  modelOrphan: OrphanDto;
  isResult: boolean = false;

  constructor(
    public service: ProfileService,
    public activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    private gonullOlService: KizilayGonulluOlService
  ) {

  }

  ngOnInit(): void {
    this.service.getOrphan(this.utilsService.selectedOrphanId).subscribe(result => {
      this.modelOrphanDto = result;
      this.modelOrphan = result.orphan;

      if(this.modelOrphan.mdgMuhatapNo != "" || this.modelOrphan.mdgMuhatapNo != null ||this.modelOrphan.mdgMuhatapNo != undefined)
      {
        this.gonullOlService.getEvents(this.modelOrphan.mdgMuhatapNo).subscribe(result => {
          if (result["result"] !== null) {
            this.model.volunteerLevelPoints = result["result"].volunteerLevelPoints;
            this.model.totalNumOfAttendedEvents = result["result"].totalNumOfAttendedEvents;
            this.model.taskList = result["result"].taskList;
            this.isResult = true;
          }
        })
      }
    });
  }
}
