<div *ngIf="isPageDone" class="content news-page sub-page-content">
  <div class="news-content scroll-content" *ngIf="model && model.length > 0">
    <div class="row row-gap-30">
      <ng-container *ngFor="let item of model">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div class="item">
            <div class="item-body">
              <div class="img">
                <img
                  *ngIf="
                    item?.newsAndAnnouncement?.catalogImageFileName &&
                    item.newsAndAnnouncement.catalogImageFileName.length > 0
                  "
                  src="{{
                    getDownloadUrl(item.newsAndAnnouncement.catalogImage)
                  }}"
                  alt=""
                />
                <img
                  *ngIf="!item?.newsAndAnnouncement?.catalogImageFileName"
                  src="../../../../assets/images/haber-default.png"
                  alt=""
                />
              </div>
              <div class="date">
                <span>{{
                  item.newsAndAnnouncement.publishDate | date: "dd/MM/yyyy"
                }}</span>
              </div>
              <div class="title">
                <span>{{ item.newsAndAnnouncement.headerText }}</span>
              </div>
            </div>
            <div class="item-footer">
              <div class="task-detail">
                <a
                  [routerLink]="[
                    '/haberler-detay/' + item.newsAndAnnouncement.id
                  ]"
                >
                  <span class="text">Detay</span>
                  <span class="icon icon-brush-right-arrow"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <mat-paginator
      (page)="getServerData()"
      [length]="dataCount"
      [pageSize]="6"
      [pageSizeOptions]="[6, 12, 30, 120]"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
  <div *ngIf="!model || model.length <= 0">
    <h6>Görüntülenecek veri bulunamadı</h6>
  </div>
  <app-footer></app-footer>
</div>
