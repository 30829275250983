<div *ngIf="isPageDone">
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="user-notification-permission"
  >
    <div class="title">
      <span>E-mail Bildirim İzinleri</span>
    </div>
    <section class="notification-permission">
      <span class="permission-list">
        <ul>
          <li>
            <mat-checkbox formControlName="taskEMail"> Görevler </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="newEMail"> Haberler </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="announcementEMail"
              >Duyuru
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="jobEMail">
              İş İlanları
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="eventEMail">Etkinlik </mat-checkbox>
          </li>
        </ul>
      </span>
    </section>
    <hr style="border-top: 1px solid rgba(0, 0, 0, 0.1)" />
    <div class="title mt-3">
      <span>Sms Bildirim İzinleri</span>
    </div>
    <section class="notification-permission">
      <span class="permission-list">
        <ul>
          <li>
            <mat-checkbox formControlName="taskSms"> Görevler </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="newSms"> Haberler </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="announcementSms"
              >Duyuru
            </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="jobSms"> İş İlanları </mat-checkbox>
          </li>
          <li>
            <mat-checkbox formControlName="eventSms">Etkinlik </mat-checkbox>
          </li>
        </ul>
      </span>
      <div class="permission-save">
        <button class="add">Kaydet</button>
      </div>
    </section>
  </form>
</div>
