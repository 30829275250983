import { Component, OnInit } from '@angular/core';
import { ApplicationCourseModel, OnlineCourseModel } from 'src/app/models/orphan';
import { OnlineCourseService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-online-course',
  templateUrl: './online-course.component.html',
  styleUrls: ['./online-course.component.css']
})
export class OnlineCourseComponent implements OnInit {

  model: OnlineCourseModel[] = []
  applicationCourseModel: OnlineCourseModel[] = [];
  deletedCourseModel: OnlineCourseModel[] = []; // Başvuru yapılmış olanlar.


  constructor(
    private service: OnlineCourseService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.service.getCourses().subscribe(result => {
      this.model = result.result;
      this.service.getEnrolCourses(this.utilsService.currentUserId).subscribe(response => {
        this.applicationCourseModel = response.result;

        if (this.model && this.applicationCourseModel.length > 0) {
          this.model = this.model.filter(p => !this.applicationCourseModel.includes(this.applicationCourseModel.find(c => c.id == p.id)));
        }

        //this.model = this.model.sort((a, b) => (a.startdate > b.startdate ? -1 : 1))


      })
    })
  }

  enrolCourse(courseId: number) {
    this.service.enrolCourseKaptanUser(this.utilsService.currentUserId, courseId).subscribe(result => {
      this.deletedCourseModel.push(this.model.find(p => p.id == courseId));
      this.model.splice(this.model.findIndex(p => p.id == courseId), 1);
    })
  }

  unEnrolCourse(courseId: number) {
    this.service.unEnrolCourseKaptanUser(this.utilsService.currentUserId, courseId).subscribe(result => {
      if (result.success === true) {
        this.model.push(this.deletedCourseModel.find(p => p.id == courseId));
        this.deletedCourseModel.splice(this.deletedCourseModel.findIndex(p => p.id == courseId), 1);
        this.model = this.model.sort((a, b) => (a.startdate > b.startdate ? -1 : 1));
        this.getUserCourses();
      }
    })
  }

  getUserCourses(): void {
    this.service.getEnrolCourses(this.utilsService.currentUserId).subscribe(result => {
      this.applicationCourseModel = result.result;
      this.applicationCourseModel = this.applicationCourseModel.sort((a, b) => (a.startdate > b.startdate ? -1 : 1));
    })
  }

}
