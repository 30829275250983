import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Flight, ListOrphanModel } from 'src/app/models/manager/list-orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ListOrphanService extends GenericCrudService<ListOrphanModel, number>{

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services");
  }

  getOrphans(): Observable<any> {
    return this.http.get(this.baseUrl + "/app/Orphans/GetAllWithManager?MaxResultCount=10");
  }

  public getFlightsData(): Observable<Flight[]> {
    let url = "assets/data.json";
    return this.http.get<Flight[]>(url);
  }
}
