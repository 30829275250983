<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-title">
          <span *ngIf="!model">İş Tecrübesi Ekle</span>
          <span *ngIf="model">İş Tecrübe Düzenle</span>
        </div>
        <div class="row flex-column">
          <div class="col-lg-12 mb-4">
            <div class="default-input-group">
              <div class="title">
                <span>İş Türü</span>
              </div>
              <div class="select">
                <select formControlName="taskType">
                  <option value="">Seçiniz</option>
                  <option [value]="item" *ngFor="let item of taskTypeSelect">
                    {{ getTaskTypeName(item) }}
                  </option>
                </select>
                <span class="text-danger" *ngIf="form.get('taskType').errors && 
                    form.get('taskType').hasError('required')">* Bu Alan Zorunludur</span>
                <div class="icon">
                  <span class="icon-down-thin"></span>
                </div>
              </div>
            </div>


          </div>
          <div class="col-lg-12 mb-4">
            <div class="default-input-group">
              <div class="title">
                <span>Çalıştığın Pozisyon</span>
              </div>
              <div class="input">
                <input type="text" formControlName="companyPosition" />
                <span class="text-danger" *ngIf="form.get('companyPosition').errors && 
                    form.get('companyPosition').hasError('required')">* Bu Alan Zorunludur</span>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <div class="default-input-group">
              <div class="title">
                <span>Firma Adı</span>
              </div>
              <div class="input">
                <input type="text" formControlName="companyName" />
                <span class="text-danger" *ngIf="form.get('companyName').errors && 
                    form.get('companyName').hasError('required')">* Bu Alan Zorunludur</span>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <div class="row row-gap-xs-20">
              <div class="col-lg-6 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Çalışma Türü</span>
                  </div>
                  <div class="select">
                    <select formControlName="oprhanWorkType">
                      <option value="">Seçiniz</option>
                      <option [value]="item" *ngFor="let item of workTypeSelect">
                        {{ getWorkTypeName(item) }}
                      </option>
                    </select>
                    <span class="text-danger" *ngIf="form.get('oprhanWorkType').errors && 
                    form.get('oprhanWorkType').hasError('required')">* Bu Alan Zorunludur</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="default-input-group">
                  <div class="title">
                    <span>Sektörü</span>
                  </div>
                  <div class="input">
                    <input type="text" formControlName="companySector" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <div class="default-input-group">
              <div class="title">
                <span>Lokasyon</span>
              </div>
              <div class="select">
                <select formControlName="companyProvinceId">
                  <option value="">Seçiniz</option>
                  <option [value]="item.id" *ngFor="let item of provinceSelect">
                    {{ item.displayName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <div class="row row-gap-xs-20">
              <div class="col-lg-6 mb-4">
                <div class="default-input-group">
                  <div class="title">
                    <span>Başlama Tarihi</span>
                  </div>
                  <div class="input">
                    <input type="date" #startDate formControlName="startDate" value="{{
                        this.form.controls.startDate.value | date: 'yyyy-MM-dd'
                      }}" max="{{ this.todayDateFormatted }}"/>
                      <span class="text-danger" *ngIf="form.get('startDate').errors && 
                    form.get('startDate').hasError('required')">* Bu Alan Zorunludur</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="default-input-group">
                  <div class="title">
                    <span>Ayrılış Tarihi</span>
                  </div>
                  <div class="input">
                    <input type="date" #endDate formControlName="endDate" value="{{
                        this.form.controls.endDate.value | date: 'yyyy-MM-dd'
                      }}" max="{{ this.todayDateFormatted }}"/>
                      <span class="text-danger" *ngIf="form.get('endDate').errors && 
                      form.get('endDate').hasError('required')">* Bu Alan Zorunludur</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mb-4">
            <div class="default-input-group">
              <div class="title">
                <span>Açıklama</span>
              </div>
              <div class="textarea">
                <textarea formControlName="description"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-content">
        <div class="delete-btn" *hasPermission="['Pages.OrphanExperiences.Delete']">
          <button type="button" *ngIf="model" (click)="
              delete(
                model.orphanExperience.id,
                model.orphanExperience.companyName
              )
            ">
            Kaydı Sil
          </button>
        </div>
        <div class="add-btn">
          <button>Kaydet</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <h2 mat-dialog-title>Install Angular</h2>

<h3>DEVELOP ACROSS ALL PLATFORMS</h3>
<p>
  Learn one way to build applications with Angular and reuse your code and
  abilities to build apps for any deployment target. For web, mobile web, native
  mobile and native desktop.
</p>

<h3>SPEED & PERFORMANCE</h3>
<p>
  Achieve the maximum speed possible on the Web Platform today, and take it
  further, via Web Workers and server-side rendering. Angular puts you in
  control over scalability. Meet huge data requirements by building data models
  on RxJS, Immutable.js or another push-model.
</p>

<h3>INCREDIBLE TOOLING</h3>
<p>
  Build features quickly with simple, declarative templates. Extend the template
  language with your own components and use a wide array of existing components.
  Get immediate Angular-specific help and feedback with nearly every IDE and
  editor. All this comes together so you can focus on building amazing apps
  rather than trying to make the code work.
</p>

<h3>LOVED BY MILLIONS</h3>
<p>
  From prototype through global deployment, Angular delivers the productivity
  and scalable infrastructure that supports Google's largest applications.
</p>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" tabindex="1">Install</button>
  <button mat-button mat-dialog-close tabindex="-1">Cancel</button>
</mat-dialog-actions> -->