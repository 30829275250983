import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { TaskTypes, WorkTypes } from 'src/app/models/generic-base/custom-enum-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { OrphanViewDto } from 'src/app/models/orphan';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { ExperienceService, FileService, ProfileService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-certificate-file-add',
  templateUrl: './certificate-file-add.component.html'
})
export class CertificateFileAddComponent extends ModalViewComponent<CertificateFileAddComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  isPageDone = false;
  isSubmitted = false;
  orphanModel: OrphanViewDto;
  formData: FormData = new FormData();
  selectedFileName: string;
  todayDateFormatted: String;

  constructor(
    private service: ExperienceService,
    private fileService: FileService,
    public profileService: ProfileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<CertificateFileAddComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: 0,
      nationality: [""],
      mdgMuhatapNo: [""],
      tcNumber: [""],
      firstName: [""],
      lastName: [""],
      placeOfBirth: [""],
      dateOfBirth: [""],
      motherAndFatherName: [""],
      address: [""],
      apartmentNumber: [""],
      postCode: [""],
      mobileNumber: [""],
      homeNumber: [""],
      eMail: [""],
      maritalStatus: [""],
      provinceId: [""],
      districtId: [""],
      neighborhoodId: [""],
      certificateFileToken: ["", Validators.required],
      certificateDateOfIssue:["", Validators.required]
    });
    this.profileService.getOrphan(this.utilsService.selectedOrphanId).subscribe(result => {
      this.orphanModel = result;
      this.isPageDone = true;

      this.form.patchValue(this.orphanModel.orphan);
    })
    this.todayDateFormatted= this.datepipe.transform(new Date(),'yyyy-MM-dd');
  }

  onSubmit():void{
    if (this.form.valid) {
      this.profileService.createOrEdit(this.form.value).subscribe(result => {
        this.utilsService.showMessage("İşlem başarılı");
        this.dialogRef.close();
      })
    }
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      let selectedFile = <File>event.target.files[0];
      this.formData.append(selectedFile.name, selectedFile);
      this.selectedFileName = selectedFile.name;
      
      this.fileService.uploadCatalogImageFile("/Orphan/UploadcatalogImageFile", this.formData).subscribe(res => {
        this.form.controls.certificateFileToken.setValue(res.result.fileToken)
      })
    }
  }

  deleteFile(id: number, key: string, index: number) {
    const dialogData: DialogDataModel = {
      title: "Dosya Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.profileService.removeCatalogImageFile(id).subscribe(response => {
          // this.model.binaryFileName = null;
        })
      }
    })
  }

}
