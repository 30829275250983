<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Eğitim Adı</span>
          </div>
          <div class="input">
            <input
              type="text"
              cdkFocusInitial
              formControlName="educationName"
            />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Eğitim Tarihi</span>
          </div>
          <div class="input mb-015rem">
            <input
              type="date"
              #educationDate
              formControlName="educationDate"
              value="{{
                this.form.controls.educationDate.value | date: 'yyyy-MM-dd'
              }}"
            />
          </div>

          <div class="default-input-group mb-4">
            <div class="title">
              <span>Süresi</span>
            </div>
            <div class="input">
              <input type="text" formControlName="educationTime" />
            </div>
          </div>
          <div class="default-input-group mb-4">
            <div class="title">
              <span>Puanı</span>
            </div>
            <div class="input">
              <input type="text" formControlName="pointValue" />
            </div>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Açıklama</span>
          </div>
          <div class="textarea">
            <textarea class="textarea" formControlName="description">
 Loroipsum Dolor Sit Amed Loroipsum Dolor Loroipsum Dolor</textarea
            >
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Dosya</span>
          </div>
          <div class="input">
            <fieldset class="dc-fieldset" *ngIf="!model?.binaryFileName">
              <input
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <div class="file-upload">
                Dosya Yükleyin
                <button
                  mat-mini-fab
                  color="primary"
                  type="button"
                  class="upload-btn"
                  (click)="fileUpload.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
              <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
              &nbsp;&nbsp;
              <a
                (click)="removeSelectedFile()"
                style="color: red"
                *ngIf="
                  selectedFileName &&
                  (!model?.binaryFileName || !model?.binaryFileId)
                "
                >Seçili Dosyayı Sil</a
              >
            </fieldset>
            <ng-container *ngIf="model?.binaryFileName">
              <a
                (click)="
                  downloadFile(
                    model?.orphanEducation.binaryFileId,
                    model?.binaryFileName
                  )
                "
                mat-button
              >
                {{ model.binaryFileName }}
                <mat-icon color="primary">download</mat-icon>
              </a>

              <a
                *hasPermission="['Pages.OrphanFiles.Delete']"
                (click)="
                  deleteFile(model.orphanEducation.id, model.binaryFileName, i)
                "
                mat-button
              >
                Sil
                <mat-icon color="accent">delete</mat-icon>
              </a>
            </ng-container>
          </div>
        </div>
        <div class="btn-content">
          <div
            class="delete-btn"
            *hasPermission="['Pages.OrphanEducations.Delete']"
          >
            <button
              type="button"
              *ngIf="model"
              (click)="
                delete(model.orphanEducation.id, model.orphanEducation.name)
              "
            >
              Eğitimi Sil
            </button>
          </div>
          <div class="add-btn" *hasPermission="['Pages.OrphanEducations.Edit']">
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
