<div *ngIf="isPageDone" class="content chat-page sub-page-content">
  <div class="fixed-area"></div>
  <div class="scroll-content overflow-hidden">
    <div class="chat-content">
      <div class="row h-100">
        <div class="col-3 user-list-content">
          <div class="new-room">
            <a (click)="edit(null)">Mesaj Oluştur</a>
          </div>
          <div class="user-list">
            <div class="user" *ngFor="let item of chatListModel">
              <a (click)="getMessageContent(item.messageInbox.id)">
                <div class="username">
                  <span>{{ item.messageInbox.groupOrChatName }}</span>
                </div>
                <div class="messages">
                  <span
                    title="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum"
                  ></span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-9 message-detail-content">
          <div class="message-detail">
            <div class="message-list" *ngIf="chatContentModel.length > 0">
              <ng-container *ngFor="let item of chatContentModel">
                <div
                  [ngClass]="
                    item.userId == utilsService.currentUserId
                      ? 'comment outgoing'
                      : 'comment'
                  "
                >
                  <div class="profile-photo">
                    <img src="../assets/images/userDefault.png" alt="" />
                  </div>
                  <div class="comment-content col-lg-8 col-md-10 col-sm-10">
                    <div class="content-title">
                      <span class="name">{{ item?.userName }}</span>
                      <span class="date">01.01.2022</span>
                    </div>
                    <div class="content-description">
                      <p>{{ item?.messageContent.messageContentText }}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div *ngIf="chatContentModel.length <= 0">
              <h6 style="text-align: center">
                Yeni mesaj oluşturabilir veya var olan sohbetinizi seçerek devam
                edebilirsiniz.
              </h6>
            </div>
            <form
              *ngIf="selectedMessageInboxId > 0"
              [formGroup]="form"
              (ngSubmit)="onSubmitComment()"
            >
              <div class="message-send">
                <div class="message">
                  <textarea
                    formControlName="messageContentText"
                    placeholder="Mesaj yaz..."
                  ></textarea>
                </div>
                <div class="send-btn">
                  <a (click)="onSubmitComment()">Gönder</a>
                </div>
                <div class="send-btn">
                  <a (click)="getMessageContent(selectedMessageInboxId)"
                    >Konuşmayı Yenile</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
