import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {
  AnnouncementComponent,
  AnnouncementDetailComponent,
  ClubInfoComponent,
  EventCalendarComponent,
  EventCalendarListComponent,
  ExperienceComponent,
  LanguageInfoComponent,
  NewsComponent,
  NewsDetailComponent,
  PersonalInfoComponent,
  ProfileComponent,
  ProjectInfoComponent,
  ProjectInfoDetailComponent,
  ReadBookComponent,
  SchoolInfoComponent,
  TakenEducationComponent,
  TaskInfoComponent,
  TaskInfoDetailComponent,
  WorkJobComponent,
  WorkJobDetailComponent,
  EventCalendarListDetailComponent,
  UserNotificationPermissionComponent
} from '.';
import { AyniNakdiYardimComponent } from './ayni-nakdi-yardim/ayni-nakdi-yardim.component';
import { MainLayoutComponent } from '../main-layout/main-layout.component';
import { HeaderNotificationsComponent } from '../shared/notifications/header-notifications.component';
import { GonulluOlComponent } from './gonullu-ol/gonullu-ol.component';
import { ChatComponent } from './chat/chat.component';
import { ListOrphanComponent } from './list-orphan/list-orphan.component';
import { OnlineCourseComponent } from './online-course/online-course.component';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent, children: [
      {
        path: 'profil',
        component: ProfileComponent
      },
      {
        path: 'etkinlik-takvimi',
        component: EventCalendarComponent
      },
      {
        path: 'kisisel-bilgilerim',
        component: PersonalInfoComponent,
        pathMatch: 'full'
      },
      {
        path: 'ogrenim-bilgilerim',
        component: SchoolInfoComponent,
        pathMatch: 'full',
      },
      {
        path: 'aldigim-egitimler',
        component: TakenEducationComponent,
        pathMatch: 'full',
        data: { hasPermission: 'Pages.asdasd' },
      },
      {
        path: 'kariyer-bilgilerim',
        component: ExperienceComponent,
        pathMatch: 'full'
      },
      {
        path: 'projelerim',
        component: ProjectInfoComponent
      },
      {
        path: 'projelerim-detay/:id',
        component: ProjectInfoDetailComponent
      },
      {
        path: 'gorevlerim',
        component: TaskInfoComponent
      },
      {
        path: 'gorevlerim-detay/:id',
        component: TaskInfoDetailComponent
      },
      {
        path: 'duyurular',
        component: AnnouncementComponent
      },
      {
        path: 'duyurular-detay/:id',
        component: AnnouncementDetailComponent
      },
      {
        path: 'haberler',
        component: NewsComponent
      },
      {
        path: 'haberler-detay/:id',
        component: NewsDetailComponent
      },
      {
        path: 'is-ilanlari',
        component: WorkJobComponent
      },
      {
        path: 'is-ilanlari-detay/:id',
        component: WorkJobDetailComponent
      },
      {
        path: 'okudugum-kitaplar',
        component: ReadBookComponent
      },
      {
        path: 'dil-bilgilerim',
        component: LanguageInfoComponent
      },
      {
        path: 'kulup-bilgilerim',
        component: ClubInfoComponent
      },
      {
        path: 'etkinlik-takvimi-liste',
        component: EventCalendarListComponent
      },
      {
        path: 'etkinlik-takvimi-detay/:id',
        component: EventCalendarListDetailComponent
      },
      {
        path: 'kullanici-izin',
        component: UserNotificationPermissionComponent
      },
      {
        path: 'ayni-nakdi-yardimlar',
        component: AyniNakdiYardimComponent
      },
      {
        path: 'tum-bildirimler',
        component: HeaderNotificationsComponent
      },
      {
        path: 'gonulluluk-faaliyetlerim',
        component: GonulluOlComponent
      },
      {
        path: 'konusmalarim',
        component: ChatComponent
      },
      {
        path: 'yetimler',
        component: ListOrphanComponent
      },
      {
        path: 'kurslar',
        component: OnlineCourseComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ], exports: [RouterModule]
})
export class OrphanRoutingModule { }
