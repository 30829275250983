<div class="content my-education-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>

    <div class="new-item" *hasPermission="['Pages.OrphanSchools.Create']">
      <a (click)="edit(null)">
        <span>Yeni Ekle</span>
        <span class="icon-plus-circle-thin"></span>
      </a>
    </div>
  </div>
  <div class="scroll-content">
    <div class="my-education-list" *ngIf="model && model.length > 0">
      <ng-container *ngFor="let item of model">
        <div class="my-education-item">
          <div class="item-update">
            <a
              *hasPermission="['Pages.OrphanSchools.Edit']"
              (click)="edit(item.orphanSchool.id)"
            >
              <span class="icon-edit icon"></span>
              <span>Düzenle</span>
            </a>
          </div>
          <div class="item-county-group">
            <div class="item-name">
              <span>Bulunduğu İl</span>
            </div>
            <div class="item-input">
              <input type="text" value="{{ item.provinceName }}" disabled />
            </div>
          </div>
          <div class="item-full-group">
            <div class="item-name">
              <span>Okul Adı</span>
            </div>
            <div class="item-input">
              <input
                type="text"
                value="{{ item.orphanSchool.schoolName }}"
                disabled
              />
            </div>
          </div>
          <!-- <div class="item-full-group">
            <div class="item-name">
              <span>Fakulte/Yüksek Okul/MYO Enstitü Adı</span>
            </div>
            <div class="item-input">
              <input
                type="text"
                value="{{ item.orphanSchool.schoolClassName }}"
                disabled
              />
            </div>
          </div> -->
          <div class="item-full-group">
            <div class="item-name">
              <span>Bölüm/Program Adı</span>
            </div>
            <div class="item-input">
              <input
                type="text"
                value="{{ item.orphanSchool.programName }}"
                disabled
              />
            </div>
          </div>
          <div class="item-input-group">
            <div class="item-group">
              <div class="item-name">
                <span>Eğitim Türü</span>
              </div>
              <div class="item-input">
                <input
                  type="text"
                  value="{{ item.educationTypeName }}"
                  disabled
                />
              </div>
            </div>

            <div class="item-group">
              <div class="item-name">
                <span>Mezuniyet Durumu</span>
              </div>
              <div class="item-input">
                <input
                  type="text"
                  value="{{ item.availabilityStatusName }}"
                  disabled
                />
              </div>
            </div>

            <div *ngIf="item.orphanSchool.schoolClassName != null" class="item-group">
              <div class="item-name">
                <span>Sınıfı</span>
              </div>
              <div class="item-input-class">
                <input
                  type="text"
                  value="{{ item.orphanSchool.schoolClassName }}"
                  disabled
                />
              </div>
            </div>

            <div *ngIf="item.orphanSchool.availabilityDate != null" class="item-group">
              <div class="item-name">
                <span>Mezuniyet Tarihi</span>
              </div>
              <div class="item-input">
                <input
                  type="text"
                  value="{{
                    item.orphanSchool.availabilityDate | date: 'dd/MM/yyyy'
                  }}"
                  disabled
                />
              </div>
            </div>

            <a
            (click)="downloadFile(item.orphanSchool.binaryFileId,item.binaryFileName)"
            *ngIf="item.orphanSchool.binaryFileId"
            class="item-time"
          >
            <div class="item-name">
              <span>Ek Dosya Var</span>
            </div>
            <div class="item-input">Tıklayınız</div>
          </a>

          </div>
        </div>
      </ng-container>
    </div>
    <div class="no-content-text" *ngIf="!model || model.length <= 0">
      <h6>Görüntülenecek veri bulunamadı</h6>
    </div>
    <app-footer></app-footer>
  </div>
</div>
