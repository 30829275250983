<div class="content student-clubs-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <div class="new-item" *ngIf="isUzmanKullanici == false">
      <a *hasPermission="['Pages.OrphanClubDetails.Edit']" (click)="edit(null)">
        <span>Yeni Ekle</span>
        <span class="icon-plus-circle-thin"></span>
      </a>
    </div>
  </div>
  <div class="scroll-content">
    <div class="student-clubs-content">
      <div class="row row-gap-30" *ngIf="model && model.length > 0">
        <div
          *ngFor="let item of model"
          class="col-xl-4 col-lg-6 col-md-6 col-sm-6"
        >
          <div class="item">
            <div class="item-body">
              <div class="club-title">
                <div class="title">
                  <span>Kulüp Adı:</span>
                </div>
                <div class="desc">
                  <span>{{ item.orphanClubDetail.name }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="club-sub-title">
                    <div class="title">
                      <span>Katılımcı Sayısı:</span>
                    </div>
                    <div class="desc">
                      <span>{{ item.orphanClubDetail.joinedCount }} Kişi</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="club-sub-title">
                    <div class="title">
                      <span>Katılım Yılı:</span>
                    </div>
                    <div class="desc">
                      <span>{{
                        item.orphanClubDetail.joinedYear | date: "dd/MM/yyyy"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-footer">
              <div class="club-edit" *ngIf="isUzmanKullanici == false">
                <a
                  *hasPermission="['Pages.OrphanClubDetails.Edit']"
                  (click)="edit(item.orphanClubDetail.id)"
                >
                  <span class="icon-edit icon"></span>
                  <span class="text">Düzenle</span>
                </a>
              </div>
              <div
                *hasPermission="['Pages.OrphanClubDetails.Edit']"
                class="club-detail"
              >
                <a (click)="detail(item.orphanClubDetail.id)">
                  <span class="text">Açıklama</span>
                  <span class="icon icon-brush-right-arrow"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-content-text" *ngIf="!model || model.length <= 0">
        <h6>Görüntülenecek veri bulunamadı</h6>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
