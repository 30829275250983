import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OdemeDurumBilgisiModel, OdemeRequestViewModel } from 'src/app/models/orphan/ayni-yardim-kizilay';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AyniYardimKizilayService {

  constructor(
    public http: HttpClient
  ) {
  }

  OdemeDurumBilgisi(model: OdemeRequestViewModel): Observable<OdemeDurumBilgisiModel> {
    return this.http.post<OdemeDurumBilgisiModel>(environment.apiRoot + "/api/services/app/AyniYardim/OdemeDurumBilgisi", model);
  }
}
