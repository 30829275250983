<div class="content notifications-page sub-page-content">
  <!-- <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div> -->
  <div class="scroll-content">
    <div class="notifications-content">
      <div class="row row-gap-15">
        <div *ngFor="let item of notifications" class="col-12">
          <div class="item">
            <div class="date">
              <span>{{
                item.notification.creationTime | date: "dd/MM/yyyy"
              }}</span>
            </div>
            <div class="title">
              <a ng-href="/{{ item.notification.data.detailLink }}">
                <span>{{ item.notification.data.message }} </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
