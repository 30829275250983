<div *ngIf="isPageDone" class="content career-and-business sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
    <section class="page-tab">
      <nav class="tab-button">
        <div class="nav" id="nav-tab" role="tablist">
          <a *hasPermission="['Pages.OrphanExperiences']" class="active" data-toggle="tab" href="#work-experience"
            role="tab" aria-selected="true">İş Tecrübelerim</a>
          <a *ngIf="
              this.utilsService.currentUserRole == 'yetim' ||
              this.utilsService.selectedOrphanId > 0
            " data-toggle="tab" href="#career-plan" role="tab" aria-selected="false">Kariyer Planım</a>
          <a *ngIf="
              this.utilsService.currentUserRole == 'yetim' ||
              this.utilsService.selectedOrphanId > 0
            " data-toggle="tab" href="#my-certificates" role="tab" aria-selected="false">Sertifikam</a>
        </div>
      </nav>
    </section>
  </div>
  <div class="scroll-content">
    <div class="tab-content" id="nav-tabContent">
      <div *ngIf="
          this.utilsService.currentUserRole == 'yetim' ||
          this.utilsService.selectedOrphanId > 0
        " class="tab-pane fade" id="career-plan" role="tabpanel">
        <div class="item-header">
          <div class="edit-item">
            <a *ngIf="!isEditCarrienPlanInfo" (click)="editCarrierPlanInfo()">
              <span class="icon-edit icon"></span>
              <span class="text">Düzenle</span>
            </a>
            <a *ngIf="isEditCarrienPlanInfo" (click)="onSubmitCarrierPlanInfo()">
              <span class="icon-edit icon"></span>
              <span class="text">Kaydet</span> </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a *ngIf="isEditCarrienPlanInfo" (click)="editCarrierPlanInfo()">
              <span class="icon-edit icon"></span>
              <span class="text">Vazgeç</span>
            </a>
          </div>
        </div>

        <div class="career-plan-content">
          <!-- <h1 class="page-title"></h1> -->
          <!-- <p *ngIf="!isEditCarrienPlanInfo">
          {{ orphanModel.orphan.carrierPlanInfo }}
        </p> -->
          <div class="default-input-group mt-4">
            <div class="title">
              <span>Kariyer Planım</span>
            </div>
            <div *ngIf="isEditCarrienPlanInfo" class="textarea">
              <textarea [(ngModel)]="orphanModel.orphan.carrierPlanInfo"></textarea>
            </div>
            <span *ngIf="!isEditCarrienPlanInfo">{{
              orphanModel?.orphan?.carrierPlanInfo
              }}</span>
            <div class="title mt-4">
              <span>Hayalindeki Meslek</span>
            </div>
            <div *ngIf="isEditCarrienPlanInfo" class="input">
              <input type="text" [(ngModel)]="orphanModel.orphan.dreamJob" placeholder="Uçak Mühendisliği" />
            </div>
            <span *ngIf="!isEditCarrienPlanInfo">{{
              orphanModel?.orphan?.dreamJob
              }}</span>
          </div>
        </div>
      </div>

      <div class="tab-pane fade show active" id="work-experience" role="tabpanel">
        <div class="new-item">
          <a *hasPermission="['Pages.OrphanExperiences.Create']" href="javascript:void(0)" (click)="edit(null)">
            <span>Yeni Ekle</span>
            <span class="icon-plus-circle-thin"></span>
          </a>
        </div>
        <div class="work-experience-list" *ngIf="model && model.length > 0">
          <ng-container *ngFor="let item of model">
            <div class="work-experience-item">
              <div class="item-update" *hasPermission="['Pages.OrphanExperiences.Edit']">
                <a (click)="edit(item.orphanExperience.id)">
                  <span class="icon-edit icon"></span>
                  <span>Düzenle</span>
                </a>
              </div>
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>İş Türü</span>
                      </div>
                      <div class="item-input">
                        <input type="text" value="{{
                          getTaskTypeName(
                            item.orphanExperience.taskType
                          )
                        }}" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Çalıştığın Pozisyon</span>
                      </div>
                      <div class="item-input">
                        <input type="text" value="{{ item.orphanExperience.companyPosition }}" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-full-group">
                <div class="item-name">
                  <span>Firma Adı</span>
                </div>
                <div class="item-input">
                  <input type="text" value="{{ item.orphanExperience.companyName }}" disabled />
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-3 col-md-12">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Çalışma Türü</span>
                      </div>
                      <div class="item-input">
                        <input type="text" value="{{
                            getWorkTypeName(
                              item.orphanExperience.oprhanWorkType
                            )
                          }}" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Lokasyon</span>
                      </div>
                      <div class="item-input">
                        <input type="text" value="{{ item.provinceName }}" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 mt-md-3 mt-sm-3 mt-lg-0 mt-3">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Sektörü</span>
                      </div>
                      <div class="item-input">
                        <input type="text" value="{{ item.orphanExperience.companySector }}" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-3 col-md-6">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Başlama Tarihi</span>
                      </div>
                      <div class="item-input">
                        <span>{{
                          item.orphanExperience.startDate | date: "dd/MM/yyyy"
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 mt-md-0 mt-sm-3 mt-lg-0 mt-3">
                    <div class="item-full-group">
                      <div class="item-name">
                        <span>Ayrılış Tarihi</span>
                      </div>
                      <div class="item-input">
                        <span>{{
                          item.orphanExperience.endDate | date: "dd/MM/yyyy"
                          }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-12">
                    <div class="item-full-group">
                      <div class="item-textarea-name">
                        <span>Açıklama</span>
                      </div>
                      <div class="item-textarea">
                        <textarea disabled>{{
                          item.orphanExperience.description
                        }}</textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="no-content-text" *ngIf="!model || model.length <= 0">
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>
      </div>

      <div *ngIf="
          this.utilsService.currentUserRole == 'yetim' ||
          this.utilsService.selectedOrphanId > 0
        " class="tab-pane fade" id="my-certificates" role="tabpanel">
        <div class="new-item" *ngIf="this.utilsService.currentUserRole != 'yetim'">
          <a *ngIf="!orphanModel?.certificateFileName" href="javascript:void(0)" (click)="addCertificateFile()">
            <span>Yeni Ekle</span>
            <span class="icon-plus-circle-thin"></span>
          </a>
        </div>
        <div class="my-certificates-list">
          <div class="row row-gap-15">
            <div class="col-xl-12" *ngIf="orphanModel?.certificateFileName">
              <div class="my-certificates-item">
                <div class="icon">
                  <span class="icon-certificate"></span>
                </div>
                <div class="item-body">
                  <div class="body-header">
                    <span class="title">
                      {{ orphanModel.certificateFileName }}</span>
                    <!-- <a
                    href="#my-certificate-edit"
                    data-toggle="modal"
                    data-target="#my-certificate-edit"
                    class="edit"
                  >
                    <span class="icon-edit"></span>
                    <span>Düzenle</span>
                  </a> -->
                  </div>
                  <div class="body-footer">
                    <div class="date-area">
                      <span class="title">Veriliş Tarihi :</span>
                      <span class="date">
                        {{ orphanModel?.orphan?.certificateDateOfIssue | date: "dd/MM/yyyy" }}
                      </span>
                    </div>
                    <div class="detail-redirect">
                      <a (click)="
                          downloadFile(
                            orphanModel.orphan.certificateFileId,
                            orphanModel.certificateFileName
                          )
                        " mat-button>İndir
                        <mat-icon color="primary">download</mat-icon>
                      </a>
                      <a *ngIf="this.isDeleted" (click)="
                          deleteFile(
                            orphanModel.orphan.id,
                            orphanModel.certificateFileName,
                            i
                          )
                        " mat-button>
                        Sil
                        <mat-icon color="accent">delete</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-content-text" *ngIf="!orphanModel?.certificateFileName">
              <h6>Görüntülenecek veri bulunamadı</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
