import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GonulluOlResultModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KizilayGonulluOlService {

  baseUrl = environment.apiRoot + "/api/services";

  constructor(
    public http: HttpClient
  ) { }


  getEvents(muhattapNo: string): Observable<GonulluOlResultModel> {
    return this.http.get<GonulluOlResultModel>(this.baseUrl + "/app/KizilayGonulluOl/GetGonulluOlEvents?muhattapNo=" + muhattapNo);
  }
}
