import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EventCommentDataRequestModel, EventCommentViewModel, EventViewModel } from 'src/app/models/orphan';
import { EventCalendarService } from 'src/app/services/orphan';
import { EventCalendarCommentService } from 'src/app/services/orphan/event-calendar-comment.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-event-calendar-list-detail',
  templateUrl: './event-calendar-list-detail.component.html'
})
export class EventCalendarListDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: EventViewModel;
  commentListModel: EventCommentViewModel[] = [];
  form: FormGroup;
  date: Date = new Date;

  constructor(
    public service: EventCalendarService,
    public commentService: EventCalendarCommentService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Etkinlik");
  }

  ngOnInit(): void {
    let userId = this.utilsService.getTokenDecode()["user_identifier"];
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.form = this.formBuilder.group({
        id: null,
        commentText: [""],
        commentDate: [this.date],
        userId: [userId],
        eventCalendarDetailId: [this.id]
      })
      this.service.getEventCalendarDetailForView(this.id).subscribe(result => {
        this.model = result;
        let eventCommentRequestModel = new EventCommentDataRequestModel();
        eventCommentRequestModel.eventId = this.id;
        let paramsString = this.utilsService.urlSearchParamConvertToString(eventCommentRequestModel);
        this.commentService.getAllCommentsWithEventId(paramsString).subscribe(res => {
          this.commentListModel = res.items;
          this.isPageDone = true;
        })

      })
    })
  }

  onSubmitComment(): void {
    if (this.form.valid) {
      this.commentService.createOrEdit(this.form.value).subscribe(result => {
        this.utilsService.showMessage("İşlem başarılı");
        this.ngOnInit();
      })
    }
  }
}
