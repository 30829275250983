import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { NewsModel } from 'src/app/models/orphan/news';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends GenericCrudService<NewsModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/NewsAndAnnouncements")
  }

  getActiveAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetActiveAll?" + params);
  }

  getNewsAndAnnouncement(id: number): Observable<NewsModel> {
    return this.http.get<NewsModel>(this.baseUrl + "/GetNewsAndAnnouncementForView?id=" + id);
  }

  getDownloadUrl(id: string): Observable<string> {
    return this.http.get<string>(environment.apiRoot + "/File/DownloadBinaryFile?id=" + id);
  }
}
