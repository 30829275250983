<div *ngIf="isPageDone" class="content news-detail-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="/haberler">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <div class="scroll-content">
    <div class="news-detail-content">
      <div class="new-img">
        <img
          *ngIf="
            model?.newsAndAnnouncement?.catalogImageFileName &&
            model.newsAndAnnouncement.catalogImageFileName.length > 0
          "
          src="{{ getDownloadUrl(model.newsAndAnnouncement.catalogImage) }}"
          alt=""
        />
        <img
          *ngIf="!model?.newsAndAnnouncement?.catalogImageFileName"
          src="../../../../../assets/images/haber-default.png"
          alt=""
        />
      </div>
      <div class="new-date">
        <span>{{
          model.newsAndAnnouncement.publishDate | date: "dd/MM/yyyy"
        }}</span>
      </div>
      <div class="new-detail">
        <h1 class="page-title">{{ model.newsAndAnnouncement.headerText }}</h1>
        <p innerHTML="{{ model.newsAndAnnouncement.contentText }}"></p>
      </div>
    </div>
  </div>
</div>
