import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app-constants';
import { ProjectStatusLanguage } from 'src/app/models/generic-base/custom-enum-model';
import { ProjectViewModel } from 'src/app/models/orphan/project';
import { ProjectService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ProjectStatus } from 'src/shared/service-proxies/service-proxies';
import { ProjectInfoEditComponent } from '../project-info-edit/project-info-edit.component';

@Component({
  selector: 'app-project-info-detail',
  templateUrl: './project-info-detail.component.html',
  styleUrls: ['./project-info-detail.component.css']
})
export class ProjectInfoDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: ProjectViewModel;
  form: FormGroup;
  date: Date = new Date;

  constructor(
    public service: ProjectService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("Projelerim");
  }

  ngOnInit(): void {
    let userId = this.utilsService.getTokenDecode()["user_identifier"];
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.form = this.formBuilder.group({
        id: null,
        commentText: [""],
        commentDate: [this.date],
        userId: [userId],
        orphanTaskId: [this.id]
      })
      this.service.getOrphanProjectDetailForEdit(this.id).subscribe(result => {
        this.model = result.orphanProjectDetail;
        this.isPageDone = true;
      })
    })
  }

  edit(id: number): void {
    const dialogRef = this.dialog.open(ProjectInfoEditComponent, {
      data: { id: id },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
        this.utilsService.showMessage("İşlem başarılı");
        window.location.href = "/projelerim"
      }
    });
  }

  getProjectStatusName(id: string): string {
    return ProjectStatusLanguage[id];
  }
}
