import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppConstants } from "src/app-constants";
import { CrudOperations } from "src/app/services/generic-base/generic-crud.service";
import { UtilsService } from "src/app/services/utils/utils.service";

export interface IEditViewComponent<T> {
  // onSubmit(): void
}

export abstract class EditViewComponent<
  T, //Tip
  DI, //id tip
> implements IEditViewComponent<T>{

  public form: FormGroup;
  public submitted = false;
  public model: T;
  public id: DI;
  public navigateUrl: string;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router) {
  }

  get f() {
    return this.form.controls;
  }

  // onSubmit(): void {
  //   this.submitted = true;
  //   if (this.form.valid) {
  //     this.service.createOrUpdate(this.form.value).subscribe(response => {
  //       this.router.navigate([this.navigateUrl]);
  //     })
  //   }
  // }
}

export interface IModalViewComponent<EC> {
  onClose(): void;
}

export abstract class ModalViewComponent<EC>
  implements IModalViewComponent<EC>
{
  public form: FormGroup;

  constructor(
    public utilsService: UtilsService,
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EC>
  ) {
  }

  get f() {
    return this.form.controls;
  }

  onClose(): void {
    this.dialogRef.close(AppConstants.EDIT_DIALOG_RESULT_CANCEL)
  }

}
