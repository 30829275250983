<div class="content volunteering-activities-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a href="#">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
    </section>
  </div>
  <!-- <div style="text-align: center">
      <b>Henüz gösterilecek kayıt yoktur.</b>
    </div> -->
  <div class="volunteering-activities-content scroll-content">
    <div class="d-flex flex-wrap justify-content-around gap-30px">
      <div class="circle-box">
        <span class="title">Gönüllülük Seviye Puanı</span>
        <span class="score">{{ model.volunteerLevelPoints }}</span>
      </div>
      <div class="circle-box">
        <span class="title">Katıldığı Toplam Görev Sayısı</span>
        <span class="score">{{ model.totalNumOfAttendedEvents }}</span>
      </div>
    </div>
    <div
      class="table-content"
      *ngIf="model.taskList && model.taskList.length > 0"
    >
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Tarih</th>
              <th scope="col">Görev Adı</th>
              <th scope="col">Katılım Durumu</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of model.taskList">
              <td>{{ item.startDate | date: "dd/MM/yyyy" }}</td>
              <td>
                {{ item.taskName }}
              </td>
              <td>{{ item.participationStatus }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    class="mt-5 no-content-text"
    *ngIf="!model.taskList || model.taskList.length <= 0"
  >
    <h6>Görüntülenecek veri bulunamadı</h6>
  </div>
  <app-footer> </app-footer>
</div>
