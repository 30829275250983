import { DataRequestModel } from "../generic-base/data-model";

export class WorkJobModel {
  workJob: WorkJobViewModel;
}
export class WorkJobViewModel {
  headerText: string;
  description: string;
  companyLocation: string;
  sectorInfo: string;
  companyInfo: string;
  startDate: Date;
  endDate: Date;
  publishDate: Date;
  isActive: true;
  id: number;
}

export class WorkJobDataRequestModel extends DataRequestModel {
  isActiveFilter: number;
}

export class JobApplicationModel {
  jobApplication: JobApplicationViewModel;
  orphanFirstName: string;
  jobStatusName: string;
  jobApplicationFileFileName: string;
  workJob: WorkJobViewModel;
}
export class JobApplicationViewModel {
  orphanDescription: string;
  joinDate: Date;
  managerDescription: string;
  jobApplicationFile: string;
  jobApplicationFileToken: string;
  orphanId: number;
  jobStatusId: number;
  id: number;
}

export class JobApplicationDataRequestModel extends DataRequestModel {
  orphanId: number;
}


