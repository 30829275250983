import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalViewComponent } from 'src/app/components/generic-base/generic-base.component';
import { ConfirmationDialogComponent } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { GetOrphanFileForViewDto, OrphanFileModel } from 'src/app/models/orphan/file';
import { OrphanSchoolModel } from 'src/app/models/orphan/school-info';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { FileService } from 'src/app/services/orphan/file.service';
import { SchoolInfoService } from 'src/app/services/orphan/school-info.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-school-info-edit',
  templateUrl: './school-info-edit.component.html',
  styleUrls: ['./school-info-edit.component.css']
})
export class SchoolInfoEditComponent extends ModalViewComponent<SchoolInfoEditComponent> implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  isEdit = false;
  isShow = false;
  isGraduationDateShow = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;
  formData: FormData = new FormData();
  provinceSelect: DropdownModel[];
  educationTypeSelect: DropdownModel[];
  availableStatusSelect: DropdownModel[];
  orphanFiles: GetOrphanFileForViewDto[] = [];
  model: OrphanSchoolModel;
  date: Date = new Date;
  availabilityDateRequired: boolean = false;

  constructor(
    private service: SchoolInfoService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SchoolInfoEditComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super(utilsService, formBuilder, dialogRef)
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      id: this.dialogData.id ?? null,
      schoolName: ["", Validators.required],
      schoolClassName: ["",],
      programName: [""],
      schoolProvinceId: [0, Validators.required],
      availabilityStatusId: [""],
      educationTypeId: [0, Validators.required],
      availabilityDate: [""],
      binaryFileToken: [""],
      createOrEditOrphanFileDto: null,
      orphanId: [this.utilsService.selectedOrphanId !== 0 ? this.utilsService.selectedOrphanId : null],
      userId: [this.utilsService.currentUserId]
    })

    const provincePromise$ = this.service.getAllProvinceForTableDropdown().toPromise();
    const educationTypePromise$ = this.service.getAllEducationTypeForTableDropdown().toPromise();
    const availableStatusPromise$ = this.service.getAllAvailabilityStatusForTableDropdown().toPromise();
    Promise.all([provincePromise$, educationTypePromise$, availableStatusPromise$]).then(response => {
      this.provinceSelect = response[0],
        this.educationTypeSelect = response[1],
        this.availableStatusSelect = response[2]
    }).then(() => {
      if (this.dialogData.id) {
        this.service.getOrphanSchoolForEdit(this.dialogData.id).subscribe(result => {
          this.model = result;
          this.form.patchValue(this.model.orphanSchool);
          this.availabilityStatusChange();
          // this.form.controls.availabilityDate.setValue(this.date);
        })
      }
      this.isPageDone = true;
    });
  }

  onFileSelected(event): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      let selectedFile = <File>event.target.files[0];
      this.formData.append(selectedFile.name, selectedFile);
      this.selectedFileName = selectedFile.name;

      this.fileService.uploadCatalogImageFile("/OrphanSchools/UploadcatalogImageFile", this.formData).subscribe(res => {
        this.form.controls.binaryFileToken.setValue(res.result.fileToken)
      })
    }
  }

  downloadFile(binaryFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(binaryFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  deleteFile(id: number, key: string, index: number) {
    const dialogData: DialogDataModel = {
      title: "Dosya Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.removeCatalogImageFile(id).subscribe(response => {
          this.model.binaryFileName = null;
        })
      }
    })
  }

  delete(id: number, key: string) {
    const dialogData: DialogDataModel = {
      title: "Kayıt Silinecek!",
      message: key
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "250px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.delete(id).subscribe((response) => {

          this.dialogRef.close();
        })
      }
    })
  }

  availabilityStatusInit() {
    var selectedItemValue = this.availableStatusSelect.find(p => p.id == this.form.controls.availabilityStatusId.value);
    if(selectedItemValue == undefined || selectedItemValue == null)
    {
      this.isShow = false;
      this.isGraduationDateShow = false;
      this.form.controls['availabilityDate'].setValue(null);
      this.form.controls['schoolClassName'].setValue(null);
    }
    else
    {
      if(selectedItemValue.displayName.toLowerCase() == "okuyor")
      {
        this.isShow = true;
        this.isGraduationDateShow = false;
        this.form.controls['availabilityDate'].setValue(null);
      }
      else if(selectedItemValue.displayName.toLowerCase() == "mevzun" || selectedItemValue.displayName.toLowerCase() == "mezun")
      {
        this.isShow = false;
        this.isGraduationDateShow = true;
        this.form.controls['schoolClassName'].setValue(null);
      }
    }
  }

  availabilityStatusChange() {
    var selectedItemValue = this.availableStatusSelect.find(p => p.id == this.form.controls.availabilityStatusId.value);
    if(selectedItemValue == undefined || selectedItemValue == null)
    {
      this.isShow = false;
      this.isGraduationDateShow = false;
      this.form.controls['availabilityDate'].setValue(null);
      this.form.controls['schoolClassName'].setValue(null);
    }
    else
    {
      if(selectedItemValue.displayName.toLowerCase() == "okuyor")
      {
        this.isShow = true;
        this.isGraduationDateShow = false;
        this.form.controls['availabilityDate'].setValue(null);
      }
      else if(selectedItemValue.displayName.toLowerCase() == "mevzun" || selectedItemValue.displayName.toLowerCase() == "mezun")
      {
        this.isShow = false;
        this.isGraduationDateShow = true;
        this.form.controls['schoolClassName'].setValue(null);
      }
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      if(this.isGraduationDateShow)
      {
        if(this.form.controls['availabilityDate'].value == null || this.form.controls['availabilityDate'].value == "")
        {
          this.availabilityDateRequired = true;
        }
        else
        {
          this.availabilityDateRequired = false;
        }
      }

      if(!this.availabilityDateRequired)
      {
        this.service.createOrEdit(this.form.value).subscribe(result => {
          if (result.message == "Ok") {
            this.utilsService.showMessage("İşlem başarılı");
          }
          this.dialogRef.close(this.form.value);
        })
      }
    }
  }
}
