import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { ReadBookModel, ReadBookViewModel } from 'src/app/models/orphan/read-book';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ReadBookService extends GenericCrudService<ReadBookViewModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanBookDetails")
  }

  getOrphanBookAllWithOrphanId(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetOrphanBookAllWithOrphanId?" + params);
  }

  getOrphanBookForEdit(id: number): Observable<ReadBookModel> {
    return this.http.get<ReadBookModel>(this.baseUrl + "/GetOrphanBookDetailForEdit?id=" + id);
  }

  getAllBookTypeForTableDropdown(): Observable<DropdownModel[]> {
    return this.http.get<DropdownModel[]>(this.baseUrl + "/GetAllBookTypeForTableDropdown");
  }
}
