import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { EventModel, EventViewModel } from 'src/app/models/orphan';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from '../generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class EventCalendarJoinService extends GenericCrudService<EventModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/OrphanEventCalendarJoins")
  }

  getAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetAll?" + params);
  }

  existEvent(userId: number, eventId: number): Observable<number> {
    var model = { userId: userId, eventId: eventId };
    return this.http.post<number>(this.baseUrl + "/ExistEvent", model);
  }
}
