<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Açıklama</span>
          </div>
          <div class="input">
            <input type="text" formControlName="orphanDescription" />
          </div>
        </div>
        <div
          *ngIf="f.id.value > 0 && utilsService.isAdmin"
          class="default-input-group mb-4"
        >
          <div class="title">
            <span>Sorumlu Açıklama</span>
          </div>
          <div class="input">
            <input type="text" formControlName="managerDescription" />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Dosya *</span>
          </div>
          <div class="input">
            <fieldset
              class="dc-fieldset"
              *ngIf="!model?.jobApplicationFileFileName"
            >
              <input
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <div class="file-upload">
                Dosya Yükleyin
                <button
                  mat-mini-fab
                  color="primary"
                  type="button"
                  class="upload-btn"
                  (click)="fileUpload.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
              </div>
              <span *ngIf="selectedFileName">{{ selectedFileName }}</span>
            </fieldset>
            <ng-container *ngIf="model?.jobApplicationFileFileName">
              <a
                (click)="
                  downloadFile(
                    model.jobApplication.jobApplicationFile,
                    model.jobApplicationFileFileName
                  )
                "
                mat-button
              >
                {{ model.jobApplicationFileFileName }}
                <mat-icon color="primary">download</mat-icon>
              </a>

              <a
                (click)="
                  deleteFile(
                    model.jobApplication.id,
                    model.jobApplicationFileFileName,
                    i
                  )
                "
                mat-button
              >
                Sil
                <mat-icon color="accent">delete</mat-icon>
              </a>
            </ng-container>
          </div>
          <span class="text-red" *ngIf="!fileUploadWarning">*Bu Alanın Doldurulması Zorunludur.</span>
        </div>
        <div class="btn-content">
          <div class="add-btn">
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
