<div *ngIf="isPageDone">
  <div class="content announcements-detail-page sub-page-content">
    <div class="fixed-area">
      <section class="col-12 px-0 history-back">
        <a routerLink="/duyurular">
          <span class="icon-brush-left-arrow icon"></span>
          <span class="text">Geri</span>
        </a>
      </section>
    </div>
    <div class="announcements-detail-content">
      <div class="announcement-date">
        <span> {{ model.announcement.publishDate | date: "dd/MM/yyyy" }}</span>
      </div>
      <div class="announcement-detail">
        <h1 class="page-title">{{ model.announcement.headerText }}</h1>
        <p innerHTML="{{ model.announcement.contentText }}"></p>
      </div>
    </div>
  </div>
</div>
