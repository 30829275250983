<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-title">
          <span>Kulüp Bilgilerini Düzenle</span>
        </div>

        <div class="default-input-group mb-4">
          <div class="title">
            <span>Kulüp Adı</span>
          </div>
          <div class="input">
            <input
              type="text"
              formControlName="name"
              placeholder="Kulüp Adını Yaz"
            />
            <span class="text-danger" *ngIf="form.get('name').errors && 
            form.get('name').hasError('required')">* Bu Alan Zorunludur</span>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="row row-gap-xs-20">
            <div class="col-lg-6">
              <div class="title">
                <span>Katılımcı Sayısı</span>
              </div>
              <div class="input">
                <input
                  type="number"
                  formControlName="joinedCount"
                  placeholder="Sayı Yaz"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="title">
                <span>Katılım Yılı</span>
              </div>
              <div class="input">
                <input
                  type="date"
                  formControlName="joinedYear"
                  placeholder="GG / AA / 20__"
                  value="{{
                    this.form.controls.joinedYear.value | date: 'yyyy-MM-dd'
                  }}"
                  max="{{this.todayDateFormatted | date: 'yyyy-MM-dd'}}"
                />
                <span class="text-danger" *ngIf="form.get('joinedYear').errors && 
                form.get('joinedYear').hasError('required')">* Bu Alan Zorunludur</span>
              </div>
            </div>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Açıklama</span>
          </div>
          <div class="textarea">
            <textarea rows="10" formControlName="description"></textarea>
          </div>
        </div>

        <div class="btn-content">
          <div
            class="delete-btn"
            *hasPermission="['Pages.OrphanClubDetails.Delete']"
          >
            <button
              type="button"
              *ngIf="model"
              (click)="delete(model.id, model.name)"
            >
              Kulübü Sil
            </button>
          </div>
          <div
            class="add-btn"
            *hasPermission="['Pages.OrphanClubDetails.Edit']"
          >
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
