<div class="content projects-page sub-page-content">
  <div class="fixed-area">
    <section class="col-12 px-0 history-back">
      <a routerLink="/profil">
        <span class="icon-brush-left-arrow icon"></span>
        <span class="text">Geri</span>
      </a>
      <div class="new-item">
        <a
          (click)="edit(null)"
          *hasPermission="['Pages.OrphanProjectDetails.Create']"
        >
          <span>Yeni Ekle</span>
          <span class="icon-plus-circle-thin"></span>
        </a>
      </div>
    </section>
  </div>
  <div class="scroll-content">
  <div class="projects-content">
    <div class="row row-gap-30">
      <ng-container *ngFor="let item of model">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div class="item">
            <div class="item-body">
              <div class="date">
                <span>{{ item.orphanProjectDetail.year }}</span>
              </div>
              <div class="title">
                <span
                  title="The standard Lorem Ipsum passage, used since the 1500s The standard Lorem Ipsum passage, used since the 1500s"
                  >{{ item.orphanProjectDetail.name }}</span
                >
              </div>
            </div>

            <div class="item-footer">
              <div class="project-statu">
                <span
                  >Durum :
                  {{
                    getProjectStatusName(item.orphanProjectDetail.projectStatus)
                  }}</span
                >
              </div>
              <div class="project-detail">
                <a
                  [routerLink]="[
                    '/projelerim-detay/' + item.orphanProjectDetail.id
                  ]"
                >
                  <span class="text">Detay</span>
                  <span class="icon icon-brush-right-arrow"></span>
                </a>
              </div>
            </div>
          </div></div
      ></ng-container>
    </div>
  </div>
  <mat-paginator
    (page)="getServerData()"
    [length]="dataCount"
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page"
  >
  </mat-paginator>
  <app-footer></app-footer>
</div>
</div>
