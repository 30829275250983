<div *ngIf="isPageDone" class="container-fluid p-0">
  <div class="row m-0">
    <header class="sub-header">
      <div class="logo">
        <a routerLink="profil">
          <img src="../assets/images/logo-white.jpg" alt="" />
        </a>
      </div>

      <div class="menu" style="flex-grow: 1">
        <!--Yetim olmayan rollerden birisi yetim seçimi yaptıktan sonra çalışacak menü-->
        <ul *ngIf="
            this.utilsService.currentUserRole != 'yetim' &&
            this.utilsService.selectedOrphanId > 0
          ">
          <ng-container *ngFor="let item of menuList">
            <li *hasPermission="[item.permission]" routerLinkActive="active">
              <a routerLink="{{ item.link }}">
                <span class="{{ item.icon }}"></span>
                <span>{{ item.name }}</span>
              </a>
            </li>
          </ng-container>
        </ul>

        <!-- <span>
          {{ this.utilsService.currentUserRole }}
          {{ this.utilsService.selectedOrphanId | json }}
        </span> -->

        <!--yetimin göreceği menü-->
        <ul *ngIf="this.utilsService.currentUserRole == 'yetim'">
          <ng-container *ngFor="let item of menuList">
            <li *ngIf="item.name | demo: utilsService.currentUserRole" routerLinkActive="active">
              <a routerLink="{{ item.link }}">
                <span class="{{ item.icon }}"></span>
                <span>{{ item.name }}</span>
              </a>
            </li>
          </ng-container>
        </ul>

        <!--Yetim olmayan rollerden birinin sabit göreceği menü-->
        <ul *ngIf="
            this.utilsService.currentUserRole != 'yetim' &&
            (this.utilsService.selectedOrphanId == 0 ||
              this.utilsService.selectedOrphanId == null)
          ">
          <li routerLinkActive="active">
            <a routerLink="profil">
              <span class="icon-home icon"></span>
              <span>Profilim</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="kisisel-bilgilerim">
              <span class="icon-profile-card icon"></span>
              <span>Kişisel Bilgilerim</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="ogrenim-bilgilerim">
              <span class="icon-education icon"></span>
              <span>Öğrenim Bilgilerim</span>
            </a>
          </li>

          <li routerLinkActive="active">
            <a routerLink="kariyer-bilgilerim">
              <span class="icon-statistics icon"></span>
              <span>Kariyer Bilgilerim</span>
            </a>
          </li>
          <li>
            <a routerLink="yetimler">
              <span class="icon-handshake icon"></span>
              <span>Yetim Listesi</span>
            </a>
          </li>
        </ul>
      </div>

      <div *ngIf="
          utilsService.currentUserRole == 'yetim' ||
          utilsService.selectedOrphanId > 0
        " class="career-goal">
        <div class="banner">
          <img src="../assets/images/carrier-banner.png" alt="" />
        </div>
        <div class="title">
          <span>Kariyer Hayalim</span>
        </div>
        <div class="question">
          <span>- Hayalindeki Meslek?</span>
          <span class="answer">{{ this.dreamJob2 }}</span>
        </div>
        <div class="description">
          <span>Hedefinde Eğitimin ve gelişimin için sana özel bazı görevlerimiz
            var.</span>
        </div>
        <div class="all-tasks">
          <a href="#">
            <span>Görevleri Gör</span>
            <span class="icon icon-brush-right-arrow"></span>
          </a>
        </div>
      </div>
    </header>
    <div class="page-content">
      <div class="header">
        <div class="left-area">
          <div class="menu-toggle" id="headerOpener">
            <span>x</span>
          </div>
          <div class="page-title">
            <h1 *ngIf="utilsService.getTitle">
              {{ utilsService?.getTitle() }}
            </h1>
          </div>
        </div>
        <div class="right-area">
          <!-- <div class="search-content">
            <form action="">
              <input
                type="text"
                name=""
                id=""
                placeholder="Aramak istediğiniz kelimeyi yazın"
              />
              <div class="search-btn">
                <span class="icon-search"></span>
              </div>
            </form>
          </div> -->
          <div class="back-profile">
            <a (click)="backToMyProfile()">Profilime Geri Dön</a>
          </div>
          <div #notificationElement class="notification" [ngClass]="unreadNotificationCount ? 'not-read' : ''">
            <div class="icon">
              <a id="notificationOpener">
                <img src="../assets/images/notification-icon.png" alt="" />
              </a>
            </div>
            <div class="notification-dropdown">
              <div class="notification-dropdown-header">
                <div class="notification-dropdown-title">
                  <span>Bildirimler</span>
                </div>
                <div class="notification-dropdown-close" id="notificationDropdownClose">
                  <span class="icon-modal-close"></span>
                </div>
              </div>
              <div class="notification-list">
                <div class="row row-gap-15">
                  <ng-container *ngIf="notifications.length > 0">
                    <div *ngFor="let item of notifications" class="col-12">
                      <div class="item">
                        <div class="date">
                          <span>{{
                            item.notification.creationTime | date: "dd/MM/yyyy"
                            }}</span>
                        </div>
                        <div class="title">
                          <a [routerLink]="
                              item.notification.data.properties.detailLink
                            ">
                            <span>{{ item.notification.data.properties.message }}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- <div class="col-12">
                    <div class="item">
                      <div class="date past">
                        <span>22.06.2022</span>
                      </div>
                      <div class="title">
                        <a href="#">
                          <span
                            >Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry.
                          </span>
                        </a>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="notification-footer" style="justify-content: flex-start !important">
                    <div class="notification-all">
                      <a routerLink="kullanici-izin">
                        <span>Bildirim Ayarları</span>
                        <span class="icon-edit icon-brush"></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="notification-footer">
                    <div class="notification-all">
                      <a routerLink="tum-bildirimler">
                        <span>Tümü</span>
                        <span class="icon-brush-right-arrow icon-brush"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="announcement-toggle" id="announcementOpener">
            <span class="icon-notice-board"></span>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <aside>
      <!-- <div class="profile">
        <a href="#">
          <div class="profile-name">
            <span class="name">{{ utilsService.getUserName() }}</span>
            <span class="icon-down-circle-thin"></span>
          </div>
          <div class="profile-photo">
            <img src="https://picsum.photos/200/300" alt="" />
          </div>
        </a>
      </div> -->

      <div class="profile">
        <a href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <div class="profile-name">
            <span class="name">{{ utilsService.getUserName() }}</span>
            <span class="icon-down-circle-thin"></span>
          </div>
          <div class="profile-photo">
            <img src="{{ utilsService.profilePicture }}" alt="" />
          </div>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <span *ngIf="utilsService.currentUserRole != 'yetim'">Mevcut Rolünüz : {{ utilsService.currentUserRole
            }}</span>
          <a class="dropdown-item" (click)="logOut()">Çıkış Yap</a>
        </div>
      </div>
      <div class="aside-title">
        <h2>Duyuru Panosu</h2>
      </div>

      <div class="aside-slick-container">
        <div class="upcoming-events" *ngIf="eventCalendarList && eventCalendarList.length > 0">
          <div class="title">
            <a routerLink="/etkinlik-takvimi-liste">Yaklaşan Etkinlikler</a>
          </div>

          <div class="upcoming-events-slick">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="eventCalendarSlideConfig"
              (init)="slickInit($event)">
              <div ngxSlickItem *ngFor="let item of eventCalendarList; let i = index" class="slide">
                <div class="item">
                  <a routerLink="etkinlik-takvimi-detay/{{
                      item.eventCalendarDetail.id
                    }}">
                    <div class="date">
                      <span class="day">{{
                        item.eventCalendarDetail.startDate | date: "dd"
                        }}</span>
                      <span class="month">{{
                        item.eventCalendarDetail.startDate | date: "MM"
                        }}</span>
                      <span class="year">{{
                        item.eventCalendarDetail.startDate | date: "yyyy"
                        }}</span>
                    </div>
                  </a>
                  <div class="event-description">
                    <a routerLink="etkinlik-takvimi-detay/{{
                        item.eventCalendarDetail.id
                      }}">
                      <div class="event-title">
                        <span>{{ item.eventCalendarDetail.subject }}</span>
                      </div>
                    </a>
                    <div class="event-time">
                      <span>Saat:
                        {{
                          item.eventCalendarDetail?.startDate | date: "dd.MM.yyyy HH:mm"
                        }}
                        /
                        {{
                          item.eventCalendarDetail?.endDate | date: "dd.MM.yyyy HH:mm"
                        }}</span>
                    </div>
                    <div class="event-location">
                      <span>Yer: {{ item.provinceName }}</span>
                    </div>
                    <div class="event-join">
                      <a *ngIf="!item.exists" (click)="
                          joinEventCalendar(item.eventCalendarDetail.id, i)
                        ">
                        <span class="icon-plus-circle-thin"></span>
                        <span>Katılımcı Ol</span>
                      </a>
                      <a *ngIf="item.exists" (click)="
                          delete(
                            i,
                            item.eventCalendarDetail.subject,
                            item.eventCalendarDetail.id
                          )
                        ">
                        <span class="icon-negative-circle-thin"></span>
                        <span>Katıldınız</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="aside-slick-button events-dots">
            <div class="upcomingEventsPrevButton prev">
              <span class="icon-left-thin"></span>
            </div>
            <div class="upcomingEventsNextButton next">
              <span class="icon-right-thin"></span>
            </div>
          </div>
        </div>
        <div *ngIf="!eventCalendarList || eventCalendarList.length <= 0">
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>
        <div class="news">
          <div class="title">
            <a routerLink="/haberler">Haberler</a>
          </div>

          <div class="news-slick" *ngIf="newsList && newsList.length > 0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="newsSlideConfig"
              (init)="slickInit($event)">
              <div ngxSlickItem *ngFor="let item of newsList" class="slide">
                <div class="item">
                  <div class="icon">
                    <span class="icon-news"></span>
                  </div>
                  <div class="news-description">
                    <div class="news-title">
                      <span>
                        <a [routerLink]="[
                            '/haberler-detay/' + item.newsAndAnnouncement.id
                          ]">
                          {{ item.newsAndAnnouncement.headerText }}
                        </a></span>
                    </div>
                    <!-- <div class="news-time">
                      <span>
                        Saat -
                        {{
                          item.newsAndAnnouncement.publishDate | date: "hh:mm"
                        }}</span
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="aside-slick-button news-dots">
            <div class="newsPrevButton prev">
              <span class="icon-left-thin"></span>
            </div>
            <div class="newsNextButton next">
              <span class="icon-right-thin"></span>
            </div>
          </div>
        </div>
        <div *ngIf="!newsList || newsList.length <= 0">
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>

        <div class="announcement">
          <div class="title">
            <a routerLink="/duyurular">Duyurular</a>
          </div>
          <div class="announcement-slick" *ngIf="announcementList && announcementList.length > 0">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="announcementSlideConfig"
              (init)="slickInit($event)">
              <div ngxSlickItem *ngFor="let item of announcementList" class="slide">
                <div class="item">
                  <div class="icon">
                    <span class="icon-notification"></span>
                  </div>
                  <div class="announcement-description">
                    <div class="announcement-title">
                      <span>
                        <a [routerLink]="[
                            '/duyurular-detay/' + item.announcement.id
                          ]">
                          {{ item.announcement.headerText }}
                        </a></span>
                    </div>
                    <div class="announcement-date">
                      <span>
                        Saat -
                        {{
                        item.announcement.publishDate | date: "hh:mm"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="aside-slick-button announcement-dots">
            <div class="announcementPrevButton prev">
              <span class="icon-left-thin"></span>
            </div>
            <div class="announcementNextButton next">
              <span class="icon-right-thin"></span>
            </div>
          </div>
        </div>
        <div *ngIf="!announcementList || announcementList.length <= 0">
          <h6>Görüntülenecek veri bulunamadı</h6>
        </div>
      </div>

      <div class="chat-bar-container">
        <div class="chat-bar">
          <a routerLink="/konusmalarim">
            <div class="chat-bar-content">
              <span class="chat-title">Sohbet için </span>
              <div class="online">
                <span class="online-icon"></span>
                <span class="online-text">(tıklayınız...)</span>
              </div>
            </div>
            <div class="chat-icon">
              <span class="icon-right-circle-thin"></span>
            </div>
          </a>
        </div>
      </div>
    </aside>
  </div>
</div>

<!-- Layout Works
<a routerLink="login">Login</a>

<button (click)="ping()">test</button>

<button (click)="test()">sadasdas</button>

{{ baaa | json }} -->
