import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { JobApplicationDataRequestModel, JobApplicationModel, WorkJobDataRequestModel, WorkJobModel } from 'src/app/models/orphan/job-work';
import { JobApplicationService, WorkJobService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-work-job',
  templateUrl: './work-job.component.html'
})
export class WorkJobComponent implements OnInit {

  isPageDone = false;
  model: WorkJobModel[];
  orphanJobApplicationModel: JobApplicationModel[];
  dataCount: number;
  requestModel = new WorkJobDataRequestModel();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public service: WorkJobService,
    public jobApplicationservice: JobApplicationService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public dialog: MatDialog
  ) {
    this.utilsService.setTitle("İş İlanları");
  }

  ngOnInit(): void {
    this.reloadList();
  }

  reloadList(): void {
    this.requestModel.maxResultCount = this.paginator?.pageSize ?? 5;
    this.requestModel.skipCount = this.requestModel.maxResultCount * (this.paginator?.pageIndex ?? 0);
    this.requestModel.isActiveFilter = 1;
    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);

    let jobApplicationRequestModel = new JobApplicationDataRequestModel();
    jobApplicationRequestModel.orphanId = this.utilsService.selectedOrphanId;
    let jobApplicationParamsString = this.utilsService.urlSearchParamConvertToString(jobApplicationRequestModel);
    const orphanJobApplcationPromise$ = this.jobApplicationservice.getOrphanJobApplicationAll(jobApplicationParamsString).toPromise();
    const workJobPromise$ = this.service.getActiveAll(paramsString).toPromise();
    Promise.all([
      workJobPromise$, orphanJobApplcationPromise$
    ]).then(result => {
      this.model = result[0].items;
      this.dataCount = result[0].totalCount;
      this.orphanJobApplicationModel = result[1].items;
    })
  }

  getServerData() {
    this.requestModel.maxResultCount = this.paginator.pageSize;
    this.requestModel.skipCount = this.paginator.pageSize * this.paginator.pageIndex;
    this.reloadList();
  }
}
