import { DataRequestModel } from "../generic-base/data-model";

export class MessageContentModel {
  messageContent: MessageContentViewModel;
  messageInboxGroupOrChatName: string;
  userName: string;
  userId: number;
}

export class MessageContentViewModel {
  messageContentText: string;
  messageInboxId: number;
  senderUserId: number;
  messageReadUsers: number[];
  id: number;
}

export class MessageContentDataRequestModel extends DataRequestModel {
  messageInboxId: number;
}
