<div *ngIf="isPageDone">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div mat-dialog-actions align="end">
        <button type="button" class="close modal-close">
          <span (click)="onClose()" class="icon-modal-close"> </span>
        </button>
      </div>
      <div class="modal-body">
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Görev Tipi</span>
          </div>
          <div class="select">
            <select style="" formControlName="orphanTaskTypeId">
              <option value="">Seçiniz</option>
              <option [value]="item.id" *ngFor="let item of taskTypeSelect">
                {{ item.displayName }}
              </option>
            </select>
            <div class="icon">
              <span class="icon-down-thin"></span>
            </div>
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Konu</span>
          </div>
          <div class="input">
            <input
              type="text"
              formControlName="subject"
              [readonly]="utilsService.currentUserRole == 'yetim'"
            />
          </div>
        </div>
        <div class="default-input-group mb-4">
          <div class="title">
            <span>Açıklama</span>
          </div>
          <div class="input">
            <input
              type="text"
              formControlName="description"
              [readonly]="utilsService.currentUserRole == 'yetim'"
            />
          </div>
        </div>
        <div
          class="default-input-group mb-4"
          *ngIf="utilsService.currentUserRole != 'yetim'"
        >
          <div class="title">
            <span>Hedeflenen Tarih</span>
          </div>
          <div class="input">
            <input
              type="date"
              formControlName="targetCompletedDate"
              placeholder="GG / AA / 20__"
              value="{{
                this.form.controls.targetCompletedDate.value
                  | date: 'yyyy-MM-dd'
              }}"
            />
          </div>
        </div>
        <div
          class="default-input-group mb-4"
          *ngIf="utilsService.currentUserRole != 'yetim'"
        >
          <div class="title">
            <span>Soru Sor</span>
          </div>
          <div class="checkbox">
            <input
              type="checkbox"
              formControlName="askQuestion"
              [readonly]="utilsService.currentUserRole == 'yetim'"
            />
          </div>
        </div>
        <div *ngIf="f.askQuestion.value" class="default-input-group mb-4">
          <div class="title">
            <span>Soru</span>
          </div>
          <div class="input">
            <input
              type="text"
              formControlName="question"
              [readonly]="utilsService.currentUserRole == 'yetim'"
            />
          </div>
          <div *ngIf="f.id.value">
            <div class="title">
              <span>Cevap</span>
            </div>
            <div class="input">
              <input type="text" formControlName="questionAnswer" />
            </div>
          </div>
        </div>
        <div *ngIf="f.id.value" class="default-input-group mb-4">
          <div class="title">
            <span>Tamamlandı</span>
          </div>
          <div class="checkbox">
            <input type="checkbox" formControlName="isCompleted" />
          </div>
        </div>
        <div class="btn-content" *hasPermission="['Pages.OrphanTasks.Edit']">
          <div class="add-btn">
            <button>Kaydet</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
