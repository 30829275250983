import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DropdownModel } from 'src/app/models/generic-base/dropdown-model';
import { CareerSpecialistFormModel } from 'src/app/models/manager/career-specialist-form';
import { GetOrphanFileForViewDto, OrphanSchoolModel } from 'src/app/models/orphan';
import { DialogDataModel } from 'src/app/models/shared/dialog-data';
import { JwtTokenService } from 'src/app/services/auth/jwt-token.service';
import { CareerSpecialistFormService } from 'src/app/services/manager/career-specialist-form.service';
import { FileService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-career-specialist-form',
  templateUrl: './career-specialist-form.component.html'
})
export class CareerSpecialistFormComponent implements OnInit {

  userId: number;
  form: FormGroup;
  isEdit = false;
  selectedFileName: string;
  isPageDone = false;
  isSubmitted = false;
  formData: FormData = new FormData();
  provinceSelect: DropdownModel[];
  educationTypeSelect: DropdownModel[];
  availableStatusSelect: DropdownModel[];
  orphanFiles: GetOrphanFileForViewDto[] = [];
  model: CareerSpecialistFormModel;
  date: Date = new Date;
  public dialog: MatDialog

  constructor(
    private service: CareerSpecialistFormService,
    private fileService: FileService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private jwtLoginService: JwtTokenService,
  ) { }

  ngOnInit(): void {
    // let userId = this.utilsService.getTokenDecode()["user_identifier"];
    this.utilsService.setUserName(this.utilsService.getLocalStorage("currentUserName"));
    this.form = this.formBuilder.group({
      id: [null],
      nameAndSurname: ["", Validators.required],
      dateBirth: ["", Validators.required],
      lifeCity: ["", Validators.required],
      phoneNumber: [0, Validators.required],
      address: ["", Validators.required],
      workTitle: ["", Validators.required],
      beforeSpecialist: [false, Validators.required],
      pastExperience: [""],
      isAccepted: [false],
      isAcceptedDescription: [""],
      joinStatus: [""],
      userId: [this.userId],
      cvFile: [""],
      cvFileToken: [""],
    })
    const helper = new JwtHelperService();
    var myRawToken = null;
    var token = String(localStorage.getItem('access_token'));
    if (token != "null") {
      myRawToken = helper.decodeToken(token);
      this.userId = myRawToken["user_identifier"];
    }

    this.service.getCareerSpecialistFormForView(0).subscribe(res => {
      if (res) {
        this.model = res;
        this.form.patchValue(this.model.careerSpecialistForm);
        if (this.model?.careerSpecialistForm.joinStatus != "Başvuru Değerlendirmeye Gönderildi") {
          this.form.disable();
        }
      }
    })
    this.form.controls.userId.setValue(this.userId);
    this.isPageDone = true;
  }

  onFileSelected(event): void {
    var extensionArray: string[] = [];
    extensionArray.push("jpeg");
    extensionArray.push("jpg");
    extensionArray.push("png");
    extensionArray.push("docx");
    extensionArray.push("xlsx");
    extensionArray.push("pdf");
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      let selectedFile = <File>event.target.files[0];
      this.formData.append(selectedFile.name, selectedFile);
      this.selectedFileName = selectedFile.name;
      var extension = this.selectedFileName.substring(this.selectedFileName.lastIndexOf('.') + 1);
      if (this.utilsService.checkFileIsCorrect(selectedFile.size, extension, 5242880, extensionArray)) {
        this.fileService.uploadCatalogImageFile("/CareerSpecialistForms/UploadCvFileFile", this.formData).subscribe(res => {
          this.form.controls.cvFileToken.setValue(res.result.fileToken)
        })
      }
    }
  }

  restartForm() {
    this.form.reset();
    this.form.enable();
    this.form.controls.userId.setValue(this.userId);
    this.form.controls.isAccepted.setValue(false);
    this.form.controls.isAccepted.setValue(false);
  }

  downloadFile(certificateFileId: string, fileName: string): void {
    this.service.downloadBinaryFile(certificateFileId).subscribe(result => {
      this.utilsService.downloadFile(result, fileName);
    })
  }

  deleteFile(id: number, key: string, index: number) {
    this.service.removeCvFile(id).subscribe(response => {
      this.model.careerSpecialistForm.cvFileFileName = null;
    })
  }

  onSubmit(): void {
    if (this.form.valid) {
      if (this.form.controls.id.value === null) {
        this.form.controls.joinStatus.setValue("Başvuru Değerlendirmeye Gönderildi");
      }
      this.service.createOrEdit(this.form.value).subscribe(result => {
        if (result.message == "Ok") {
          this.utilsService.showMessage("İşlem başarılı");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
    }
  }

  logOut(): void {
    this.jwtLoginService.logout(this.utilsService.getLocalStorage("access_token")).subscribe(() => {
      this.utilsService.removeLocalStorageItem("userId");
      this.utilsService.removeLocalStorageItem("currentUserName");
      this.utilsService.removeLocalStorageItem("pcBase64");
      this.utilsService.removeLocalStorageItem("access_token");
      window.location.reload();
    });

  }
}
