import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalendarOptions, EventApi, DateSelectArg, EventClickArg } from '@fullcalendar/angular';
import { AppConstants } from 'src/app-constants';
import { INITIAL_EVENTS, createEventId } from 'src/app/event-utils';
import { EventDataRequestModel, EventModel, OrphanTaskandEventModel, TaskDataRequestModel } from 'src/app/models/orphan';
import { EventCalendarService, TaskInfoService } from 'src/app/services/orphan';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { EventCalendarDetailComponent } from './event-calendar-detail/event-calendar-detail.component';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css']
})
export class EventCalendarComponent implements OnInit {

  requestModel = new EventDataRequestModel();
  taskRequestModel = new TaskDataRequestModel();
  eventListModel: EventModel[] = [];
  eventandTaskListModel: OrphanTaskandEventModel[] = [];
  isPageDone = false;

  constructor(
    public service: EventCalendarService,
    private taskInfoService: TaskInfoService,
    public formBuilder: FormBuilder,
    private utilsService: UtilsService,
    public router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.requestModel.maxResultCount = 30
    this.taskRequestModel.isCompletedFilter = 0;
    this.taskRequestModel.maxResultCount = 30
    this.taskRequestModel.orphanId = this.utilsService.currentUserRole == 'yetim' ? this.utilsService.selectedOrphanId : this.utilsService.currentUserId;
    let paramsString = this.utilsService.urlSearchParamConvertToString(this.requestModel);
    this.service.getAll(paramsString).subscribe(result => {
      this.eventandTaskListModel = result.items;
      let taskRaramsString = this.utilsService.urlSearchParamConvertToString(this.taskRequestModel);
      this.taskInfoService.getOrphanTaskAll(taskRaramsString).subscribe(response => {

        if (result) {
          for (let index = 0; index < response.items.length; index++) {

            this.eventandTaskListModel.push(response.items[index]);

          }
        }
        this.calendarOptions = {
          locale: "tr",
          firstDay: 1,
          weekends: true,
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          noEventsContent: "Görüntülenecek etkinlik yok.",
          buttonText: {
            month: 'Ay',
            listWeek: 'Hafta',
            listDay: 'Gün',
          },
          moreLinkContent: function (args) {
            return '+' + args.num + ' daha fazla';
          },
          views: {
            dayGridMonth: {
              eventLimit: 3,
            }
          },
          customButtons: {
            myCustomButton: {
              text: 'Liste',
              // click: this.customFunction.bind(this) // <-------- CAN ALSO USE THIS ONE
              click: () => this.customFunction()
            }
          },
          headerToolbar: {
            left: '',
            center: 'dayGridMonth,listWeek,listDay myCustomButton',
            right: 'prev next title'
          },
          events: this.convertEvents(this.eventandTaskListModel),
          displayEventTime: true,
          select: this.handleDateSelect.bind(this),
          eventClick: this.handleEventClick.bind(this),
          eventsSet: this.handleEvents.bind(this)
        };
        this.isPageDone = true;
      });

    })
  }

  customFunction() {
    this.router.navigate(["etkinlik-takvimi-liste"]);
  }

  convertEvents(eventModel: OrphanTaskandEventModel[]) {
    let events = [];

    for (let index = 0; index < eventModel.length; index++) {
      let model = {
        id: eventModel[index].eventCalendarDetail ? eventModel[index].eventCalendarDetail?.id : eventModel[index].orphanTask.id,
        title: eventModel[index].eventCalendarDetail ? eventModel[index].eventCalendarDetail?.subject : eventModel[index].orphanTask.subject,
        start: eventModel[index].eventCalendarDetail ? eventModel[index].eventCalendarDetail?.startDate : eventModel[index].orphanTask.targetCompletedDate,
        end: eventModel[index].eventCalendarDetail ? eventModel[index].eventCalendarDetail?.endDate : eventModel[index].orphanTask.targetCompletedDate,
        allDay: false,
        groupId: eventModel[index].eventCalendarDetail ? '1' : '2'
      }

      events.push(model);

    }
    return events;
  }


  calendarVisible = true;
  calendarOptions;
  currentEvents: EventApi[] = [];

  handleDateSelect(selectInfo: DateSelectArg) {
    /* const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    } */
  }

  handleEventClick(clickInfo: EventClickArg) {
    let itemType = clickInfo.event.groupId == '1' ? 'Event' : 'Task';
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
    const dialogRef = this.dialog.open(EventCalendarDetailComponent, {
      data: { id: clickInfo.event._def.publicId, dataType: itemType },
      width: "650px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != AppConstants.EDIT_DIALOG_RESULT_CANCEL) {
      }
    });
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

}
